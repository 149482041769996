.common-faq-wrapper {
    margin: 0vw 12vw;
    margin-top: 8vw;
    margin-bottom: 8vw;
}
.common-faq-wrapper > h1 {
    text-align: center;
    font-family: "Poppins-SemiBold";
    font-style: normal;
    font-weight: 600;
    font-size: 2vw;
    line-height: 45px;
    color: #4f516b;
    margin-bottom: 5vw;
}

@media (max-width: 600px) {
    .common-faq-wrapper > h1 {
        font-size: 22px;
    }

    .common-faq-wrapper {
        margin: 10vw 10vw;
    }
}
