.dsa-success-stories-wrapper {
    padding: 3vw 6vw;
    margin-top: -1.5vw;
}

.dsa-success-stories-wrapper h2 {
    font-family: Poppins-Semibold;
    font-size: 2.5vw;
    color: #222;
    text-align: center;
    padding: 0.4vw 1vw;
}

.dsa-success-stories-wrapper p {
    font-family: Poppins-Regular;
    font-weight: 500;
    font-size: 1.4vw;
    color: #222;
    text-align: center;
    margin-bottom: 0vw;
}

.dsa-success-stories-wrapper .slick-list {
    margin-left: 3vw;
}

.dsa-success-stories-card {
    width: 90% !important;
    border-radius: 20px;
    background: #F7F8F9;
    padding: 2vw;
    position: relative;
    height: 38vw;
    box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.10);
    margin-bottom: 1vw;
    margin-left: 0.6vw;
}

.dsa-success-stories-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.slick-prev {
    width: 2rem;
    height: 2rem;
}

.slick-next {
    width: 2rem;
    height: 2rem;
}

.dsa-success-stories-card-quotes-img {
    width: 1.5rem;
    height: 1rem;
    margin-bottom: 2vw;
}

.dsa-success-stories-card-img {
    width: 8rem;
    height: 8rem;
    margin: 2vw 0vw;
}

.dsa-success-stories-card-txt {
    height: 13vw;
}

.dsa-success-stories-card-txt p {
    font-family: Poppins-Regular;
    font-size: 1.1vw;
    text-align: center;
    color: #231F20;
}

.dsa-success-stories-name-wrapper {
    display: flex;
    align-items: center;
}

.dsa-success-stories-name {
    font-family: Poppins-SemiBold;
    font-size: 1.1vw;
    margin-right: 0.5vw;
}

.dsa-success-stories-name-wrapper img {
    width: 1.5rem;
    height: 1.5rem;
    margin-top: 0.3vw;
    cursor: pointer;
}

.dsa-success-stories-company-logo-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.dsa-sde-text {
    color: #231F20;
    font-family: Poppins-Regular;
    font-size: 1vw;
}

.dsa-logo-wrapper {
    padding: 0.3vw 0.5vw;
}

.dsa-logo-wrapper img {
    width: 5rem;
    height: 2rem;
}

.google-wrapper img {
    width: 5rem;
    height: 2rem;
    margin-top: 0.3vw;
}

.amazon-wrapper img {
    width: 6rem;
    height: 2.5rem;
    margin-top: 0.5vw;
}

.paypal-wrapper img {
    width: 4rem;
    height: 2rem;
    margin-top: 0.3vw;
    margin-left: 0.3vw;
}

.dsa-success-stories-read-more-btn {
    display: flex;
    justify-content: center;
    margin-top: 4vw;
}

.dsa-success-stories-read-more-btn button {
    color: #fff;
    font-family: Poppins-Regular;
    background: #3940A0;
    cursor: pointer;
    border: 3px solid rgba(68, 75, 171, 0.7);
    border-radius: 8px;
    font-family: Poppins-Regular;
    font-weight: 600;
    font-size: 1.2vw;
    padding: 1vw 2vw;
    margin-right: 2vw;
}

@media (max-width: 600px) {
    .dsa-success-stories-wrapper {
        margin-top: 6vw;
    }

    .dsa-success-stories-wrapper h2 {
        font-size: 4.75vw;
    }

    .dsa-success-stories-wrapper p {
        font-size: 3.4vw;
    }

    .dsa-success-stories-wrapper .slick-list {
        margin-left: 6vw;
        margin-right: 2vw;
    }

    .dsa-success-stories-card {
        height: 100vw;
        margin: 5vw 2vw;
        padding: 1vw;
    }

    .dsa-success-stories-card-quotes-img {
        margin: 7vw 4vw 3vw 4vw;
    }

    .slick-prev {
        width: 1.8rem;
        height: 1.8rem;
    }

    .slick-next {
        width: 1.8rem;
        height: 1.8rem;
    }

    .dsa-success-stories-content {
        height: fit-content;
        justify-content: space-evenly;
        align-items: center;
    }

    .dsa-success-stories-card-txt {
        height: auto;
        padding-left: 1vw;
    }

    .dsa-success-stories-card-img {
        width: 7rem;
        height: auto;
    }

    .dsa-success-stories-card-txt p {
        font-size: 3vw;
        padding: 2vw 0;
    }

    .dsa-success-stories-name {
        font-size: 4.5vw;
    }

    .dsa-sde-text {
        font-size: 4vw;
    }

    .dsa-logo-wrapper img {
        width: 4rem;
        height: 1.5rem;
        padding: 0.5vw 0 0 1vw;
        margin-top: -0.5vw;
    }

    .google-wrapper img {
        width: 6rem;
        height: 2rem;
        margin-top: 1vw;
        margin-left: -0.2vw;
    }

    .amazon-wrapper img {
        width: 7rem;
        padding-top: 1.4vw;
    }

    .paypal-wrapper {
        margin-top: 2vw;
    }

    .paypal-wrapper img {
        margin-left: 1vw;
        height: 1.5rem;
        margin-top: -0.6vw;
    }

    .dsa-success-stories-company-logo-wrapper {
        margin-top: -1.5vw;
    }

    .dsa-success-stories-read-more-btn button {
        font-size: 2.9vw;
        border: 1px solid #444bab;
        padding: 2.5vw 5vw;
    }

    .dsa-success-stories-name-wrapper img {
        margin-top: 0.5vw;
    }
}