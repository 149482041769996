.passwordfield-wrapper {
  margin-bottom: 1vw;
}

.passwordfield-label {
  color: #737171;
  font-family: "Poppins-Regular";
  margin-bottom: 5px;
}

.passwordfield-input-wrapper {
  position: relative;
}

.passwordfield-input {
  width: 100%;
  font-size: 1.2vw;
  color: #393939;
  font-family: "Poppins-Regular";
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  padding: 0.5vw 0.5vw;
  outline-color: #c7c7d1;
  padding-left: 0.8vw;
}

.passwordfield-error {
  font-size: 14px;
  color: #d14444;
}

.passwordfield-error-icon {
  position: absolute;
  right: 3.5vw;
  color: #d14444;
  margin: 0.7vw 0.5vw;
  font-size: 2vw !important;
}

.passwordfield-eye-icon {
  position: absolute;
  right: 10px;
  color: #737171;
  margin: 0.5vw 0.5vw;
  font-size: 2vw !important;
  cursor: pointer;
}

.passwordfield-input-error {
  border-color: #d14444;
}

@media (max-width: 600px) {
  .passwordfield-wrapper {
    margin-bottom: 5vw;
  }

  .passwordfield-label {
    font-size: 15px;
  }

  .passwordfield-input {
    padding: 3vw 0.5vw;
    font-size: 3.2vw;
    text-indent: 10px;
  }

  .passwordfield-error-icon,
  .passwordfield-eye-icon {
    font-size: 6vw !important;
    margin: 3vw 0.5vw;
  }

  .passwordfield-error-icon {
    right: 40px;
  }

  .passwordfield-error {
    margin-top: 5px;
  }
}
