.springboot-project {
    padding: 60px;
}

.springboot-project .springboot-project-heading {
    font-family: Gilroy-Bold;
    font-size: 48px;
    font-weight: 400;
    line-height: 64px;
    letter-spacing: 0.04em;
    text-align: center;
    padding: 0px 150px;
    width: 100%;
}

.springboot-project .springboot-project-paragraph {
    font-family: Gilroy-Regular;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.02em;
    text-align: center;
    padding: 0px 145px;
}

.springboot-project .springboot-project-card-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    padding: 20px 0px;
}

.springboot-project .springboot-project-card {
    display: flex;
    flex-direction: column;
    background: #f1f5f9;
    padding: 24px;
    border-radius: 10px;
    gap: 20px;
    overflow: hidden;
}

.springboot-project .springboot-project-card .springboot-project-image-section {
    border-radius: 12px;
    background: #ffffff;
    height: 275px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.springboot-project
    .springboot-project-card
    .springboot-project-information-box
    .springboot-project-card-heading {
    font-family: Gilroy-Bold;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.02em;
    text-align: left;
}

.springboot-project
    .springboot-project-card
    .springboot-project-information-box
    .springboot-project-card-paragraph {
    font-family: Gilroy-Regular;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: left;
    margin-top: 4px;
}

.springboot-project
    .springboot-project-card
    .springboot-project-information-box
    .springboot-project-tags-container {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin-top: 12px;
}

.springboot-project
    .springboot-project-card
    .springboot-project-information-box
    .springboot-project-tag {
    background: #ffffff;
    padding: 8px 20px;
    font-family: Gilroy-Regular;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-align: left;
    border-radius: 5px;
    white-space: nowrap;
}

.springboot-project .springboot-project-button-container {
    display: flex;
    justify-content: center;
}

.springboot-project .springboot-project-free-trial-button {
    background: #3940a0;
    font-family: Gilroy-Bold;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: left;
    text-transform: capitalize;
    padding: 16px 32px;
    border-radius: 10px;
}

@media (max-width: 600px) {
    .springboot-project {
        padding: 20px;
    }

    .springboot-project .springboot-project-heading {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.02em;
        padding: 0px;
    }

    .springboot-project .springboot-project-paragraph {
        font-size: 16px;
        line-height: 24px;
        padding: 0px;
    }

    .springboot-project .springboot-project-card {
        padding: 16px;
    }

    .springboot-project .springboot-project-card-container {
        grid-template-columns: repeat(1, 1fr);
    }
    .springboot-project .springboot-project-card .springboot-project-image-section {
        height: 250px;
    }

    .springboot-project
        .springboot-project-card
        .springboot-project-information-box
        .springboot-project-card-heading {
        font-size: 20px;
        line-height: 28px;
    }

    .springboot-project
        .springboot-project-card
        .springboot-project-information-box
        .springboot-project-card-paragraph {
        font-size: 14px;
        line-height: 20px;
    }
    .springboot-project
        .springboot-project-card
        .springboot-project-information-box
        .springboot-project-tag {
        font-size: 12px;
        line-height: 16px;
    }

    .springboot-project .springboot-project-button-container a {
        width: 100%;
    }
    .springboot-project .springboot-project-free-trial-button {
        width: 100%;
    }
}
