.springboot-logo-slider {
    padding: 0 2vw;
}

.springboot-logo-slider-img-wrapper {
    height: 5vw;
}

.springboot-google-logo {
    width: 76px;
    height: 26px;
}

.springboot-flipkart-logo {
    width: 94px;
    height: 25px;
}

.springboot-adobe-logo {
    width: 82px;
    height: 32px;
}

.springboot-microsoft-logo {
    width: 101px;
    height: 22px;
}

.springboot-amazon-logo {
    width: 79px;
    height: 24px;
}

.springboot-walmart-logo {
    width: 87px;
    height: 24px;
}

.springboot-razorpay-logo {
    width: 120px;
    height: 24px;
}

.springboot-paytm-logo {
    width: 64px;
    height: 20px;
    margin-left: 24px;
}

.springboot-oracle-logo {
    width: 86px;
    height: 22px;
}

.springboot-paloalto-logo {
    width: 92px;
    height: 24px;
}

.springboot-logo-slider-text {
    font-family: Gilroy-Bold;
    font-size: 40px;
    font-weight: 400;
    line-height: 64px;
    letter-spacing: 0.04em;
    text-align: center;
    width: 100%;
    margin-bottom: 30px;
    margin-top: 30px;
}

@media (max-width: 600px) {
    .springboot-logo-slider-text {
        font-size: 4.3vw;
        margin-bottom: 0px;
        margin-top: 20px;
    }

    .springboot-logo-slider-img-wrapper {
        height: 12vw;
    }
}
