.blogs-section {
    background-color: #ffffff;
    position: relative;
    padding: 40px 20px;
  }
  
  .blogs-header {
    display: flex;
    justify-content: space-between;
    max-width: 1312px;
    margin: auto;
  }
  
  .blogs-title {
    color: #000000;
    max-width: 711px;
    font-size: 48px;
    line-height: 64px;
    font-weight: bold;
    text-align: left;
  }
  
  .read-more-btn {
    width: 193px;
    height: 48px;
    background-color: #020617;
    color: #ffffff;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    border-radius: 12px;
    border: none;
    cursor: pointer;
  }
  
  .read-more-btn:hover {
    background-color: #1e293b;
  }
  
  .blogs-grid {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 24px;
  }

  .blogs-footer {
    display: none !important;
  }
  
  .blogs-slider {
    margin-top: 20px;
    max-width: 335px;
    margin: auto;
    display: none;
  }
  
  .blogs-footer {
    padding: 12px;
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  /* Responsive Styling */
  @media (max-width: 1024px) {
    .blogs-title {
      font-size: 32px;
      line-height: 48px;
    }
  }
  
  @media (max-width: 600px) {
    .blogs-grid {
      display: none;
    }
    .blogs-slider {
      display: block;
    } 
    .blogs-footer {
      display: flex !important;
    }
    .read-more-btn-desktop {
      display: none !important;
    }
    .blogs-title {
      font-size: 24px !important;
      line-height: 32px;
      margin-bottom: 45px;
    }
    .next-arrow {
      top: -30px !important;
    }
    .prev-arrow {
      top: -30px !important;
    }
  }
  