.ren-audience-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 3vw 4vw;
}
.ren-audience-wrapper h2 {
    font-size: 2.4vw;
    padding-bottom: 2.5vw;
    font-family: Poppins-Medium;
}
.ren-audience-card {
    background-color: #E8EDFB;
    color: #000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 3vw;
    border-radius: 1vw;
    width: 88%;
    margin-bottom: 2vw;
}
.ren-audience-content {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 1vw 0;
}
.ren-audience-content .customCss {
    font-family: Poppins-Medium;
    font-size: 1.8vw;
    line-height: 2.3vw;
    padding-bottom: 1vw;
    font-weight: bold;
}
.ren-audience-description {
    display: inline;
    font-family: Poppins-Regular;
    font-size: 1.3vw;
}
.ren-audience-content img {
    display: inline-block;
    height: 1.3vw;
    padding-top: 0.2vw;
    padding-left: 0.2vw;
}
.ren-audience-image-wrapper {
    display: flex;
    justify-content: flex-end;
    height: 15vw;
    cursor: pointer;
}
.ren-audience-image {
    height: 100%;
}
@media ( max-width: 600px ) {
    .ren-audience-wrapper {
        padding: 3vw 4vw;
    }
    .ren-audience-wrapper h2 {
        font-size: 5.7vw;
        padding-bottom: 4vw;
    }
    .ren-audience-card {
        width: 100%;
        height: auto;
        border-radius: 2vw;
        padding: 0 2vw;
        margin-bottom: 4vw;
        align-items: flex-end;
    }
    .ren-audience-image-wrapper {
        align-items: flex-end;
        height: 35vw;
        width: 36vw;
        padding: 1.5vw 0.5vw;
        padding-bottom: 0;
    }
    .zeal-chetan-image-wrapper {
        height: 43vw;
    }
    .ren-audience-image {      
        width: 100%;
    }
    .ren-audience-content {
        width: 60vw;
        padding: 3vw 1vw;
    }
    .ren-audience-content .customCss {
        font-size: 3.6vw;
        font-family: Poppins-Regular;
        line-height: 4.5vw;
        padding-bottom: 2vw;
        font-weight: bold;
    }
    .ren-audience-description {
        font-size: 3.1vw;
        padding-bottom: 2.5vw;
    }
    .ren-audience-content img {
        height: 3.5vw;
        padding-top: 1vw;
        padding-left: 1vw;
    }

}
