.lld-pre-requisites-wrapper {
    background: #444bab;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 20vw;
    margin-bottom: 5vw;
}
.lld-pre-requisites-image-left {
    width: 16vw;
    transform: rotate(-180deg);
}
.lld-pre-requisites-image-left img {
    width: 100%;
}
.lld-pre-requisites-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-family: Poppins-SemiBold;
    color: #ffffff;
    width: 65vw;
}
.lld-pre-requisites-wrapper p.heading {
    color: white;
    font-size: 2.1vw;
    padding-bottom: 1.4vw;
    font-weight: bold;
}

.lld-pre-requisites-wrapper p {
    font-family: Poppins-Regular;
    font-weight: 400;
    font-size: 1.4vw;
}
.lld-pre-requisites-text {
    font-family: Poppins-Regular;
    font-weight: 400;
    font-size: 1.4vw;
}
.lld-pre-requisites-image-right {
    width: 16vw;
}
.lld-pre-requisites-image-right img {
    width: 100%;
}

@media (max-width: 600px) {
    .lld-pre-requisites-wrapper {
        height: 34vw;
    }
    .lld-pre-requisites-wrapper p.heading {
        font-size: 3.2vw;
    }

    .lld-pre-requisites-wrapper p {
        font-size: 2.6vw;
    }

    .lld-pre-requisites-image-left {
        width: 18vw;
    }
    .lld-pre-requisites-image-right {
        width: 18vw;
    }
}
