.learning-outcomes-wrapper {
    display: flex;
    margin: 0vw 8vw;
    margin-top: 0;
    margin-bottom: 8vw;
}
.learning-outcomes-content {
    display: flex;
    flex-direction: column;
    width: 45%;
}
.learning-outcomes-content > h2 {
    margin-bottom: 2vw;
    font-family: "Poppins-SemiBold";
    font-weight: 600;
    font-size: 2vw;
    color: #272834;
}
.learning-outcomes-content > p {
    font-family: "Poppins-Regular";
    font-weight: 400;
    font-size: 1.1vw;
    color: #2f3043;
}
.learning-outcomes-points-wrapper {
    display: flex;
    flex-direction: column;
    padding: 0.7vw;
    width: 55%;
    word-wrap: normal;
}
.learning-outcomes-points {
    display: flex;
    margin-bottom: 1.7vw;
}
.learning-outcomes-points > img {
    object-fit: contain;
    margin-right: 1.5vw;
}
.learning-outcomes-points > p {
    font-family: "Poppins-Medium";
    font-weight: 500;
    font-size: 1.1vw;
    color: #2f3043;
}
.horizontal-line-outcomes {
    height: 1px;
    background-color: #c0c0c0;
    margin-bottom: 1.5vw;
    width: 45vw;
}
.right-arrow {
    position: relative;
    top: 3px;
    left: 10px;
    height: 15px;
    width: 15px;
}
@media (max-width: 600px) {
    .learning-outcomes-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 5vw 7vw;
        margin-bottom: 10vw;
    }
    br {
        content: "";
    }
    .learning-outcomes-content > h2 {
        font-size: 20px;
        margin-bottom: 10px;
        text-align: center;
    }
    .learning-outcomes-content > p {
        font-size: 14px;
        margin-bottom: 40px;
        text-align: center;
    }
    .learning-outcomes-points {
        align-items: center;
    }
    .learning-outcomes-points > p {
        font-size: 14px;
        margin-left: 10px;
        margin-top: 15px;
    }
    .learning-outcomes-points > img {
        width: 15px;
        height: 15px;
    }
    .horizontal-line-outcomes {
        margin-top: 30px;
        margin-bottom: 40px;
        width: 350px;
    }
    .learning-outcomes-content {
        width: auto;
    }
    .learning-outcomes-points-wrapper {
        width: auto;
    }
}
