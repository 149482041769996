.stories-footer-wrapper {
    border-top: 2px solid #ccd1ef;
}

.know-more-about-us-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 3vw 5vw;
    border-top: 2px solid #c7cdf1;
}

.know-more-about-text-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-family: "Poppins-Regular";
    color: #505050;
}
.know-more-about-logo-wrapper {
    font-family: "Poppins-Regular";
    color: #505050;
    width: 45%;
}
.know-more-about-text {
    font-family: "Poppins-SemiBold";
    color: #4f516b;
    font-size: 2.2vw;
    margin: 0 0 20px;
}
.footer-logo-wrapper,
.we-help-text {
    margin-bottom: 3vw;
    font-size: 1.1vw;
}

.lets-hear-all-text,
.lets-hear-all-text a {
    font-family: "Poppins-Regular";
    font-size: 1.1vw;
    color: #505050;
    text-decoration: none;
    margin-bottom: 10px;
}
.lets-hear-all-text a {
    text-decoration: underline;
    font-weight: 600;
}
.footer-links-section a {
    color: #505050;
    text-decoration: none !important;
    font-size: 1.1vw;
}
.links-font-style {
    font-family: "Poppins-Regular";
    color: #4f516b;
    text-decoration: none;
}

.footer-sec-first-column,
.footer-sec-second-column,
.footer-sec-third-column {
    display: flex;
    flex-direction: column;
    padding-right: 3vw;
}

.footer-sec-first-column > * + *,
.footer-sec-second-column > * + *,
.footer-sec-third-column > * + * {
    margin-top: 10px;
}

.footer-links-section {
    margin-top: 2vw;
    display: flex;
}

.icon-wrapper {
    display: flex;
}

.icon-wrapper > * + * {
    margin-left: 2.5vw;
}

.all-rights-reverved-text {
    border-top: 2px solid #ccd1ef;
    padding: 5px 0;
    font-family: "Poppins-Regular";
    color: #66747b;
    font-size: 12px;
    text-align: center;
}
@media (max-width: 600px) {
    .know-more-about-us-wrapper {
        display: block;
        padding: 20px;
    }
    .footer-links-section {
        display: block;
        padding-bottom: 15px;
    }
    .know-more-about-text {
        font-size: 18px;
    }
    .footer-sec-first-column,
    .footer-sec-second-dcolumn,
    .footer-sec-third-column {
        gap: 0;
    }
    .links-font-style a {
        padding-bottom: 10px;
    }
    .lets-hear-all-text,
    .lets-hear-all-text a {
        font-size: 18px;
    }
    .know-more-about-text br {
        display: none;
    }
    .all-rights-reverved-text {
        font-size: 10px;
    }
    .footer-sec-first-column,
    .footer-sec-second-column,
    .footer-sec-third-column {
        display: flex;
        flex-direction: column;
        gap: 0vw;
    }
    .footer-links-section a {
        font-size: 14px;
        margin: 5px 0;
    }
    .we-help-text {
        font-size: 15px;
        padding: 20px 0;
    }
    .know-more-about-logo-wrapper {
        width: 100%;
    }
}
