.advantage-wrapper {
  display: flex;
  flex-direction: column;
  background-color: #e8edfb;
  justify-content: center;
  align-items: center;
  padding: 5vw 5.5vw;
  margin: 2vw 0;
}
.advantage-wrapper h2 {
  font-size: 2.4vw;
  padding-bottom: 1.2vw;
  font-family: Poppins-Medium;
}
.advantage-wrapper h3 {
  font-size: 1.5vw;
  padding-bottom: 1.6vw;
  font-family: Poppins-Regular;
}
.advantage-wrapper .slick-list {
  margin-left: 3vw;
}
.slick-prev {
  width: 2rem;
  height: 2rem;
  margin-left: 1.5vw;
}
.slick-next {
  width: 2rem;
  height: 2rem;
}
.advantage-desktop-img {
  width: 100%;
}
.advantage-slider {
  width: 100%;
  padding: 0 2vw;
}
.advantage-slider-img {
  width: auto;
  height: 60vw;
}
@media (max-width: 600px) {
  .advantage-wrapper {
    padding: 4vw;
  }
  .advantage-wrapper h1 {
    font-size: 6vw;
    text-align: center;
    padding-bottom: 2vw;
  }
  .advantage-wrapper h2 {
    font-size: 3.5vw;
    padding-bottom: 2.5vw;
  }
  .advantage-wrapper .slick-list {
    margin-left: 6vw;
    margin-right: 2vw;
  }
  .advantage-slider-img-wrapper {
    display: flex;
    justify-content: center;
  }
  .slick-prev {
    width: 1.8rem;
    height: 1.8rem;
  }
  .slick-next {
    width: 1.8rem;
    height: 1.8rem;
  }
}
