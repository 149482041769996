.learners-heading {
    font-family: "Poppins-SemiBold";
    font-size: 2vw;
    color: #272834;
    margin-bottom: 4vw;
    margin-top: 8vw;
    text-align: center;
}
.learners-profile {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1vw;
}

.learners-img-wrapper {
    position: relative;
    margin-top: 0.7vw;
    margin-left: 0vw;
    margin-right: 2vw;
}
.learners-img {
    width: 10vw;
}

.learners-content-wrapper {
    display: flex;
    flex-direction: column;
    width: 45vw;
    position: relative;
}

.learners-name {
    font-family: "Poppins-SemiBold";
    font-size: 1.3vw;
    color: #4b4f60;
    margin-top: 0.7vw;
    display: flex;
    align-items: center;
    padding-bottom: 0.35vw;
    padding-left: 2.2vw;
    cursor: pointer;
}
.quotation {
    width: 4%;
}
.learners-job-desc {
    font-family: "Poppins-Regular";
    font-size: 1.1vw;
    color: #000000;
    padding-left: 2.2vw;
}
.learners-name > p {
    font-family: "Poppins-SemiBold";
    padding-right: 0.35vw;
    padding-top: 0.35vw;
    font-size: 1.2vw;
    color: #000000;
}
.learners-name > img {
    width: 4%;
    margin-bottom: -0.35vw;
}
.learners-job {
    font-family: "Poppins-Regular";
    color: hsl(0, 0%, 0%);
    font-size: 1.1vw;
    display: flex;
    align-items: center;
    padding-left: 2.2vw;
    margin-bottom: 3vw;
}
.read-more-stories-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.circle {
    display: flex;
    margin-bottom: 2vw;
}

.slick-dots li.slick-active button:before {
    color: #3e8ebc !important;
}
.learners-company-logo {
    width: 10vw;
    margin-top: 1vw;
}

.read-more-stories-btn-container {
    display: flex;
    align-items: center;
    margin: 3vw 0vw;
}

.stories-wrapper {
    display: flex;
    flex-direction: column;
    padding: 3vw 5vw;
    padding-left: 12vw;
}
.stories-wrapper .slick-prev {
    top: 8vw !important;
    left: 3vw;
    z-index: 1;
    width: 2vw;
    height: 3vw;
}

.stories-wrapper .slick-next {
    top: 8vw !important;
    right: 7vw !important;
    z-index: 1;
    width: 2vw;
    height: 3vw;
}

.stories-wrapper .slick-dots {
    left: -4.6vw;
}
.slick-dots li button:before {
    width: 3vw;
    height: 3vw;
    font-size: 0.8vw;
}
.stories-wrapper .slick-dots li {
    background-repeat: no-repeat;
    background-position: center;
}
.stories-wrapper .slick-dots .slick-active {
    background-repeat: no-repeat;
    background-position: center;
}
@media (max-width: 600px) {
    .learners-heading {
        font-size: 23px;
        margin: 20px 40px;
        text-align: center;
        margin-top: 10vw;
    }
    .stories-wrapper {
        padding: 0vw;
    }
    .learners-profile {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .learners-content-wrapper {
        align-items: center;
        width: 90%;
    }
    .learners-img {
        width: 150px;
        height: 150px;
        margin-bottom: 20px;
    }
    .learners-job-desc {
        display: flex;
        align-items: center;
        font-size: 14px;
        margin-bottom: 10px;
        margin-left: 20px;
        margin-top: 5vw;
    }
    .learners-name > p {
        text-align: center;
        font-size: 16px;
        margin-bottom: 10px;
    }
    .learners-name > img {
        height: 20px;
        width: 20px;
        margin-bottom: 5px;
    }
    .learners-job {
        font-size: 15px;
        text-align: center;
    }
    .dot-circle {
        display: none;
    }
    .middle-dot {
        display: none;
    }
    .read-more-stories-btn-container {
        display: none;
    }
    .quotation {
        position: absolute;
        height: 20px;
        width: 20px;
        left: 0;
    }
    .stories-wrapper .slick-dots {
        padding-left: 20px;
    }
    .learners-company-logo {
        width: 30vw;
    }
    .slick-dots li button:before {
        font-size: 3vw;
    }
    .slick-dots {
        bottom: -12px;
    }
}
