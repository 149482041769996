.success-stories-wrapper {
    padding: 3vw 6vw;
    margin-top: -1.5vw;
}
.success-stories-wrapper h2 {
    font-family: Poppins-SemiBold;
    font-size: 2.5vw;
    color: #222;
    text-align: center;
    padding: 1vw;
}
.success-stories-wrapper p {
    font-family: Poppins-SemiBold;
    font-size: 2vw;
    color: #222;
    text-align: center;
    margin-bottom: 4vw;
}
.success-stories-card-wrapper {
    display: flex;
    justify-content: space-between;
}

.success-stories-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    background: #ffffff;
    padding: 2vw;
    width: 32%;
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.success-stories-card-quotes-img {
    width: 1.5rem;
    height: 1rem;
    margin-bottom: 2vw;
}
.success-stories-card-img {
    width: 8rem;
    height: 8rem;
    margin: 2vw 0vw;
}
.success-stories-card-txt {
    display: flex;
    flex: 1 0 0;
}
.success-stories-card-txt p {
    font-family: Poppins-Regular;
    font-size: 1.1vw;
    text-align: center;
    color: #000;
}
.success-stories-name-wrapper {
    display: flex;
    align-items: start;
}
.success-stories-name {
    font-family: Poppins-SemiBold;
    font-size: 1.1vw;
    margin-right: 0.5vw;
}
.success-stories-name-wrapper img {
    width: 1.5rem;
    height: 1.5rem;
    margin-top: 0.6vw;
    cursor: pointer;
}
.success-stories-company-logo-wrapper {
    display: flex;
    align-items: start;
    justify-content: center;
    gap: 1vw;
    margin-top: -1.8rem !important;
}


.sde-text {
    color: #000;
    font-family: Poppins-Regular;
    font-size: 1.5vw !important;
    margin-top: 0.2rem !important;
}
.google-wrapper img {
    width: 6rem;
    height: 2rem;
    margin-top: 0.3vw;
}
.cn-wrapper img {
    width: 9rem;
    height: 1.6rem;
}
.ps-wrapper img {
    width: 4rem;
    margin-left: 0.5vw;
}
.success-stories-read-more-btn {
    display: flex;
    justify-content: center;
    margin-top: 4vw;
}

.success-stories-read-more-btn button {
    color: #343a40;
    font-family: Poppins-Regular;
    background: #ffffff;
    cursor: pointer;
    border: 3px solid rgba(68, 75, 171, 0.7);
    border-radius: 8px;
    font-family: Poppins-Regular;
    font-weight: 600;
    font-size: 1.2vw;
    padding: 1vw 2vw;
    margin-right: 2vw;
}

@media (max-width: 600px) {
    .success-stories-wrapper {
        padding: 0vw;
    }
    .success-stories-wrapper h2 {
        font-size: 5.5vw;
    }
    .success-stories-wrapper p {
        font-size: 3.2vw;
    }
    .success-stories-card-wrapper {
        flex-direction: column;
        justify-content: center;
        padding: 3vw 6vw;
    }
    .success-stories-slider-name-wrapper img{
        margin-top: -2vw !important;
    }


    .success-stories-read-more-btn {
        text-align: end;
    }
    .success-stories-read-more-btn button {
        margin-top: 20.2vw;
        font-size: 3.5vw;
        border: 1px solid #444bab;
        padding: 3vw;
        margin-left: 8vw;
    }
    .success-stories-name-wrapper img {
        margin-top: -0.5vw;
    }

    .sde-slider-text {
        margin-top: 3vw !important;
    }

    .success-stories-card-wrapper .slick-slider {
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
        border-radius: 3.6vw;
    }
}
