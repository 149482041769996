.logo-slider {
    padding: 0 2vw;
    background-color: #F8FFF9;
}
.logo-slider-img-wrapper {
    height: 5vw;
}
.logo-slider-img {
    width: 150px;
    padding: 0 10px;
}
.logo-slider-text {
    text-align: center;
    font-size: 1vw;
    padding-top: 2vw;
    padding-bottom: 2vw;
    font-family: Poppins-Semibold;
}
@media (max-width: 600px) {
    .logo-slider-img {
        width: 120px;
    }
    .logo-slider-text {
        font-size: 3.5vw;
        padding-bottom: 5vw;
    }
    .logo-slider-img-wrapper {
        height: 12vw;
    }
}
