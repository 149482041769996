.python-intro-wrapper {
    display: flex;
    justify-content: space-between;
    background-image: url("../../../img/python-intro-desktop.webp");
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 2vw;
}

.python-intro-content {
    display: flex;
    flex-direction: column;
    padding-left: 5vw;
}

.python-star-rating-wrapper {
    display: flex;
    align-items: center;
    padding-top: 3vw;
}

.python-star-rating-text {
    color: #032068;
    font-family: Poppins-Regular;
    font-weight: 500;
    font-size: 1.2vw;
    padding-left: 0.2vw;
    padding-top: 0.1vw;
}

.python-intro-content>h1 {
    font-family: "Poppins-SemiBold";
    font-size: 3.4vw;
    font-weight: 600;
    color: #1C4362;
    margin-bottom: 1.25vw;
    margin-top: 1.25vw;
}

.python-intro-content>h2 {
    font-family: "Poppins-Medium";
    font-size: 2vw;
    font-weight: 500;
    color: #343333;
    margin-bottom: 1.25vw;
    margin-top: 1.25vw;
}

.python-intro-points {
    display: flex;
    align-items: center;
    padding: 0.3vw 0vw;
}

.python-intro-points img {
    width: 1.3rem;
    height: 1.3rem;
}

.python-intro-points p {
    font-size: 1.1vw;
    font-family: Poppins-Regular;
    color: rgba(0, 0, 0, 0.8);
    padding-left: 0.5vw;
}

.python-intro-cta-btn-wrapper {
    margin: 1vw 0vw;
    margin-top: 5vw;
}

.python-intro-cta-btn-wrapper button {
    background: linear-gradient(91.59deg, #444bab 1.85%, #3ca5c2 99.69%);
    border: none;
    border-radius: 0.4vw;
    color: #ffffff;
    cursor: pointer;
    font-family: Poppins-Regular;
    font-size: 1.25vw;
    padding: 1vw 6.1vw;
}

.python-intro-image {
    display: none;
}

.python-free-lectures {
    color: #272834;
    font-family: Poppins-Regular;
    font-size: 1.1vw;
    margin-bottom: 7.5vw;
}

@media (max-width: 600px) {
    .python-intro-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        background: rgba(199, 219, 235, 1);
    }

    .python-intro-wrapper>img {
        width: 100%;
        height: 100%;
    }

    .python-intro-content {
        padding-left: 5vw;
        display: flex;
        justify-content: left;
        align-items: left;
    }

    .python-star-rating-wrapper {
        flex-direction: column;
        align-items: flex-start;
        padding-top: 10vw;
    }

    .python-star-rating-text {
        font-size: 3.73vw;
        padding: 1vw 0vw;
    }

    .python-intro-content>h1 {
        font-size: 7vw;
    }

    .python-intro-content>h2 {
        font-size: 4vw;
        color: #000000;
        font-family: Poppins-Regular;
        font-weight: 600;
    }

    .python-intro-desc {
        font-size: 4vw;
        margin: 6vw 0vw;
        padding-bottom: 2vw;
    }

    .python-intro-points {
        padding: 0.7vw 0vw;
    }

    .python-intro-points p {
        font-size: 3.73vw;
        color: #272834;
    }

    .python-intro-points img {
        margin-right: 1vw;
    }

    .python-intro-cta-btn-wrapper {
        display: flex;
        justify-content: center;
    }

    .python-intro-cta-btn-wrapper button {
        border: none;
        border-radius: 2.13vw;
        font-size: 4vw;
        font-weight: 600;
        padding: 4vw 5.3vw;
        margin-top: 2vw;
        width: 100%;
        margin-right: 5vw;
    }

    .python-free-lectures {
        display: flex;
        justify-content: center;
        font-size: 3.73vw;
        font-weight: 500;
        margin-top: 0vw;
        padding-bottom: 6vw;
        padding-right: 5vw;
    }

    .python-intro-image {
        display: block;
        width: 100vw;
    }

    .python-illustrator-img {
        width: 100vw;
        height: auto;
    }
}