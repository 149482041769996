.google-login-screen-wrapper {
    height: 100vh;
    background-color: #fafafb;
    padding: 3%;
}
.google-form-wrapper {
    background-color: #fff;
    width: 32%;
    margin: 0 auto;
    padding: 2vw;
    border: 1px solid rgba(68, 75, 171, 0.1);
}
.google-pwd-error-icon {
    position: absolute;
    left: 26vw;
    color: #d14444;
    visibility: visible;
    margin-top: 0.75vw;
}

.google-pwd-success-icon {
    position: absolute;
    left: 26vw;
    color: #177e40;
    visibility: visible;
    margin-top: 0.75vw;
}

.google-pwd-hide-password {
    position: absolute;
    left: 28vw;
    color: #737171;
    visibility: visible;
    margin-top: 0.75vw;
}

.google-pwd-show-password {
    position: absolute;
    left: 28vw;
    color: #737171;
    visibility: visible;
    margin-top: 0.75vw;
}

.google-pwd-error {
    color: #d14444;
}
.google-content-password-wrapper {
    position: relative;
}
.google-content-password-wrapper p {
    color: #d14444;
}
.google-signup-otp-confirm-btn {
    width: 100%;
    text-align: center;
    background: linear-gradient(93.53deg, #444bab 0%, #416bb3 62.5%, #3ca5c2 100%);
    color: #fff;
    font-family: "Poppins-Regular";
    font-weight: 600;
    cursor: pointer;
    padding: 0.8vw 0;
    border-radius: 4px;
    font-size: 1.2vw;
    border: none;
    margin-top: 5vw;
}
@media (max-width: 600px) {
    .google-form-wrapper {
        width: 100%;
        padding: 2vw 6vw;
    }

    .google-signup-otp-confirm-btn{
        font-size: 3vw;
        padding: 2.5vw 0vw;
    }
    .google-login-screen-wrapper {
        padding: 0% 0%;
    }
    .google-pwd-hide-password {
        left: 92%;
        margin-top: 2vw;
    }
    .google-pwd-show-password {
        left: 92%;
        margin-top: 2vw;
    }

    .google-pwd-error-icon {
        left: 85%;
        margin-top: 2vw;
    }
    .google-pwd-success-icon {
        left: 85%;
        margin-top: 2vw;
    }
}
