.springboot-coursecurriculum {
    background: #e1e7f8;
    padding: 60px 120px;
}

.springboot-coursecurriculum .springboot-curriculum-heading {
    font-family: Gilroy-Bold;
    font-size: 48px;
    font-weight: 400;
    line-height: 64px;
    letter-spacing: 0.04em;
    text-align: center;
    width: 100%;
}

.springboot-coursecurriculum .springboot-curriculum-switcher-container {
    display: flex;
    gap: 60px;
    justify-content: space-between;
    padding: 60px 40px;
    background: #a4baec;
    margin-top: 40px;
    border-radius: 20px;
}

.springboot-coursecurriculum .springboot-curriculum-tabs-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 50%;
}

.springboot-coursecurriculum .springboot-curriculum-tab {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #d0d5dd;
    border-radius: 10px;
    padding: 12px 20px;
    background-color: white;
    cursor: pointer;
}

.springboot-coursecurriculum .springboot-curriculum-tab.active {
    background: #3940a0;
    border: none;
}

.springboot-coursecurriculum .springboot-curriculum-tab .springboot-curriculum-title {
    font-family: Gilroy-Regular;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.04em;
    text-align: left;
    color: #231f20;
}

.springboot-coursecurriculum .springboot-curriculum-tab.active .springboot-curriculum-title {
    color: white;
}

.springboot-coursecurriculum .springboot-curriculum-tab .springboot-curriculum-capsule {
    font-family: Gilroy-Regular;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.04em;
    text-align: left;
    background: #f1f5f9;
    border-radius: 27px;
    padding: 8px 16px;
    color: #020617;
    text-wrap: nowrap;
}

.springboot-coursecurriculum .springboot-curriculum-information-box {
    background: #3940a0;
    border-radius: 12px;
    padding: 20px;
    width: 50%;
    color: white;
    height: min-content;
}

.springboot-coursecurriculum .springboot-curriculum-information-box .springboot-curriculum-topics {
    padding-bottom: 12px;
    border-bottom: 1px solid #f5f3ff;
}

.springboot-coursecurriculum .springboot-curriculum-information-box .springboot-curriculum-topics {
    font-family: Gilroy-Regular;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.04em;
    text-align: left;
}

.springboot-coursecurriculum .springboot-curriculum-information-box .springboot-curriculum-points {
    font-family: Gilroy-Regular;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.04em;
    text-align: left;
    padding-top: 12px;
    padding-left: 25px;
    list-style: disc;
    line-height: 30px;
}

.springboot-coursecurriculum .springboot-curriculum-information-box ul li {
    list-style: disc;
}

.springboot-coursecurriculum .springboot-curriculum-information-box.mobile {
    display: none;
}

@media (max-width: 600px) {
    .springboot-coursecurriculum {
        padding: 20px;
    }

    .springboot-coursecurriculum .springboot-curriculum-heading {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.02em;
    }

    .springboot-coursecurriculum .springboot-curriculum-switcher-container {
        background: transparent;
        padding: 0px;
        margin-top: 20px;
    }

    .springboot-coursecurriculum .springboot-curriculum-information-box.desktop {
        display: none;
    }

    .springboot-coursecurriculum .springboot-curriculum-information-box.mobile {
        display: block;
        padding: 0px;
        width: 100%;
        max-height: 0;
        overflow: hidden;
        /* transition: max-height 0.2s ease-out; */
    }

    .springboot-coursecurriculum .springboot-curriculum-information-box.mobile.show {
        padding: 20px;
        /* height: auto; */
        max-height: 1000px;
        transition:
            max-height 1s ease-in,
            padding-top 0.6s ease-in,
            padding-bottom 0.6s ease-in;
    }

    .springboot-coursecurriculum .springboot-curriculum-tab {
        padding: 16px;
    }

    .springboot-coursecurriculum .springboot-curriculum-tab .springboot-curriculum-capsule {
        padding: 8px 12px;
    }

    .springboot-coursecurriculum .springboot-curriculum-tabs-container {
        width: auto;
        gap: 12px;
    }
}
