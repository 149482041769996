.advantages-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 5vw;
}
.advantages-wrapper h2 {
    text-align: center;
    font-size: 2.5vw;
    font-family: Poppins-SemiBold;
}

.advantages-wrapper p {
    text-align: center;
    font-family: Poppins-SemiBold;
    font-size: 1.54vw;
}

.advantages-card-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}
.advantages-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    padding: 2vw;
}
.design-software-icon {
    width: 15rem;
}
.code-reviewer-icon {
    width: 14rem;
}
.interview-ready-icon {
    width: 12rem;
}
.concurrency-icon {
    width: 12rem;
}
.advantages-card-data {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2vw;
    width: 63%;
    border-top: 15px solid #3fbdf1;
    background: #fff;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
}
.advantages-card-data p {
    text-align: center;
    color: #000;
    font-size: 1.1vw;
    font-family: Poppins-Regular;
}

@media (max-width: 600px) {
    .advantages-wrapper h2 {
        font-size: 6.4vw;
    }
    .advantages-wrapper p {
        font-family: Poppins-Regular;
        padding: 3vw;
        text-align: center;
        font-size: 3.8vw;
    }
    .advantages-card-wrapper {
        margin-top: 5vw;
        margin-bottom: 25vw;
        display: block;
    }

    .advantages-card-wrapper .slick-arrow {
        margin-left: 44vw;
        margin-right: 40vw;
        top: 115%;
        width: 10.6vw;
        height: 10.6vw;
    }

    .advantages-card-wrapper .slick-list {
        margin-left: 8vw;
        margin-right: 8vw;
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
        border-radius: 3.6vw;
    }

    .advantages-slider-card {
        height: auto;
    }
    .advantages-slider-card-img-wrapper {
        padding: 8vw;
        width: 100%;
    }
    .advantages-slider-card-img-wrapper img {
        width: 100%;
        height: 35.2vw;
    }
    .advantages-slider-card-desc {
        border-top: 15px solid #3fbdf1;
        font-size: 3.1vw;
        padding: 3.1vw;
        font-family: Poppins-Regular;
    }
    .slick-prev {
        left: -10px;
    }
    .slick-next {
        right: -10px;
    }
}
