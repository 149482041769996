.common-about-wrapper {
    display: flex;
    margin: 10vw 8vw;
}
.common-about-content {
    display: flex;
    flex-direction: column;
    width: 50vw;
}
.common-about-content > h1 {
    font-family: "Poppins-SemiBold";
    font-weight: 600;
    font-size: 2vw;
    color: #444557;
    margin-bottom: 2.5vw;
}
.common-about-description-wrapper > p {
    display: flex;
    font-family: "Poppins-Regular";
    font-weight: 400;
    font-size: 1.1vw;
    color: #2f3043;
    margin-bottom: 2.5vw;
    width: 40vw;
}

.common-about-button-wrapper {
    display: flex;
}
.common-about-button-wrapper > button {
    background: linear-gradient(91.59deg, #444bab 1.85%, #3ca5c2 99.69%);
    border-radius: 0.4vw;
    font-family: "Poppins-SemiBold";
    font-weight: 600;
    font-size: 1.3vw;
    color: #ffffff;
    border: none;
    cursor: pointer;
    white-space: nowrap;
    padding: 1vw 5vw;
}
.rating-wrapper {
    display: flex;
    margin: 1vw 1vw;
}
.rating-wrapper > p {
    font-size: 1.1vw;
    color: #272834;
    font-family: "Lato-Regular";
    font-weight: 500;
    margin-left: 1vw;
}
.about-items-wrapper {
    display: flex;
    width: 31vw;
}
.common-about-info-wrapper {
    display: flex;
    flex-direction: column;
    padding: 1vw 0;
    padding-left: 2vw;
    background: #f8f7f7;
    border-radius: 0.4vw;
    justify-content: center;
}
.common-about-info {
    display: flex;
    align-items: center;
    flex: 1;
}
.common-about-info > p {
    font-family: "Poppins-Medium";
    font-weight: 500;
    font-size: 1.1vw;
    color: #333443;
    margin-left: 1.5vw;
}
.common-about-icons {
    max-width: 12%;
}
.vertical-bar {
    background: linear-gradient(180deg, #6a6ae2 0%, #3e8ebc 100%);
    border-radius: 0.4vw;
    width: 1vw;
}

@media (max-width: 600px) {
    .common-about-content {
        width: 100%;
    }
    .common-about-wrapper {
        display: flex;
        flex-direction: column;
    }
    .common-about-content > h1 {
        font-size: 20px;
        text-align: center;
    }
    .common-about-description-wrapper > p {
        text-align: justify;
        font-size: 14px;
        width: 85vw;
    }
    .common-about-button-wrapper {
        display: flex;
        flex-direction: column;
        margin: 10px 50px;
    }
    .common-about-button-wrapper > button {
        font-size: 3.5vw;

        padding: 0;
        padding: 4vw;
        border-radius: 5px;
    }
    .common-about-button-wrapper > p {
        position: relative;
        bottom: 32px;
        font-size: 12px;
        left: 100px;
    }
    .rating-wrapper {
        width: 700px;
        align-items: center;
        margin-left: 30px;
    }
    .rating-wrapper > p {
        font-size: 14px;
        margin-left: 10px;
    }
    .common-about-items-wrapper {
        width: 80vw;
        height: 450px;
        margin: 25px 10px;
    }
    .common-about-info-wrapper {
        padding: 25px 10px;
    }
    .common-about-info {
        margin: 0;
        padding: 1.5vw 0;
    }
    .common-about-info > p {
        font-size: 14px;
        margin-left: 10px;
    }
    .common-about-icons {
        max-width: 10%;
    }
    .about-items-wrapper {
        width: 100%;
        margin-top: 12vw;
    }
}
