.intro-wrapper {
    display: flex;
    background: #d6e6f2;
    padding: 0vw 6vw;
    padding-top: 5vw;
    margin-bottom: 5vw;
    position: relative;
}
.intro-contents-wrapper {
    display: flex;
    flex-direction: column;
    width: 47%;
    margin-right: 8vw;
    padding-top: 1vw;
}
.heading {
    font-family: Poppins-SemiBold;
    font-size: 2.5vw;
    color: #231f20;
}
.blue-heading {
    color: #4179b7;
}
.desc {
    color: rgba(0, 0, 0, 1);
    font-family: Poppins-Medium;
    padding: 1vw 0vw;
    font-size: 1.3vw;
    font-weight: lighter;
}
.intro-points {
    display: flex;
    align-items: center;
    padding-bottom: 0.3vw;
}
.intro-points img {
    width: 1.3rem;
    height: 1.3rem;
}
.intro-points p {
    font-size: 1.1vw;
    font-family: Poppins-Regular;
    color: rgba(0, 0, 0, 0.8);
    padding-left: 0.5vw;
}
.free-trial-btn-wrapper {
    margin: 1vw 0vw;
    margin-top: 3vw;
}
.free-trial-btn-wrapper button {
    background: linear-gradient(91.59deg, #444bab 1.85%, #3ca5c2 99.69%);
    border: none;
    border-radius: 0.4vw;
    color: #ffffff;
    cursor: pointer;
    font-family: Poppins-Regular;
    font-size: 1.2vw;
    padding: 1vw 4vw;
}
.intro-image-wrapper {
    display: flex;
    flex-direction: column;
    width: 55%;
}
.image-wrapper {
    width: 79%;
    margin-left: 10vw;
}
.intro-image-wrapper img {
    width: 100%;
}
.company-logo-wrapper {
    width: 50%;
    background: #ffffff;
    padding: 1vw;
    border-top-left-radius: 1vw;
    position: absolute;
    bottom: 0vw;
    padding: 1.5vw;
    left: 50%
}
.company-logo-wrapper p {
    font-family: Poppins-Regular;
    font-size: 1.3vw;
    color: rgba(0, 0, 0, 1);
}

.free-lectures {
    color: #000;
    font-family: Poppins-Regular;
    font-size: 1.1vw;
    margin-bottom: 5vw;
}
.star-rating-wrapper {
    display: flex;
    align-items: center;
}
.star-rating-text {
    color: #032068;
    font-family: Poppins-Regular;
    padding: 0vw 0.3vw;
    font-size: 1.1vw;
}

@media (max-width: 600px) {
    .intro-wrapper {
        display: flex;
        flex-direction: column;
        padding: 3vw 0vw;
    }
    .intro-contents-wrapper {
        width: 100%;
        padding: 3vw 6vw;
    }
    .heading {
        font-size: 5.7vw;
        width: 80%;
    }
    .desc {
        font-size: 4.2vw;
        margin-bottom: 5.3vw;
    }

    .intro-image-wrapper {
        width: 100%;
        padding-left: 5vw;
        padding-right: 5vw;
        /* margin: 5vw 0vw 14vw 0vw; */
    }
    .image-wrapper {
        width: 100%;
        margin-left: 0vw;
    }
    .intro-points-mob p {
        font-weight: 400;
        font-size: 3.7vw;
        padding-left: 2vw;
    }
    .intro-points-mob .intro-points img {
        width: 6.4vw;
        height: 6.4vw;
    }

    .star-rating-wrapper {
        flex-direction: column;
        justify-content: center;
        margin-top: 3.4vw;
        margin-bottom: 18vw;
    }
    .star-rating-text {
        color: #032068;
        line-height: 5.6vw;
        font-size: 3.7vw;
        padding: 1vw;
    }
    .signup-mob {
        width: 100%;
    }
    .free-trial-btn-wrapper {
        display: flex;
        justify-content: center;
    }
    .free-trial-btn-wrapper button {
        width: 100%;
        font-size: 4.2vw;
        padding: 4vw 5vw;
        border-radius: 12px;
    }
    .free-lectures {
        font-size: 3.7vw;
        margin: 3.2vw 2.5vw;
    }
    .company-logo-wrapper {
        width: 100%;
        left: 0vw;
    }
    .company-logo-wrapper p {
        font-size: 3.2vw;
        padding: 1vw;
        text-align: center;
    }

    .company-logo-img {
        margin-left: 7vw;
        margin-right: 7vw;
    }
}
