.dsa-about-wrapper {
    display: flex;
    flex-direction: column;
    padding: 4.167vw 5vw 0vw 5vw;
    margin-bottom: 5vw;
    background-color: #F7F8F9;
}

.dsa-about-heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 2vw;
    margin-top: 0;
}

.dsa-about-heading h2 {
    font-family: Poppins-Semibold;
    font-size: 3.1vw;
    font-weight: 500;
    margin-bottom: 0.5vw;
    color: #231F20;
}

.dsa-about-subheading {
    text-align: center;
    margin-bottom: 0.5vw;
    font-size: 1.4vw;
    color: #231F20;
    width: 53vw;
    letter-spacing: 1px;
    line-height: 2vw;
}

.dsa-about-content {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.dsa-about-image-wrapper {
    height: auto;
    width: 22vw;
    margin-left: 10vw;
}

.dsa-about-image {
    padding-top: 2.5vw;
    width: 22vw;
    overflow: hidden;
}

.dsa-about-items-wrapper {
    display: flex;
    width: 37vw;
    margin-right: 5vw;
}

.dsa-about-info-wrapper {
    display: flex;
    flex-direction: column;
    padding: 10vw 0 4vw 0;
    border-radius: 0.4vw;
    justify-content: space-around;
}

.dsa-about-info {
    display: flex;
    align-items: center;
    padding-right: 3vw;
    flex: 1;
}

.dsa-about-icons {
    max-width: 6%;
}

.dsa-about-info>p {
    font-family: "Poppins-Medium";
    font-weight: 500;
    font-size: 1.389vw;
    color: #333443;
    margin-left: 1.5vw;
}

@media (max-width: 600px) {
    .dsa-about-wrapper {
        padding: 5vw;
        margin: 2vw 0;
        background-color: #FFF;
    }

    .dsa-about-heading {
        margin: 2vw 0;
        text-align: center;
    }

    .dsa-about-heading h2 {
        font-size: 5.6vw;
    }

    .dsa-about-subheading {
        padding: 1vw 0;
        font-size: 3.7vw;
        font-weight: 400;
        line-height: 5.6vw;
        width: 100%;
    }

    .dsa-about-image-wrapper {
        display: none;
    }

    .dsa-about-items-wrapper {
        width: 90vw;
        height: auto;
        margin: 0;
    }

    .dsa-about-info-wrapper {
        padding: 2vw 0;
    }

    .dsa-about-info {
        margin: 0;
        padding: 2vw 0;
    }

    .dsa-about-info>p {
        font-size: 3.7vw;
        margin-left: 2vw;
    }

    .dsa-about-icons {
        max-width: 7.2%;
    }
}