.blue-heading-tag {
    background-color: #87e9ff;
    /* padding: 0em 0.2em 0em 0.2em; */
    display: inline-block;
    /* color: #000; */
    line-height: 1.7vw;
}

@media (max-width: 600px) {
    .blue-heading-tag {
        background-color: white;
        line-height: 3vw;
    }
}
