.dsa-pre-requisites-wrapper {
    background: #9D76C1;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 20vw;
    margin-bottom: 5vw;
}

.dsa-pre-requisites-image-left {
    width: 16vw;
    transform: rotate(-180deg);
}

.dsa-pre-requisites-image-left img {
    width: 100%;
}

.dsa-pre-requisites-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-family: Poppins-SemiBold;
    color: #FFFFFF;
    width: 65vw;
}

.dsa-pre-requisites-wrapper .customCssRequisites {
    font-size: 2.3vw;
    padding-bottom: 1.4vw;
}

.dsa-pre-requisites-content p {
    font-family: Poppins-Regular;
    font-weight: 600;
    font-size: 1.3vw;
    padding-bottom: 0.7vw;
}

.dsa-pre-requisites-image-right {
    width: 16vw;
}

.dsa-pre-requisites-image-right img {
    width: 100%;
}

@media (max-width: 600px) {
    .dsa-pre-requisites-wrapper {
        height: 70vw;
        flex-direction: column;
    }

    .dsa-pre-requisites-content {
        padding: 0;
        margin-top: -5vw;
        margin-left: 5vw;
        width: 85vw;
        order: 2;
    }

    .dsa-pre-requisites-wrapper .customCssRequisites {
        font-size: 4.2vw;
        padding-bottom: 2.8vw;
    }

    .dsa-pre-requisites-wrapper p {
        font-size: 3.6vw;
    }

    .dsa-pre-requisites-image-left {
        width: 22vw;
        order: 3;
    }

    .dsa-pre-requisites-image-right {
        width: 22vw;
        margin-left: auto;
        order: 1;
    }
}