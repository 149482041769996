.dsa-logo-slider {
    padding: 0 2vw;
}

.dsa-logo-slider-img-wrapper {
    height: 5vw;
}

.dsa-google-logo {
    width: 76px;
    height: 26px;
}

.dsa-flipkart-logo {
    width: 94px;
    height: 25px;
}

.dsa-adobe-logo {
    width: 82px;
    height: 32px;
}

.dsa-microsoft-logo {
    width: 101px;
    height: 22px;
}

.dsa-amazon-logo {
    width: 79px;
    height: 24px;
}

.dsa-walmart-logo {
    width: 87px;
    height: 24px;
}

.dsa-razorpay-logo {
    width: 120px;
    height: 24px;
}

.dsa-paytm-logo {
    width: 64px;
    height: 20px;
    margin-left: 24px;
}

.dsa-oracle-logo {
    width: 86px;
    height: 22px;
}

.dsa-paloalto-logo {
    width: 92px;
    height: 24px;
}

.dsa-logo-slider-text {
    text-align: center;
    font-size: 1vw;
    padding-top: 2vw;
    padding-bottom: 2vw;
    font-family: Poppins-Medium;
}

@media (max-width: 600px) {
    .dsa-logo-slider-text {
        font-size: 3.3vw;
        padding-bottom: 5vw;
    }

    .dsa-logo-slider-img-wrapper {
        height: 12vw;
    }
}