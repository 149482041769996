.curriculum-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3vw 6vw;
    margin-bottom: 5vw;
}
.curriculum-container h2 {
    text-align: center;
    font-size: 2.5vw;
    font-family: Poppins-SemiBold;
    margin-bottom: 2vw;
}
.curriculum-section-wrapper {
    display: flex;
    justify-content: space-between;
}

.curriculum-card-wrapper {
    display: flex;
    flex-direction: column;
}
.curriculum-header-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 10px;
    border: 1px solid #444bab;
    background: #fff;
    margin: 2vw 12vw;
    padding: 0.5vw;
}
.curriculum-header-wrapper h3 {
    text-align: center;
    font-size: 1.5vw;
    font-family: Poppins-SemiBold;
    color: #444bab;
}
.curriculum-card {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    border-left: 6px solid #444bab;
    background: #ffffff;
    padding: 1vw;
    margin: 2vw;
    overflow-y: scroll;
    height: 8vw;
}
.curriculum-card h4 {
    color: #444bab;
    font-size: 1.1vw;
    font-family: Poppins-SemiBold;
}
.curriculum-card p {
    color: #444bab;
    font-size: 1.1vw;
    font-family: Poppins-Regular;
}

.curriculum-card::-webkit-scrollbar {
    width: 6px;
}

.curriculum-card::-webkit-scrollbar-track {
    background: #ffff;
    margin-top: 10px;
    margin-bottom: 10px;
}
.curriculum-card::-webkit-scrollbar-thumb {
    background: #c3c8ff;
}

.curriculum-card::-webkit-scrollbar-thumb:hover {
    background: #c3c8ff;
}

.download-curriculum {
    display: flex;
    align-items: center;
    border-radius: 8px;
    border: 3px solid rgba(68, 75, 171, 0.7);
    padding: 1vw 1.5vw;
    margin-top: 3vw;
    cursor: pointer;
}
.download-curriculum img {
    width: 1.7rem;
    height: 1.7rem;
}
.download-curriculum p {
    font-family: Poppins-SemiBold;
    color: #343a40;
    font-size: 1.2vw;
    padding: 0vw 0.5vw;
}
.mob-curriculum-section-wrapper {
    display: flex;
    flex-direction: column;
}
@media (max-width: 600px) {
    .curriculum-container {
        margin-top: 10vw;
    }
    .curriculum-container h2 {
        font-size: 6.4vw;
        margin-bottom: 0vw;
    }
    .curriculum-header-wrapper {
        margin: 5vw 25vw;
        margin-top: 10vw;
    }
    .curriculum-header-wrapper h3 {
        font-size: 3.5vw;
    }
    .curriculum-card {
        overflow-y: hidden;
        height: auto;
        padding: 3.2vw 0vw 3.2vw 5.6vw;
        /* padding-left: 3vw; */
    }
    .curriculum-card h4 {
        font-family: Poppins-Bold;
        font-size: 4.1vw;
    }
    .curriculum-card p {
        font-size: 3.5vw;
        width: 70%;
    }
    .download-curriculum {
        padding: 2vw 3vw 2vw 3vw;
        margin-top: 6vw;
    }
    .download-curriculum img {
        color: white;
        width: 1.3rem;
        height: 1.3rem;
    }
    .download-curriculum p {
        font-size: 3.5vw;
    }

    .show-more-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .show-more-text {
        width: 28.3vw;
        text-align: center;
        border-style: none;
        border-radius: 35px;
        background-color: white;
        color: #444bab;
        font-family: Poppins-SemiBold;
        font-size: 3vw;
        padding: 2vw;
    }
    .mob-curriculum-section-wrapper {
        margin-top: -5vw;
    }
}
