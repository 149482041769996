.password-validate-popup {
    position: absolute;
    top: 0;
    right: -17vw;
}

.password-validate-pop-up-wrapper span {
    margin-left: 2vw;
    text-align: center;
    font-size: 12px;
}
.pop-up-heading {
    font-family: "Poppins-Regular";
    color: #3a3d4e;
    font-size: 12px;
    margin-bottom: 0.5vw;
}
.pop-up-subheading {
    font-family: "Poppins-Regular";
    color: #3a3d4e !important;
    margin-bottom: 0 !important;
    padding: 0.5vw 0;
    font-size: 12px !important;
}
.pop-up-ticks-text {
    color: #3a3d4e;
    margin-bottom: 0.5vw !important;
}
.validate-text-icon {
    color: #888888;
}
.common-icons {
    position: absolute;
    text-align: center;
    font-size: 14px !important;
    margin-top: 0.3vw;
}
.password-validate-pop-up-wrapper {
    width: 15vw;
    height: 100%;
    padding: 1vw;
    background: #ffffff;
    position: relative;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    box-shadow: 0px 0.2px 4px rgba(0, 0, 0, 0.25);
}

@media (max-width: 600px) {
    .password-contain-popup {
        position: inherit !important;
        top: 0 !important;
        right: -0 !important;
        margin: 10px 0;
    }
    .common-icons {
        font-size: 20px !important;
    }
    .password-validate-pop-up-wrapper span {
        font-size: 14px;
        margin-left: 22px;
    }
    .pop-up-ticks-text {
        margin-bottom: 10px !important;
    }
    .password-validate-pop-up-wrapper {
        width: 100%;
        padding: 20px 10px;
    }
    .pop-up-subheading {
        margin-bottom: 7px !important;
    }
    .pop-up-heading {
        margin-bottom: 10px;
    }
    .password-validate-pop-up-wrapper :before {
        display: none;
    }
    .password-validate-popup {
        position: relative;
        right: 0;
        margin-bottom: 20px;
    }
}
