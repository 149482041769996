.prerequisites {
    height: 15vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 0px;
}
/* bakground-colors */
.lld-bg-color {
    background: linear-gradient(180deg, #2b69a3 0%, #aeafb0 212.98%);
}
.cpp-bg-color {
    background: linear-gradient(180deg, #1e2659 0%, #494f75 96%, #c8c9ca 209.44%);
    border-radius: 0px;
}
.react-bg-color {
    background: linear-gradient(180deg, #0b40ad 0%, #8e8f92 163.95%);
}
.dsa-bg-color {
    background: linear-gradient(180deg, #544a72 90.15%, #d0d0d0 140.71%);
}
.python-bg-color{
    background: linear-gradient(180deg, #074996 0%, #8E8F92 163.17%);
}
.prerequisites > h1 {
    font-family: "Poppins-SemiBold";
    font-weight: 600;
    font-size: 2.5vw;
    color: #ffffff;
    margin-bottom: 1.39vw;
}
.prerequisites > span {
    font-family: "Poppins-Medium";
    font-weight: 500;
    font-size: 1.3vw;
    text-align: center;
    color: #ffffff;
}

@media (max-width: 600px) {
    .prerequisites {
        height: 200px;
        padding: 20px 15px;
    }
    .prerequisites > h1 {
        font-size: 22px;
        margin-bottom: 15px;
    }
    .prerequisites > span {
        font-size: 14px;
    }
}
