.lld-free-trial-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 4vw 0vw;
    position: relative;
    margin-bottom: 8vw;
}
.lld-free-trial-wrapper p {
    font-family: Poppins-SemiBold;
    font-size: 2vw;
    color: #000;
    text-align: center;
}
.buttons-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2vw;
}
.free-trial-btn button {
    background: linear-gradient(91.59deg, #444bab 1.85%, #3ca5c2 99.69%);
    border: none;
    border-radius: 0.4vw;
    color: #ffffff;
    cursor: pointer;
    font-family: Poppins-SemiBold;
    font-size: 1.2vw;
    padding: 1vw 2vw;
    margin-right: 2vw;
}
.talk-to-us-btn {
    display: flex;
    align-items: center;
    border-radius: 10px;
    background-color: #ffffff;
    border: 3px solid rgba(68, 75, 171, 0.7);
    cursor: pointer;
    padding: 0.8vw 1vw;
}
.talk-to-us-btn p {
    color: #343a40;
    font-family: Poppins-SemiBold;
    font-size: 1.2vw;
    margin-right: 0.5vw;
}
.talk-to-us-btn img {
    width: 1.7rem;
    height: 1.7rem;
}
.lld-empty-inbox-img {
    position: absolute;
    right: 4vw;
    top: 8.5vw;
}
.lld-empty-inbox-img img {
    width: 14rem;
    height: 14rem;
}
@media (max-width: 600px) {
    .lld-free-trial-wrapper {
        margin-bottom: 25vw;
    }
    .lld-free-trial-wrapper {
        padding: 3vw 6vw;
    }
    .lld-free-trial-wrapper p {
        font-size: 5.8vw;
    }
    .buttons-wrapper {
        align-items: center;
        margin-bottom: 12vw;
    }
    .free-trial-btn button {
        padding: 2.6vw;
        font-size: 4.2vw;
        border-radius: 10px;
    }
    .talk-to-us-btn {
        padding: 1.8vw;
    }
    .talk-to-us-btn p {
        font-size: 4.2vw;
        margin-right: 1.5vw;
    }
    .talk-to-us-btn img {
        width: 1.2rem;
        height: 1.2rem;
    }
    .lld-empty-inbox-img {
        display: none;
    }
}
