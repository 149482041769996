.custom-accordion-item {
    background-color: #fff;
    margin-bottom: 1vw;
    padding: 10px 20px;
    border: 1px solid #00000020;
}

.custom-accodrion-title {
    color: #4f516b;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.custom-accordion-item.custom-accordion-background {
    background-color: #f7fafc;
}

.custom-accodrion-title h2 {
    font-family: "Poppins-Regular";
    font-size: 1.1vw;
}
.custom-accordion-answer {
    font-size: 1.1vw;
}

.custom-accodrion-content {
    color: #393939;
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0, 1, 0, 1);
    font-family: "Poppins-Regular";
    font-size: 1.1vw;
}

.custom-accodrion-content.custom-accodrion-show {
    padding-top: 1.5vw;
    height: auto;
    max-height: 9999px;
    transition: all 1.2s cubic-bezier(0, 1, 0, 1);
}

@media (max-width: 600px) {
    .custom-accodrion-title h2,
    .custom-accodrion-content {
        font-size: 15px;
        padding: 0 0.5vw;
    }
    .custom-accordion-item.custom-accordion-background {
        background-color: #fff;
    }
    .custom-accordion-item {
        background-color: #f7fafc;
    }
    .custom-accordion-img-wrapper {
        width: 100%;
    }
    .custom-accordion-answer {
        font-size: 15px;
    }
}
