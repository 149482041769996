.dsa-freetrial-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 4vw 0 6vw 0;
    gap: 1.2vw;
}

.dsa-free-trial-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.dsa-freetrial-wrapper p {
    font-family: Poppins-Semibold;
    font-weight: 500;
    font-size: 3vw;
    color: #231F20;
    text-align: center;
}

.dsa-buttons-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2vw 2vw 0 2vw;
}

.dsa-free-trial-btn button {
    background: linear-gradient(180deg, #3940A0 0%, #3CA3C1 100%);
    border: none;
    border-radius: 0.4vw;
    color: #ffffff;
    cursor: pointer;
    font-family: Poppins-Medium;
    font-size: 1.4vw;
    padding: 0.8vw 2vw;
    margin-left: 2vw;
}

.dsa-talk-to-us-btn {
    display: flex;
    align-items: center;
    border-radius: 10px;
    background: #E7EAEE;
    cursor: pointer;
    padding: 0.8vw 1vw;
}

.dsa-talk-to-us-btn p {
    color: #343a40;
    font-family: Poppins-Medium;
    font-size: 1.4vw;
    margin-right: 0.5vw;
}

.dsa-talk-to-us-btn img {
    width: 1.7rem;
    height: 1.7rem;
}

@media (max-width: 600px) {
    .dsa-freetrial-wrapper {
        padding: 4vw 3vw;
        margin: 0 0vw;
    }

    .dsa-free-trial-content p {
        font-size: 3.7vw;
        margin-bottom: 0vw;
    }

    .dsa-buttons-wrapper {
        align-items: center;
        margin-bottom: 3vw;
    }

    .dsa-free-trial-btn button {
        padding: 1.9vw 4.1vw;
        font-size: 3.4vw;
        border-radius: 10px;
    }

    .dsa-talk-to-us-btn {
        padding: 1.9vw 2.5vw;
    }

    .dsa-talk-to-us-btn p {
        font-size: 3.4vw;
        margin-right: 1.5vw;
    }

    .dsa-talk-to-us-btn img {
        width: 1.1rem;
        height: 1.1rem;
    }

    br {
        content: none;
    }
}