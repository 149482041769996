.springboot-audience {
    padding: 60px 0px;
}

.springboot-audience .springboot-audience-heading {
    font-family: Gilroy-Bold;
    font-size: 48px;
    font-weight: 400;
    line-height: 64px;
    letter-spacing: 0.04em;
    text-align: center;
    width: 100%;
    color: #020617;
}

.springboot-audience .springboot-audience-card-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
    padding: 60px 82px;
}

.springboot-audience .springboot-audience-card {
    padding: 40px;
    background: #e1e7f8;
    border-radius: 20px;
    overflow: hidden;
}

.springboot-audience .springboot-audience-card .springboot-audience-card-heading {
    font-family: Gilroy-Bold;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.02em;
    text-align: left;
}

.springboot-audience .springboot-audience-card .springboot-audience-card-paragraph {
    font-family: Gilroy-Regular;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: left;
}

.springboot-audience .springboot-audience-button-container {
    display: flex;
    justify-content: center;
    padding: 0px 20px;
}

.springboot-audience .springboot-audience-free-trial-button {
    background: #3940a0;
    font-family: Gilroy-Bold;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: left;
    text-transform: capitalize;
    padding: 16px 32px;
    border-radius: 10px;
}

.springboot-audience .springboot-audience-card .springboot-audience-image-container {
    display: flex;
    margin-top: 40px;
    justify-content: end;
}

.springboot-audience .springboot-audience-card .springboot-audience-image-container img {
    object-fit: contain;
    overflow: auto;
}

@media (max-width: 600px) {
    .springboot-audience .springboot-audience-card-container {
        grid-template-columns: repeat(1, 1fr);
        padding: 20px;
        gap: 12px;
    }

    .springboot-audience .springboot-audience-heading {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.02em;
    }

    .springboot-audience .springboot-audience-card .springboot-audience-card-heading {
        font-size: 20px;
        line-height: 28px;
    }

    .springboot-audience .springboot-audience-card .springboot-audience-image-container img {
        margin-top: 12px;
        width: 100%;
    }

    .springboot-audience .springboot-audience-button-container a {
        width: 100%;
    }

    .springboot-audience .springboot-audience-free-trial-button {
        width: 100%;
    }

    .springboot-audience .springboot-audience-card {
        padding: 20px 16px;
    }
}
