.next-arrow {
    cursor: pointer;
    position: absolute;
    width: 36px;
    height: 36px;
    background-color: #292c33;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transform: translateY(-50%);
    color: #f8fafc;
  }
  .next-arrow-icon {
    width: 10px;
    height: 16px;
  }
  