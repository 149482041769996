.AdsLiveClassForm-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Poppins-Regular";
    height: 100%;
    font-size: 0.9vw;

    background-image: url(../../../img/ads-background-circles.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.AdsLiveClassForm-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    width: 50%;
    max-width: 600px;
    background-color: white;
    border-radius: 10px;
    padding: 2vw;
    box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.1);
}

.AdsLiveClassForm-LogoHeading {
    width: 80%;
}

.AdsLiveClassForm-FormTitle {
    font-family: "Poppins-SemiBold";
    margin: 20px 0 10px 0;
}

.AdsLiveClassForm-form {
    width: 100%;
}

.AdsLiveClassForm-input-div {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
}

.AdsLiveClassForm-input-div label {
    font-family: "Poppins-SemiBold";
    margin-bottom: 5px;
    text-align: left;
}

/* !important to override phone input classes */
.AdsLiveClassForm-phone-input,
.AdsLiveClassForm-input-div input {
    background-color: #f7f8f9 !important;
    border: 1px solid #d0d5dd !important;
    border-radius: 5px !important;

    min-height: 20px !important;
    height: 2vw !important;
    width: 100% !important;
    font-size: 0.9vw !important;

    padding-left: 10px;
}

.AdsLiveClassForm-error {
    font-size: x-small;
    text-align: left;
    margin-left: 5px;
    color: rgb(233, 64, 64);
}

.AdsFormSuccessModal-container {
    padding: 2vw;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    max-width: 20%;
    font-size: 0.9vw;
    text-align: center;
    border-radius: 20px;
    border: 1px solid var(--Neutral-100, #e7eaee);
}

@media (max-width: 600px) {
    .AdsLiveClassForm-container {
        width: 100%;
        padding: 30px 20px;
        font-size: 1rem;
        margin: 20px 0 40px 0;
    }

    /* !important to override phone input classes */
    .AdsLiveClassForm-phone-input,
    .AdsLiveClassForm-input-div input {
        height: 40px !important;
        font-size: 1rem !important;
    }

    .AdsLiveClassForm-error {
        font-size: x-small;
        margin-left: 5px;
        color: rgb(233, 64, 64);
    }

    .AdsFormSuccessModal-container {
        padding: 2rem 1rem;
        max-width: 90%;
        font-size: 0.9rem;
    }
}
