.our-curriculum {
  background: #fafafb;
  margin-bottom: 2vw;
  padding-top: 40px;
  padding-bottom: 80px;
}
.our-curriculum-polygon {
  clip-path: polygon(0 0, 100% 20%, 100% 100%, 0 80%);
}
.our-curriculum-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 6vw 0;
  background-color: #e8edfb;
  padding: 5vw 6vw;
  border-radius: 5vw;
}
.our-curriculum-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.our-curriculum-content .customCss {
  font-size: 3vw;
  padding-bottom: 3vw;
  font-family: Poppins-Medium;
}
.course-modules-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 1vw;
  justify-content: center;
}
.view-curriculum-btn {
  text-align: center;
  padding-bottom: 1vw;
  padding-top: 2vw;
}
.view-curriculum-btn a {
  text-decoration: none;
}
.course-module-title {
  display: flex;
  align-items: center;
  font-family: "Poppins-Medium";
  color: #231f20;
  font-size: 1.1vw;
  font-weight: 600;
  padding: 0.5vw 1.5vw;
  margin-bottom: 0.1vw;
}
.clicked-module-title {
  display: flex;
  align-items: center;
  font-family: "Poppins-Medium";
  color: #444bab;
  font-size: 1.1vw;
  font-weight: 600;
  padding: 0.5vw 1.5vw;
  margin-bottom: 0.1vw;
}
.electives-heading {
  font-size: 2vw;
  font-family: Poppins-Semibold;
  color: #000;
  padding-top: 4vw;
  padding-bottom: 1vw;
}
.course-module-image {
  padding: 0 0.5vw 0 0.7vw;
  height: 2.6rem;
}
.optional-text {
  color: #64748b;
}
.ren-subtopics-wrapper {
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.5vw;
  column-gap: 1.5vw;
}
.subtopics-content {
  display: flex;
  width: 47.5%;
  flex-direction: column;
  background-color: #e8edfb;
  border-radius: 0.7vw;
  padding: 1vw 1.1vw;
  margin-top: 1vw;
}
.subtopics-heading {
  font-size: 1rem;
  font-family: Poppins-Semibold;
  padding-bottom: 0.5vw;
}
.subtopics-points-wrapper {
  display: flex;
  flex-direction: column;
}
.subtopics-points {
  font-size: 0.9rem;
  font-family: Poppins-Regular;
  display: flex;
  align-items: center;
  padding-bottom: 0.3vw;
}
.course-guidance {
  font-family: "Poppins-Medium";
  color: #231f20;
  font-size: 1.1vw;
  font-weight: 600;
  margin-bottom: 0.1vw;
  text-align: left;
}
.course-module-content {
  color: #231f20;
  font-family: "Poppins-Medium";
  font-weight: 500;
  font-size: 1.1vw;
  max-height: 0;
  overflow: hidden;
  padding: 0 1.9vw;
}
.course-module-content.course-module-topics {
  height: auto;
  padding: 0.7vw 1.9vw;
  margin-bottom: 1.1vw;
  max-height: 9999px;
  transition: all 2s cubic-bezier(0, 1, 0, 1);
}

.course-module-title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 1.2vw;
  cursor: pointer;
}
.course-module-duration {
  color: #64748b;
  font-size: 1vw;
  font-family: "Poppins-Regular";
}
.course-line-break {
  border-top: 1px solid #e7eaee;
}
.course-module-section {
  width: 75vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 0.7vw;
  padding: 0.4vw 0.2vw;
  margin-bottom: 0.3vw;
  background: #f7f8f9;
}
.our-curriculum-wrapper h3 {
  font-family: "Poppins-SemiBold";
  color: #444bab;
  font-size: 1.2vw;
}

.our-curriculum-wrapper h2 {
  font-family: "Poppins-SemiBold";
  color: #4f516b;
  margin: 2vh 0;
  font-size: 2.4vw;
}

.our-curriculum-subheading {
  font-family: "Poppins-SemiBold";
  color: #4f516b;
  font-size: 2.4vw;
  margin-bottom: 1vw;
}

.our-curriculum-desc {
  font-family: "Poppins-Regular";
  color: #505050;
  text-align: justify;
  padding-bottom: 20px;
  font-size: 1.1vw;
}

.our-curriculum-content {
  margin-bottom: 2vw;
}

.our-curriculum-mentors-img {
  display: flex;
  justify-content: center;
}

.our-curriculum-mentors-img img {
  width: 32vw;
  margin-top: 2vw;
  padding: 2vw 0;
  padding-left: 4vw;
}

.our-curriculum-subjects {
  display: flex;
  align-items: center;
  padding: 5px 0;
}
.our-curriculum-subjects p {
  font-family: "Poppins-Regular";
  color: #505050;
  text-align: justify;
  font-size: 1.1vw;
}
.our-curriculum-subjects img {
  width: 3%;
  padding-right: 8px;
}
.our-curriculum-desc:last-child {
  padding-top: 20px;
}
.ren-download-curriculum {
  display: flex;
  align-items: center;
  border-radius: 8px;
  background-color: #3940a0;
  border: 3px solid rgba(68, 75, 171, 0.7);
  padding: 0.7vw 1.8vw;
  margin-top: 3vw;
  cursor: pointer;
}
.ren-download-curriculum img {
  width: 1.8vw;
  height: 1.8vw;
}
.ren-download-curriculum p {
  font-family: Poppins-SemiBold;
  color: #fff;
  font-size: 1.2vw;
  padding: 0vw 0.7vw;
}
.trophy-icon {
  padding-top: 0.3vw;
  padding-right: 0.3vw;
  height: 2rem;
  width: 2rem;
}

.customCss-clicked-module-title {
  color: #444bab !important;
}

.customCss-course-module-title {
  color: #231f20 !important;
}

.customCss {
  color: black !important;
}

@media (max-width: 600px) {
  .our-curriculum-wrapper {
    margin: 50px 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10vw;
    padding: 5vw 0;
    margin-bottom: 5vw;
  }
  .our-curriculum-desc,
  .our-curriculum-subjects p {
    font-size: 15px;
  }

  .our-curriculum-subheading {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .our-curriculum-subjects img {
    width: 4%;
  }
  .our-curriculum {
    padding: 100px 0;
    text-align: center;
    padding-bottom: 80px;
  }
  .our-curriculum-content {
    width: 100%;
    padding: 0 4vw;
  }
  .our-curriculum-content .customCss {
    font-size: 6vw;
  }
  .our-curriculum-mentors-img {
    justify-content: center;
    width: 100%;
  }
  .our-curriculum-wrapper h3 {
    font-size: 3.5vw;
  }
  .our-curriculum-wrapper h2 {
    font-size: 5vw;
  }

  .our-curriculum-mentors-img img {
    width: 65vw;
  }
  .course-module-section {
    width: 100%;
    margin-bottom: 4vw;
    padding: 0;
    border-radius: 2.5vw;
  }
  .course-module-section > h4 {
    font-size: 16px;
    margin-bottom: 1.2vw;
  }
  .course-module-section > p {
    font-size: 15px;
  }
  .course-modules-wrapper {
    margin-bottom: 2vw;
    gap: 0;
    width: 100%;
  }
  .course-module-image {
    height: 1.5rem;
    padding: 0;
    padding-right: 2vw;
  }
  .course-module-title-wrapper {
    padding: 2vw 2.5vw 2vw 0vw;
  }
  .course-module-title,
  .clicked-module-title {
    font-size: 3.6vw;
    font-family: Poppins-Regular;
    width: 80%;
    padding-right: 0.5vw;
  }
  .course-module-duration {
    font-size: 3vw;
  }
  .course-module-topics {
    font-size: 3.5vw;
  }
  .view-curriculum-btn {
    padding-bottom: 10vw;
  }
  .subtopics-heading {
    font-size: 0.9rem;
  }
  .subtopics-content {
    width: 100%;
    border-radius: 2vw;
    margin-top: 4vw;
    padding: 4vw 2vw;
  }
  .subtopics-points {
    font-size: 0.9rem;
    padding-bottom: 1vw;
  }
  .electives-heading {
    font-size: 5vw;
    padding-bottom: 5vw;
  }
  .course-guidance {
    font-family: Poppins-Regular;
    color: #191d23;
    font-size: 3vw;
    font-weight: 500;
  }
  .ren-download-curriculum {
    padding: 1.7vw 2.8vw;
    margin-bottom: 4vw;
  }
  .ren-download-curriculum p {
    font-size: 3vw;
  }
  .ren-download-curriculum img {
    width: 3.5vw;
    height: 3.5vw;
  }
  .trophy-icon {
    padding-top: 1.5vw;
    padding-right: 1.5vw;
    height: 1.5rem;
    width: 1.5rem;
  }
}
