.policy-wrapper {
    padding: 0 5vw;
    padding-bottom: 5vw;
}

.policy-wrapper h2 {
    font-size: 1.6vw;
    color: #272834;
    font-family: "Poppins-SemiBold";
    padding: 2.5vw 0;
}
.policy-wrapper h3 {
    font-family: "Poppins-Regular";
    font-size: 1.2vw;
    margin-bottom: 1vw;
    color: #272834;
}
.policy-wrapper h5 {
    font-family: "Poppins-Regular";
    color: #272834;
    font-size: 1.2vw;
    margin-bottom: 2px;
}
.policy-wrapper p {
    font-family: "Poppins-Regular";
    color: #505050;
    font-size: 1.1vw;
    text-align: justify;
    padding-bottom: 0.5vw;
}
.policy-contents-wrapper {
    padding-bottom: 1.5vw;
}
.right-and-obligation-wrapper {
    margin-bottom: 2vw;
}
.right-and-obligation-wrapper-subtitle {
    margin-bottom: 1vw !important;
}
@media (max-width: 600px) {
    .policy-wrapper h2 {
        font-size: 3.8vw;
        padding: 40px 0;
        padding-bottom: 20px;
    }
    .policy-wrapper h3 {
        font-size: 3vw;
    }
    .policy-wrapper h5 {
        font-size: 3vw;
    }
    .policy-wrapper p {
        font-size: 2.8vw;
    }
    .policy-contents-wrapper {
        padding-bottom: 20px;
    }
}
