.python-audience-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 3vw;
    padding: 2vw 2.25vw;
}

.python-audience-heading-wrapper {
    text-align: center;
    font-family: Poppins-Regular;
    color: #000000;
}

.python-audience-heading-wrapper>h2 {
    font-size: 2.5vw;
    font-family: Poppins-Regular;
    font-weight: 600;
    padding-bottom: 1vw;
}

.python-audience-heading-wrapper>p {
    font-size: 1.5vw;
    font-weight: 400;
    padding-bottom: 1vw;
}

.python-audience-card-wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.python-audience-card {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 30%;
    padding: 2vw 4vw;
}

.python-audience-img {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 1vw;
}

.python-audience-img img {
    height: 6vw;
}

.python-audience-text {
    font-family: Poppins-Regular;
    font-size: 1.3vw;
    text-align: center;
    padding: 0.5vw 2.25vw;
}

@media (max-width: 600px) {
    .python-audience-wrapper {
        margin-bottom: 0.5vw;
        padding: 2vw 5vw;
    }

    .python-audience-heading-wrapper>h2 {
        font-size: 6.4vw;
        font-weight: 600;
    }

    .python-audience-heading-wrapper>p {
        font-size: 3.73vw;
        line-height: 5.6vw;
    }

    .python-audience-card-wrapper {
        padding: 1.5vw;
        margin-bottom: 0.5vw;
    }

    .python-audience-card {
        width: 45%;
        margin-bottom: 2vw;
        padding: 2vw 0;
    }


    .python-audience-img {
        width: 14vw;
        height: 14vw;
    }
    .python-audience-img img {
        height: auto;
        max-height: 13.86vw;
        min-height: 12.6vw;
        width: 13.86vw;
    }

    .python-audience-text {
        font-size: 3.2vw;
        padding: 0.5vw;
    }
}