.dsa-intro-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #F7F8F9;
    padding-left: 6vw;
}

.dsa-intro-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.dsa-intro-heading {
    display: flex;
    flex-direction: column;
}

.dsa-intro-heading h1 {
    font-family: "Poppins-SemiBold";
    font-size: 2.8vw;
    font-weight: 600;
    color: #231F20;
    padding-bottom: 1.25vw;
    padding-top: 5vw;
}

.dsa-intro-heading h2 {
    font-family: "Poppins-Medium";
    font-size: 1.7vw;
    font-weight: 500;
    color: #231F20;
    margin: 0.3vw 0;
}

.dsa-intro-img {
    width: 49vw;
}

.dsa-intro-img-mob {
    width: 100vw;
}

.dsa-intro-img-mob img {
    width: 100%;
}

.dsa-intro-points {
    display: flex;
    align-items: flex-start;
    padding: 0.5vw 0vw;
}

.dsa-intro-points img {
    width: 1.3rem;
    height: 1.2rem;
    margin-top: 0.3vw;
}

.dsa-intro-points p {
    font-size: 1.1vw;
    font-family: Poppins-Regular;
    color: rgba(0, 0, 0, 0.8);
    padding-left: 0.5vw;
}

.dsa-intro-cta-rating-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.dsa-intro-cta-wrapper {
    display: flex;
    flex-direction: column;
    padding-top: 1vw;
}

.dsa-intro-cta-btn-wrapper {
    margin: 1vw 0vw;
}

.dsa-intro-cta-btn-wrapper button {
    background: linear-gradient(91.59deg, #444bab 1.85%, #3ca5c2 99.69%);
    border: none;
    border-radius: 0.4vw;
    color: #ffffff;
    cursor: pointer;
    font-family: Poppins-Regular;
    font-weight: 600;
    font-size: 1.3vw;
    padding: .65vw 7vw;
    width: auto;
}

.dsa-free-lectures {
    color: #231F20;
    font-family: Poppins-Regular;
    font-weight: 700;
    font-size: 1.1vw;
    margin-bottom: 1vw;
}

.dsa-free-text {
    font-family: Poppins-Semibold;
    background: linear-gradient(180deg, #3940A0 0%, #3CA3C1 100%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}

.dsa-intro-rating-wrapper {
    display: flex;
    flex-direction: row;
    padding-bottom: 3vw;
    align-items: center;
}

.dsa-intro-rating-wrapper img {
    height: 3.3vw;
    margin-right: 1vw;
}

.dsa-star-rating-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.dsa-star-rating-text {
    font-family: Poppins-Regular;
    color: #231F20;
    font-weight: 500;
    font-size: 1.4vw;
}

@media (max-width: 600px) {
    .dsa-intro-wrapper {
        justify-content: center;
        padding: 7vw 0vw;
    }

    .dsa-intro-content {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .dsa-intro-heading {
        padding: 0 5vw;
    }

    .dsa-intro-heading h1 {
        font-size: 5.5vw;
    }

    .dsa-intro-heading h2 {
        font-size: 4vw;
        color: #231F20;
        font-family: Poppins-Regular;
        font-weight: 500;
    }

    .dsa-intro-img {
        width: 60vw;
    }

    .dsa-intro-points-wrapper {
        padding: 5vw;
    }

    .dsa-intro-cta-rating-wrapper {
        width: 90vw;
        flex-direction: column;
    }

    .dsa-star-rating-wrapper {
        flex-direction: column;
        align-items: center;
        padding: 0 3vw;
    }

    .dsa-star-rating-text {
        font-size: 4vw;
        padding: 0vw;
        width: max-content;
    }

    .dsa-intro-desc {
        font-size: 4vw;
        margin: 6vw 0vw;
        padding-bottom: 2vw;
    }

    .dsa-intro-points {
        padding: 1.7vw 0vw;
    }

    .dsa-intro-points p {
        font-size: 3.7vw;
    }

    .dsa-intro-points img {
        width: 24px;
        margin-right: 2vw;
        height: auto;
        margin-top: 1.2vw;
    }

    .dsa-intro-cta-btn-wrapper {
        display: flex;
        justify-content: center;
    }

    .dsa-intro-rating-wrapper {
        justify-content: center;
        padding: 4vw 0;
    }

    .dsa-intro-rating-wrapper img {
        height: 10vw;
    }

    .dsa-intro-cta-btn-wrapper button {
        border: none;
        border-radius: 1vw;
        font-size: 4vw;
        padding: 3vw 5vw;
        width: 90vw;
    }

    .dsa-free-lectures {
        display: flex;
        justify-content: center;
        font-size: 3.7vw;
        margin-bottom: 1vw 0;
    }
}