.site-card-border-less-wrapper {
    background: #ececec;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.redirect-card{
    padding: 40px 0;
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.redirecting-loader{
    width: 20vw;
}

.redirecting-msg {
    text-align: center;
    margin: 0 20px;
}

@media (max-width: 600px) {
    .redirecting-loader{
        width: 70vw;
    }
}

