.refund-policy-banner-wrapper {
    display: flex;
    background-color: #fafafc;
    gap: 3vw;
    align-items: cneter;
}
.refund-policy-content-wrapper {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 5vw;
    padding-top: 5vw;
}
.refund-policy-content-wrapper > h1 {
    font-family: "Poppins-SemiBold";
    font-size: 3.8vw;
    color: #272834;
}
.refund-policy-content-wrapper > p {
    color: #444bab;
    font-size: 1.7vw;
    font-family: "Poppins-Regular";
    margin-top: 2vw;
}
.refund-policy-img-wrapper img {
    width: 65%;
    margin-left: 20%;
}
@media (max-width: 600px) {
    .refund-policy-content-wrapper > p {
        font-size: 2.8vw;
    }
    .refund-policy-content-wrapper > h1 {
        font-size: 5vw;
    }
    .refund-policy-content-wrapper {
        width: 100%;
    }
    .refund-policy-banner-wrapper {
        display: inherit;
    }
    .refund-policy-img-wrapper {
        text-align: center;
    }
    .refund-policy-img-wrapper img {
        width: 80%;
    }
}
