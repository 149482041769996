.faq-section-content {
    text-align: center;
    padding-top: 3.3vw;
}

.the-faqs-tabs-section {
    margin-bottom: 8vw;
}

.faq-section-content h2 {
    font-family: "Poppins-SemiBold";
    color: #444bab;
    font-size: 1.2vw;
}

.faq-section-content h3 {
    font-family: "Poppins-medium";
    color: #4f516b;
    padding: 2vh 0;
    font-size: 2vw;
    padding-bottom: 4vw;
}

.faq-section-content p {
    font-family: "Poppins-Regular";
    color: #000;
    font-size: 1.2vw;
    padding-bottom: 4vw;
}
.faq-tabs-wrapper-mob {
    padding: 25px;
    outline: #d0d1e0;
}

.faq-select-mob {
    width: 100%;
    margin-bottom: 10px;
    font-family: "Poppins-SemiBold";
    font-size: 15px;
    padding: 8px 10px;
    background-color: #f7fafc;
}

@media (max-width: 600px) {
    .faq-section-content h2 {
        font-size: 18px;
    }

    .faq-section-content h3 {
        font-family: "Poppins-medium";
        color: #4f516b;
        margin: 2vh 0;
        font-size: 2vw;
        font-size: 25px;
    }

    .faq-section-content p {
        font-family: "Poppins-Regular";
        color: #000;
        font-size: 1.2vw;
        padding-bottom: 4vw;
        font-size: 15px;
    }
    .faq-section-content {
        background: #fff;
    }
}
