.features-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3vw 6vw;
  margin: 2vw 0;
}
.features-wrapper .customCss {
  font-size: 3vw;
  padding-bottom: 3vw;
  font-family: Poppins-Medium;
  font-weight: bold;
}
.features-wrapper p {
  font-size: 3.2vw;
  padding: 3vw 0;
  font-family: Poppins-Medium;
}
.features-wrapper img {
  width: 100%;
}
.personalisation-video-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60vw;
  height: 30vw;
  border-radius: 2vw;
  cursor: pointer;

  /* equivalent to frameborder=0 */
  border: none; 
}
.personalisaton-thumbnail {
  width: 60vw;
  height: 30vw;
  border-radius: 2vw;
  margin-bottom: 1.5vw;
}
@media (max-width: 600px) {
  .features-wrapper {
    padding: 2vw 4vw;
  }
  .features-wrapper .customCss {
    font-size: 6vw;
    text-align: center;
    padding-bottom: 5vw;
    font-weight: bold;
  }
  .personalisation-video-wrapper {
    width: 92vw;
    height: 49vw;
    border-radius: 4vw;
  }
  .features-wrapper p {
    font-size: 4.5vw;
    padding: 4vw 0;
  }
  .personalisaton-thumbnail {
    width: 92vw;
    height: 49vw;
    border-radius: 4vw;
    margin-bottom: 5vw;
  }
}
