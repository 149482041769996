.ren-success-stories-wrapper {
  padding: 3vw 8vw;
  margin-top: -1.5vw;
}
.ren-success-stories-wrapper .customCss {
  font-family: Poppins-Semibold;
  font-size: 2.6vw;
  color: #222;
  text-align: center;
  padding: 0.4vw 1vw;
  margin-bottom: 3vw;
}
.ren-success-stories-wrapper h2 {
  font-family: Poppins-Regular;
  font-weight: 500;
  font-size: 1.4vw;
  color: #222;
  text-align: center;
}
.ren-success-stories-wrapper .slick-list {
  margin-left: 3vw;
}
.ren-success-stories-card {
  width: 90% !important;
  border-radius: 20px;
  background: #f7f8f9;
  padding: 2vw;
  position: relative;
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 1vw;
  margin-left: 0.6vw;
}
.ren-success-stories-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.slick-prev {
  width: 2rem;
  height: 2rem;
  margin-left: 1.5vw;
}
.slick-next {
  width: 2rem;
  height: 2rem;
}
.ren-success-stories-card-quotes-img {
  width: 1.5rem;
  height: 1rem;
  margin-bottom: 2vw;
}
.ren-success-stories-card-img {
  width: 8rem;
  height: 8rem;
  margin: 2vw 0vw;
}
.ren-success-stories-card-txt {
  height: 13vw;
}
.ren-success-stories-card-txt p {
  font-family: Poppins-Regular;
  font-size: 1.1vw;
  text-align: center;
  color: #000;
}
.ren-success-stories-name-wrapper {
  display: flex;
  align-items: center;
}
.ren-success-stories-name {
  font-family: Poppins-SemiBold;
  font-size: 1.1vw;
  margin-right: 0.5vw;
}
.ren-success-stories-name-wrapper img {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
}
.ren-success-stories-company-logo-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ren-sde-text {
  color: #000;
  font-family: Poppins-Regular;
  font-size: 1vw;
}
.ren-logo-wrapper {
  padding: 0.3vw 0.5vw;
}
.ren-logo-wrapper img {
  width: 5rem;
  height: 2rem;
}
.google-wrapper img {
  width: 5rem;
  height: 2rem;
  margin-top: 0.3vw;
}
.amazon-wrapper img {
  width: 6rem;
  height: 2.5rem;
  margin-top: 0.5vw;
}
.paypal-wrapper img {
  width: 4rem;
  height: 2rem;
  margin-top: 0.3vw;
  margin-left: 0.3vw;
}
.ren-success-stories-read-more-btn {
  display: flex;
  justify-content: center;
  margin-top: 2.2vw;
}
.ren-success-stories-read-more-btn button {
  color: #fff;
  font-family: Poppins-Regular;
  background: #3940a0;
  cursor: pointer;
  border: 3px solid rgba(68, 75, 171, 0.7);
  border-radius: 8px;
  font-family: Poppins-Regular;
  font-weight: 600;
  font-size: 1.2vw;
  padding: 1vw 2vw;
  margin-right: 2vw;
}

@media (max-width: 600px) {
  .ren-success-stories-wrapper {
    margin-top: 6vw;
    margin-bottom: 4vw;
    padding: 3vw 4vw;
  }
  .ren-success-stories-wrapper .customCss {
    font-size: 5.5vw;
  }
  .ren-success-stories-wrapper h2 {
    font-size: 3.4vw;
  }
  .ren-success-stories-wrapper .slick-list {
    margin-left: 4vw;
    margin-right: 1vw;
  }
  .ren-success-stories-card {
    height: 120vw;
    margin: 3vw 2vw;
    padding: 1vw;
  }
  .ren-success-stories-card-quotes-img {
    margin: 7vw 4vw 3vw 4vw;
  }
  .ren-success-stories-wrapper .slick-prev {
    width: 2rem;
    height: 2rem;
    position: absolute;
    top: 132vw;
    left: 36vw;
    margin-left: 0;
  }
  .ren-success-stories-wrapper .slick-next {
    width: 2rem;
    height: 2rem;
    position: absolute;
    right: 36vw;
    top: 132vw;
  }
  .ren-success-stories-content {
    height: fit-content;
    justify-content: space-evenly;
    align-items: center;
  }
  .ren-success-stories-card-txt {
    height: auto;
    padding-left: 1vw;
  }
  .ren-success-stories-card-img {
    width: 7rem;
    height: auto;
  }
  .ren-success-stories-card-txt p {
    font-size: 4vw;
    padding: 2vw 0;
  }
  .ren-success-stories-name {
    font-size: 4.5vw;
  }
  .ren-sde-text {
    font-size: 4vw;
  }
  .ren-logo-wrapper img {
    width: 4rem;
    height: 1.5rem;
    padding: 0.5vw 0 0 1vw;
    margin-top: 0.5vw;
  }
  .google-wrapper img {
    width: 6rem;
    height: 2rem;
    margin-top: 1vw;
    margin-left: -0.2vw;
  }
  .amazon-wrapper img {
    width: 7rem;
    padding-top: 1.4vw;
  }
  .paypal-wrapper {
    margin-top: 2vw;
  }
  .paypal-wrapper img {
    margin-left: 1vw;
    height: 1.5rem;
    margin-top: -0.6vw;
  }
  .ren-success-stories-company-logo-wrapper {
    margin-top: 0.5vw;
  }
  .ren-success-stories-read-more-btn {
    margin-top: 15vw;
  }
  .ren-success-stories-read-more-btn button {
    font-size: 2.9vw;
    border: 1px solid #444bab;
    padding: 2.5vw 5vw;
  }
  .ren-success-stories-name-wrapper img {
    margin-top: 0.5vw;
  }
}
@media (max-width: 350px) {
  .ren-success-stories-wrapper .slick-prev {
    display: none;
  }
  .ren-success-stories-wrapper .slick-next {
    display: none;
  }
  .ren-success-stories-card {
    height: fit-content;
  }

}
