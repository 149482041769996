.cpp-scholarship-wrapper {
    background: #C9F1D4;
    width: 100vw;
    display: flex;
    justify-content: space-evenly;
    padding: 1.2vw 2vw;
    margin-bottom: 4vw;
}
.cpp-scholarship-about {
    color: #231F20;
    font-family: Poppins-Semibold;
    font-size: 2.3vw;
    line-height: 3vw;
}
.cpp-scholarship-text{
    color: #098265;
}
.cpp-scholarship-image-wrapper {
    padding: 2vw 0;
    width: 25vw;
}
.cpp-scholarship-image-wrapper img {
    width: 100%;
}
.cpp-scholarship-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.2vw;
    padding: 4vw 0.5vw 1vw 0.5vw;
}
.cpp-scholarship-button-wrapper {
    background-color: #3940A0;
    padding: 1vw 2vw;
    width: fit-content;
    border-radius: 0.5vw;
    margin: 2vw 1vw;
    box-shadow: 0px 4px 4px 0px #0000001A;
}
.cpp-scholarship-button-wrapper a {
    color: #fff;
    font-family: Poppins-Regular;
    font-weight: 600;
}
@media (max-width: 600px) {
    .cpp-scholarship-wrapper {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 2vw;
        margin-bottom: 6vw;
    }
    .cpp-scholarship-about {
        font-size: 4vw;
        line-height: 5vw;
    }
    .line-break {
        display: none;
    }
    .cpp-scholarship-image-wrapper {
        width: 90vw;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 3vw 0;
    }
    .cpp-scholarship-image-wrapper img {
        object-fit: fill;
        width: 45vw;
    }
    .cpp-scholarship-content {
        width: 90vw;
        padding: 1vw 6vw;
        font-size: 4vw;
        line-height: 5.25vw;
    }
    .cpp-scholarship-button-wrapper {
        margin-top: 6vw;
        padding: 2vw 5vw;
        border-radius: 2vw;
    }
    .cpp-scholarship-button-wrapper a {
        font-size: 3.5vw;
    }
}