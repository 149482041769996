.dsa-advantage-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f7f8f9;
}

.dsa-advantage-heading-wrapper {
    padding-top: 5.5vw;
    text-align: center;
    font-family: Poppins-Regular;
}

.dsa-advantage-heading-wrapper>h2 {
    font-size: 2.8vw;
    font-family: Poppins-Semibold;
    font-weight: 500;
    margin-bottom: 1vw;
}

.dsa-advantage-heading-wrapper>p {
    font-size: 1.7vw;
    font-family: Poppins-Regular;
    font-weight: 400;
    margin-bottom: 3vw;
}

.dsa-advantage-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dsa-advantage-card-wrapper {
    display: flex;
    flex-direction: row;
    width: 94vw;
    justify-content: space-between;
    padding-top: 1vw;
    padding-bottom: 5.5vw;
}

.dsa-advantage-content img {
    width: 76vw;
}

.dsa-advantage-card {
    display: flex;
    flex-direction: column;
    width: 20vw;
}

.dsa-advantage-card:nth-child(1) {
    margin-top: -1vw;
}

.dsa-advantage-card:nth-child(2) {
    padding-left: 1vw;
    margin-top: -5vw;
}

.dsa-advantage-card:nth-child(4) {
    padding-left: 1vw;
    margin-top: -4vw;
}

.dsa-advantage-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.dsa-advantage-type {
    font-size: 1.4vw;
    font-weight: 500;
    font-family: Poppins-Semibold;
    color: #191d23;
    margin-bottom: 1vw;
}

.dsa-advantage-text {
    font-size: 1.1vw;
    line-height: 1.9vw;
    font-weight: 300;
    color: #4b5768;
}

.dsa-advantage-img {
    width: 50%;
    display: flex;
    justify-content: center;
}

.dsa-advantage-img img {
    width: 35vw;
    height: auto;
    object-fit: cover;
    position: relative;
    top: -6vw;
    overflow: hidden;
    padding: 2vw;
}

.slick-prev {
    width: 2rem;
    height: 2rem;
    margin-left: 1.5vw;
}

.slick-next {
    width: 2rem;
    height: 2rem;
}

@media (max-width: 600px) {
    .dsa-advantage-wrapper {
        padding: 1vw 5vw;
        background-color: #fff;
        margin-bottom: 5vw;
    }

    .dsa-advantage-heading-wrapper {
        text-align: center;
        padding: 0 6vw;
    }

    .dsa-advantage-heading-wrapper>h2 {
        font-size: 5.6vw;
        padding-bottom: 2.8vw;
    }

    .dsa-advantage-heading-wrapper>p {
        font-size: 3.7vw;
        padding-bottom: 4.5vw;
    }

    .dsa-advantage-content-mob-wrapper {
        width: 100%;
        margin-bottom: 10vw;
    }

    .dsa-advantage-content-mob-wrapper .slick-next {
        width: 9vw;
        height: 9vw;
        margin-top: 42vw;
        margin-right: 36vw;
    }

    .dsa-advantage-content-mob-wrapper .slick-prev {
        width: 9vw;
        height: 9vw;
        margin-top: 42vw;
        margin-left: 36vw;
    }

    .dsa-advantage-card-mob {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        padding: 2vw 5vw;
        height: 67vw;
        border-radius: 2.4vw;
    }

    .dsa-advantage-card-mob img {
        width: 14vw;
    }

    .dsa-advantage-type {
        font-size: 4.2vw;
        text-align: center;
    }

    .dsa-advantage-text {
        font-size: 4.2vw;
        line-height: 6.5vw;
        text-align: center;
    }

    .dsa-advantage-img {
        width: 100%;
    }

    .dsa-advantage-img img {
        height: 50vw;
        width: 50vw;
    }
}