.explore-course-course-card-wrapper {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}
.explore-course-section {
    padding: 2vw 5vw;
}
.pl-1 {
    padding-left: 1vw !important;
}
.explore-course-course-card-img {
    width: 100%;
}

.pt-1 {
    padding-top: 1vw;
}
.explore-course-course-card {
    width: 28%;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    position: relative;
    cursor: pointer;
}
.explore-course-title {
    color: #4f516b;
    font-size: 2vw;
    font-family: "Poppins-SemiBold";
    text-align: center;
    margin-bottom: 3vw;
}
.explore-course-course-card-img-wrapper {
    position: relative;
}

.explore-course-content {
    color: #505050;
    font-family: "Poppins-Regular";
    font-size: 1.17vw;
    text-align: center;
    padding: 2vw 15vw;
    padding-bottom: 3vw;
}
.explore-course-course-card-title {
    color: #4f516b;
    font-family: "Poppins-SemiBold";
    font-size: 1.5vw;
    padding: 1vw;
    padding-bottom: 2vw;
}
.explore-course-course-card-desc {
    color: #505050;
    font-family: "Poppins-Regular";
    font-size: 1vw;
    padding: 1vw;
    margin-bottom: 5vw;
    padding-left: 1.5vw;
}
.explore-course-course-card-btn {
    font-family: "Poppins-SemiBold";
    font-size: 1.25vw;
    background-color: #ffffff;
    border: none;
    position: absolute;
    bottom: 5%;
    padding-left: 1.5vw;
    color: #444bab;
    text-align: center;
    display: flex;
    justify-content: center;
}
.coming-soon {
    color: #818187 !important;
    font-style: italic;
}
.arrow-right {
    width: 12%;
    margin-left: 0.5vw;
    margin-top: 0.3vw;
}

@media (max-width: 600px) {
    .explore-course-content {
        font-size: 15px;
        padding: 2vw 0vw;
        padding-bottom: 4vw;
    }

    .explore-course-title {
        font-size: 5vw;
        margin-bottom: 10vw;
    }
    .explore-course-course-card-wrapper {
        flex-direction: column;
    }
    .explore-course-course-card {
        width: 100%;
        margin-bottom: 5vw;
    }
    .explore-course-course-card-title {
        font-size: 16px;
        padding: 1vw 3vw;
        padding-top: 5vw;
    }
    .explore-course-course-card-desc {
        font-size: 14px;
        padding: 0 3vw;
        padding-top: 3vw;
        margin-bottom: 12vw;
    }
    .explore-course-course-card-btn {
        bottom: 2%;
        font-size: 14px;
        padding: 0 3vw;
    }
    .arrow-right {
        width: 15px !important;
        margin-left: 1.5vw;
        margin-top: 0.8vw;
    }
    .explore-course-section {
        padding-bottom: 10vw;
    }
}
