.python-success-stories-wrapper {
    padding: 3vw 6vw;
    margin-top: -1.5vw;
}

.python-success-stories-wrapper h2 {
    font-family: Poppins-Regular;
    font-weight: 500;
    font-size: 2.5vw;
    color: #222;
    text-align: center;
    padding: 1vw;
}

.python-success-stories-wrapper p {
    font-family: Poppins-Regular;
    font-weight: 500;
    font-size: 2vw;
    color: #222;
    text-align: center;
    margin-bottom: 1vw;
}

.python-success-stories-card-wrapper {
    display: flex;
    justify-content: space-between;
}

.python-success-stories-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    background: #ffffff;
    padding: 2vw;
    width: 32%;
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.python-success-stories-card-quotes-img {
    width: 1.5rem;
    height: 1rem;
    margin-bottom: 2vw;
}

.python-success-stories-card-img {
    width: 8rem;
    height: 8rem;
    margin: 2vw 0vw;
}

.python-success-stories-card-txt {
    display: flex;
    flex: 1 0 0;
}

.python-success-stories-card-txt p {
    font-family: Poppins-Regular;
    font-size: 1.1vw;
    text-align: center;
    color: #000;
}

.python-success-stories-name-wrapper {
    display: flex;
    align-items: center;
}

.python-success-stories-name {
    font-family: Poppins-SemiBold;
    font-size: 1.1vw;
    margin-right: 0.5vw;
}

.python-success-stories-name-wrapper img {
    width: 1.5rem;
    height: 1.5rem;
    margin-top: 0.3vw;
    cursor: pointer;
}

.python-success-stories-company-logo-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.python-sde-text {
    color: #000;
    font-family: Poppins-Regular;
    font-size: 1.5vw !important;
    margin-right: 0.5vw !important;
}

.google-wrapper img {
    width: 5rem;
    height: 2rem;
    margin-top: 0.3vw;
}

.amazon-wrapper img {
    width: 6rem;
    height: 2.5rem;
    margin-top: 0.5vw;
}

.paypal-wrapper img {
    width: 4rem;
    height: 2rem;
    margin-top: 0.3vw;
    margin-left: 0.3vw;
}

.python-success-stories-read-more-btn {
    display: flex;
    justify-content: center;
    margin-top: 4vw;
}

.python-success-stories-read-more-btn button {
    color: #343a40;
    font-family: Poppins-Regular;
    background: #ffffff;
    cursor: pointer;
    border: 3px solid rgba(68, 75, 171, 0.7);
    border-radius: 8px;
    font-family: Poppins-Regular;
    font-weight: 600;
    font-size: 1.2vw;
    padding: 1vw 2vw;
    margin-right: 2vw;
}

@media (max-width: 600px) {
    .python-success-stories-wrapper {
        margin-top: 2vw;
        padding: 3vw 4vw;
    }

    .python-success-stories-wrapper h2 {
        font-size: 6.4vw;
        font-weight: 600;
        font-family: Poppins-Medium;
    }

    .python-success-stories-wrapper p {
        font-size: 3.73vw;
    }

    .python-success-stories-card-wrapper {
        flex-direction: column;
        justify-content: center;
        padding: 3vw 0;
    }

    .python-success-stories-card {
        width: 93%;
        height: 125vw;
        margin: 5vw 1vw 5vw 3vw;
        padding: 5vw 2vw;
        box-shadow: 0px 6px 12px 0px #0000001A;
        background: #f7f8f9;
    }

    .python-success-stories-card-txt p {
        font-size: 4.5vw;
        line-height: 7.5vw;
        padding: 1vw;
    }

    .python-success-stories-name {
        font-size: 5.3vw;
    }

    .python-sde-text {
        font-size: 3.7vw !important;
    }

    .google-wrapper img {
        height: 1.5rem;
        margin-top: .5vw;
        padding: .5vw 0 0 1vw;
        width: 4rem;
    }

    .amazon-wrapper img {
        height: 1.5rem;
        margin-top: .5vw;
        padding: .5vw 0 0 1vw;
        width: 4rem;
    }

    .paypal-wrapper {
        margin-top: 2vw;
    }

    .paypal-wrapper img {
        margin-left: 1vw;
        height: 1.5rem;
        margin-top: -1.5vw;
    }

    .python-success-stories-read-more-btn button {
        font-size: 4.27vw;
        border: 1px solid #444bab;
        padding: 2.5vw 5vw;
        margin: 4vw 0;
    }

    .python-success-stories-name-wrapper img {
        margin-top: 0.5vw;
    }

    .python-stories-slider-arrows-wrapper {
        height: 10.7vw;
        display: flex;
        gap: 2vw;
        margin-top: 2vw;
        justify-content: center;
        cursor: pointer;
    }

    .python-stories-slider-arrows-wrapper img {
        height: 100%;
    }
}