.welcome-screen-wrapper{
    height: 100vh;
    width: 100vw;
    font-family: 'Lato-Regular';
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 5%;
    text-align: center;
}
.welcome-screen-logo{
    width: 80px;
    margin-bottom: 20px;
}
.welcome-screen-wrapper>h1{
    margin: 1.5vw 0vw;
    font-size: 2.5vw;
}
.welcome-screen-wrapper>span{
    font-size: 1.5vw;
    margin-bottom: 20px;
}
.welcome-screen-wrapper>p{
    font-size: 1.2vw;
    margin: 2vw 0vw;
}

@media (max-width: 600px) {
    .welcome-screen-wrapper>h1{
        font-size: 20px;
    }
    .welcome-screen-wrapper>span{
        font-size: 12px;
    }
    .welcome-screen-wrapper>p{
        font-size: 12px;
    } 
}