.dsa-curriculum-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 6vw 5vw 5vw 5vw;
}

.dsa-curriculum-wrapper h2 {
    font-family: "Poppins-Semibold";
    font-weight: 500;
    font-size: 2.778vw;
    text-align: center;
    color: #231f20;
    margin-bottom: 1vw;
    line-height: 3vw;
}

.customCssHeading {
    color: #231f20;
    font-family: Poppins-SemiBold;
    font-size: 1.3vw;
    font-weight: 500;
    margin-bottom: .1vw;
    padding: .6vw .5vw;
}

.dsa-curriculum-wrapper p {
    font-family: "Poppins-Regular";
    font-weight: 600;
    font-size: 1.667vw;
    text-align: center;
    color: #231f20;
    margin-bottom: 2.778vw;
}

.dsa-topics-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.dsa-topic-card {
    width: 65vw;
    display: flex;
    flex-direction: column;
    border-radius: 0.7vw;
    padding: 0.8vw 1.5vw 0.4vw 1.5vw;
    margin-bottom: 1.5vw;
    border: 1px solid #b8c0cc;
    background: #f7f8f9;
    cursor: pointer;
}

.dsa-topic-card-heading-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.5vw 1.5vw;
    margin-bottom: 0.5vw;
}

.dsa-topic-card-heading-wrapper span {
    color: #231f20;
    font-family: "Poppins-Medium";
    font-weight: 500;
    font-size: 1.1vw;
    padding: 0.5vw 1.5vw;
    height: fit-content;
    border-radius: 2vw;
    background: #e7eaee;
}

.dsa-topic-card-heading {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.dsa-topic-card-heading h4 {
    padding: 0.6vw 0.5vw;
    font-family: "Poppins-SemiBold";
    font-weight: 500;
    font-size: 1.3vw;
    color: #231f20;
    margin-bottom: 0.1vw;
}

.dsa-topic-card-clicked-heading {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.dsa-topic-card-clicked-heading h3 {
    padding: 0.6vw 0.5vw;
    font-family: "Poppins-SemiBold";
    font-weight: 500;
    font-size: 1.3vw;
    margin-bottom: 0.1vw;
    color: #3a51a6;
}

.dsa-topic-card-heading img {
    height: 1.3vw;
}

.dsa-subtopics-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.5vw 1.5vw;
    padding: 0.2vw 0.5vw;
    font-family: "Poppins-Regular";
    font-weight: 400;
    font-size: 1.1vw;
    color: #231f20;
}

.dsa-line-break {
    border-top: 1px solid #e7eaee;
}

.dsa-subtopics-wrapper>img {
    height: 2vw;
    width: 2vw;
    margin-right: 1.5vw;
}

.dsa-subtopics-wrapper p {
    padding: 0.2vw 0.5vw;
    font-family: "Poppins-Regular";
    font-weight: 400;
    font-size: 1.1vw;
    color: #231f20;
    margin-bottom: 1rem;
}

.see-more {
    font-family: "Poppins-SemiBold";
    font-size: 1.1vw;
    color: #444bab;
    margin-bottom: 1vw;
    cursor: pointer;
}

.arrow-down {
    position: relative;
    top: 0.6vw;
    cursor: pointer;
    border-left: 0.4vw solid transparent;
    border-right: 0.4vw solid transparent;
    border-top: 0.6vw solid #444bab;
    margin-left: 1vw;
}

.arrow-up {
    position: relative;
    bottom: 1.5vw;
    cursor: pointer;
    border-left: 0.4vw solid transparent;
    border-right: 0.4vw solid transparent;
    border-bottom: 0.6vw solid #444bab;
    margin-left: 1vw;
}

.dsa-module-content {
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0, 1, 0, 1);
}

.dsa-module-content.dsa-module-topics {
    height: auto;
    max-height: 9999px;
    transition: all 1s cubic-bezier(0.95, 0.05, 0.8, 0.04);
}

.dsa-download-curriculum {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: #3940a0;
    padding: 1.1vw 2.2vw;
    margin-top: 1vw;
    cursor: pointer;
    width: fit-content;
}

.dsa-download-curriculum img {
    width: 1.667vw;
    height: 1.667vw;
    display: block;
}

.dsa-download-curriculum p {
    font-family: Poppins-SemiBold;
    color: #fff;
    font-size: 1.2vw;
    padding: 0vw 0.7vw;
    margin: 0; /* Removes default margins to align properly */
    line-height: 1;
}

.customCssSubTopics {
    font-weight: 400;
    margin-bottom: 0rem !important;
}

@media (max-width: 600px) {
    .dsa-curriculum-wrapper {
        padding: 2vw 5vw;
        margin-top: 10vw;
    }

    .customCssHeading {
        font-size: 4vw !important;
    }

    .dsa-curriculum-wrapper h2 {
        font-size: 5.6vw;
        line-height: 6.5vw;
    }

    .dsa-topic-card-clicked-heading h3{
        font-size: 3.3vw;
    }

    .dsa-curriculum-wrapper p {
        font-size: 3.7vw;
        padding-bottom: 2vw;
    }

    .dsa-topic-card {
        border-radius: 1.4vw;
    }

    .dsa-topics-wrapper .dsa-topic-card {
        margin-bottom: 20px;
        width: 90vw;
    }

    .dsa-topic-card-heading {
        font-size: 3.7vw;
        padding: 0.8vw 0;
        margin: 1vw;
    }

    .dsa-topic-card-clicked-heading {
        font-size: 3.7vw;
        padding: 0.8vw 0;
        margin: 1vw;
    }

    .dsa-topic-card-heading-wrapper {
        padding: 0.5vw 1vw;
        margin-bottom: 0;
    }

    .dsa-topic-card-heading-wrapper p {
        font-size: 3.7vw;
        font-family: "Poppins-Medium";
    }

    .dsa-topic-card-heading span {
        height: 5.6vw;
    }

    .dsa-topic-card-heading-wrapper span {
        font-size: 2.3vw;
        height: 5.6vw;
        margin: 1vw;
        border-radius: 6.3vw;
        width: 17vw;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .dsa-subtopics-wrapper {
        padding: 0.75vw 5vw;
        font-size: 3.2vw;
    }

    .dsa-subtopics-wrapper>img {
        height: 20px;
        width: 20px;
        margin-right: 15px;
    }

    .dsa-subtopics-wrapper  p {
        font-size: 3.2vw;
    }

    .see-more {
        font-size: 14px;
    }

    .arrow-down {
        top: 8px;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 8px solid #444bab;
    }

    .arrow-up {
        top: 8px;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 8px solid #444bab;
        margin-bottom: 28px;
    }

    .curriculum-wrapper {
        margin-top: 10vw;
    }

    .dsa-download-curriculum {
        padding: 3.7vw 2.8vw;
        border-radius: 2.3vw;
        margin-bottom: 4vw;
        align-items: center;
        width: 90vw;
    }

    .dsa-download-curriculum img {
        width: 5.6vw;
        height: 5.6vw;
    }

    .dsa-download-curriculum p {
        font-size: 3.7vw;
        padding: 0vw 2.75vw;
    }
}