.cpp-about-wrapper {
    display: flex;
    flex-direction: column;
    padding: 3vw 5vw;
    margin-bottom: 5vw;
}
.cpp-about-heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 2vw;
    margin-top: 6vw;
}
.cpp-about-heading h2 {
    font-family: Poppins-Semibold;
    font-size: 3.1vw;
    font-weight: 500;
    margin-bottom: 0.5vw;
    color: #000000;
}
.cpp-about-heading p {
    margin-bottom: 0.5vw;
    font-size: 1.4vw;
}
.cpp-about-subheading p {
    text-align: center;
}
.cpp-about-content {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.cpp-about-image-wrapper {
    height: auto; 
    width: 40vw;
    padding: 1vw 3vw;
}
.cpp-about-image {
    padding-top: 2.5vw;
    width: 40vw;
    overflow: hidden;
}
.cpp-about-items-wrapper {
    display: flex;
    width: 37vw;
}
.cpp-about-info-wrapper {
    display: flex;
    flex-direction: column;
    padding: 3vw 0 5vw 0;
    padding-left: 2vw;
    border-radius: 0.4vw;
    justify-content: center;
}
.cpp-about-info {
    display: flex;
    align-items: center;
    padding-right: 3vw;
    flex: 1;
}
.cpp-about-icons {
    max-width: 8%;
}
.cpp-about-info > p {
    font-family: "Poppins-Medium";
    font-weight: 500;
    font-size: 1.25vw;
    color: #333443;
    margin-left: 1.5vw;
}

@media (max-width: 600px) {
    .cpp-about-wrapper {
        padding: 2vw 5vw;
    }
    .cpp-about-heading {
        margin: 2vw 0;
        text-align: center;
    }
    .cpp-about-heading h2 {
        font-size: 5.5vw;
    }
    .cpp-about-subheading {
        display: none;
    }
    .cpp-about-image-wrapper {
        display: none;
    }
    .cpp-about-items-wrapper {
        width: 90vw;
        height: auto;
        margin: 3vw 0;
    }
    .cpp-about-info-wrapper {
        padding: 2vw 0;
    }
    .cpp-about-info {
        margin: 0;
        padding: 2.5vw 0;
    }
    .cpp-about-info > p {
        font-size: 4.1vw;
        margin-left: 2vw;
    }
    .cpp-about-icons {
        max-width: 7%;
    }
}
