.python-about-wrapper {
    display: flex;
    flex-direction: column;
    padding: 1vw 4vw;
    margin-bottom: 5vw;
}
.python-about-heading {
    display: flex;
    justify-content: center;
    margin-bottom: 2vw;
    margin-top: 3.25vw;
}
.python-about-heading h2 {
    font-family: Poppins-Regular;
    font-size: 3.1vw;
    font-weight: 500;
    color: #000000;
}
.python-about-content-wrapper {
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 10px 60px 0px rgba(38, 45, 118, 0.08);
    padding: 1vw 3vw;
}
.python-about-content {
    display: flex;
    flex-direction: column;
    width: 40vw;
}
.python-about-image-wrapper {
    height: auto; 
    width: 40vw;
}
.python-about-image {
    width: 40vw;
    overflow: hidden;
}
.python-about-description-wrapper {
    margin-top: -3vw;
}
.python-about-description-wrapper > p {
    font-family: "Poppins-Regular";
    font-weight: 400;
    font-size: 1.1vw;
    color: #2f3043;
    margin-bottom: 1.5vw;
}
.python-about-description-wrapper {
    margin-bottom: 1.25vw;
    font-size: 1.3vw;
}
.customHeading {
    font-size: 1.3vw !important;
    margin-bottom: 1.25vw !important;
    font-weight: 900 !important;
}
.python-about-items-wrapper {
    display: flex;
    width: 37vw;
}
.python-about-info-wrapper {
    display: flex;
    flex-direction: column;
    padding: 2.5vw 0;
    padding-left: 2vw;
    border-radius: 0.4vw;
    justify-content: center;
}
.python-about-info {
    display: flex;
    align-items: center;
    padding-right: 3vw;
    flex: 1;
}
.python-about-icons {
    max-width: 12%;
}
.python-about-info > p {
    font-family: "Poppins-Medium";
    font-weight: 500;
    font-size: 1.25vw;
    color: #333443;
    margin-left: 1.5vw;
}

@media (max-width: 600px) {
    .python-about-wrapper {
        width: 100%;
    }
    .python-about-heading h2{
        font-size: 6.4vw;
        font-weight: 600;
        padding: 2.5vw 0;
        text-align: center;
    }
    .python-about-content-wrapper {
        flex-direction: column;
        justify-content: center;
        box-shadow: none;
        padding: 0;
    }
    .python-about-content {
       width: 100%;
       align-items: center;
    }
    .python-about-image-wrapper {
        display: flex;
        justify-content: center;
        width: 80vw;
    }
    .python-about-image {
        width: 100%;
    }
    .python-about-description-wrapper > p {
        font-size: 3.2vw;
        line-height: 4.8vw;
    }
    .python-about-description-wrapper  {
        font-size: 4.27vw;
        line-height: 5.87vw;
    }
    .customHeading {
        font-size: 4.3vw !important;
        margin-bottom: 1.25vw !important;
    }
    .python-about-items-wrapper {
        width: 100%;
        height: auto;
        margin: 0;
    }
    .python-about-info-wrapper {
        padding: 0;
    }
    .python-about-info {
        margin: 0;
        padding: 2.5vw 0;
    }
    .python-about-info > p {
        font-size: 3.73vw;
        margin-left: 3vw;
    }
    .python-about-icons {
        max-width: 11.7%;
    }
}
