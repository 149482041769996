.python-advantage-wrapper {
    display: flex;
    flex-direction: column;
    margin: 5vw 7vw 2vw 7vw;
}

.python-advantage-heading-wrapper {
    text-align: center;
    font-family: Poppins-Regular;
}

.python-advantage-heading-wrapper>h2 {
    font-size: 3.5vw;
    font-weight: 500;
    margin-bottom: 1vw;
}

.python-advantage-heading-wrapper>p {
    font-size: 1.5vw;
    font-family: Poppins-Regular;
    font-weight: 400;
    margin-bottom: 9vw;
}

.python-advantage-card-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 2vw;
}

.python-advantage-card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 3vw 2vw;
    margin-top: -6vw;
}

.python-advantage-card:nth-child(even) {
    flex-direction: row-reverse;
}

.python-advantage-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 6vw 3vw;
    width: 45%;
}

.python-advantage-type {
    font-size: 2vw;
    font-weight: 500;
    font-family: Poppins-Semibold;
    color: #387EB8;
    margin-bottom: 1vw;
}

.python-advantage-text {
    font-size: 1.3vw;
    line-height: 1.9vw;
    font-weight: 300;
    color: #46464D;
    padding-right: 1vw;
}

.python-advantage-img {
    width: 50%;
    display: flex;
    justify-content: center;
    padding-bottom: 3vw;
}

.python-advantage-img img {
    width: 31vw;
    height: auto;
    object-fit: contain;
    overflow: hidden;
}

@media (max-width: 600px) {
    .python-advantage-wrapper {
        margin: 0;
        padding: 6vw 5vw;
    }

    .python-advantage-heading-wrapper {
        text-align: center;
    }

    .python-advantage-heading-wrapper>h2 {
        font-size: 6.4vw;
        font-weight: 600;
    }

    .python-advantage-heading-wrapper>p {
        font-size: 3.73vw;
        line-height: 5.6vw;
    }

    .python-advantage-card,
    .python-advantage-card:nth-child(even) {
        flex-direction: column-reverse;
        background-color: #FFFBEB;
        margin-top: 3vw;
        height: 94.4vw;
    }

    .python-advantage-data {
        width: 100%;
        justify-content: flex-start;
        text-align: center;
        padding: 0 3vw;
        height: 32vw;
    }

    .python-advantage-type {
        font-size: 4.27vw;
    }

    .python-advantage-text {
        font-size: 3.73vw;
        line-height: 5.3vw;
        padding-right: 0;
        padding-bottom: 2vw;
    }

    .python-advantage-img {
        width: 100%;
        flex: 1;
        align-items: center;
    }

    .python-advantage-img img {
        height: 50vw;
        width: 57.6vw;
        padding: 0;
        top: 0;
    }

    .python-advantage-slider-arrows-wrapper {
        height: 10.7vw;
        display: flex;
        gap: 2vw;
        margin-top: 5vw;
        justify-content: center;
        cursor: pointer;
    }

    .python-advantage-slider-arrows-wrapper img {
        height: 100%;
    }
}