.mentor-wrapper {
    display: flex;
    background-color: #fff;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3vw 6vw;
}

.mentor-wrapper h2 {
    font-weight: 500;
    font-size: 2.778vw;
    text-align: center;
    color: #231f20;
    margin-bottom: 2vw;
}

.dsa-mentor-heading {
    font-family: "Poppins-Semibold";
}

.cpp-mentor-heading {
    font-family: "Poppins-Medium";
}

.lld-mentor-heading {
    font-family: "Poppins-Semibold";
}

.python-mentor-heading {
    font-family: "Poppins-Regular";
}

.ren-mentor-heading {
    font-family: "Poppins-Medium";
}

.mentor-line-break {
    display: none;
}

.mentor-content-wrapper {
    display: flex;
    width: 77vw;
    justify-content: space-between;
    align-items: center;
}

.mentor-img-wrapper {
    display: flex;
    flex-direction: column;
    width: 22vw;
}

.mentor-img img {
    width: 22vw;
}

.mentor-details-wrapper {
    border-radius: 10px;
    border: 1px solid #231F20;
    padding: 0.5vw 0.8vw;
    margin-top: -2vw;
    background: #fff;
    border-radius: 1.3vw;
    display: flex;
    flex-direction: column;
}

.mentor-name-linkedin-gmail {
    display: flex;
    align-items: center;
    justify-content: center;
}

.mentor-name {
    font-family: Poppins-Regular;
    font-weight: 600;
    font-size: 1vw;
    color: #4b4f60;
    margin-right: 0.2vw;
}

.mentor-linkedin-icon {
    width: 28px;
    height: 28px;
    margin-right: 0.2vw;
    cursor: pointer;
}

.mentor-gmail-icon {
    width: 24px;
    height: 20px;
    margin-bottom: 0.3vw;
    cursor: pointer;
}

.mentor-company-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.mentor-company-wrapper img {
    width: 64px;
    height: 36px;
    padding-top: 0.1vw;
}

.mentor-company-text {
    font-family: Poppins-Regular;
    font-size: 0.8vw;
    color: #4b4f60;
}

.mentor-description {
    display: flex;
    width: 50vw;
    padding: 6.5vw 0;
    margin-left: 2vw;
}

.mentor-description p {
    font-family: Poppins-Regular;
    font-weight: 400;
    font-size: 1.667vw;
    color: #231F20;
}

.mentor-bold-text {
    font-weight: 500;
    font-family: Poppins-Semibold;
}

@media (max-width: 600px) {
    .mentor-wrapper {
        margin-top: 10vw;
        background-color: #FFF;
        padding: 5vw;
    }

    .mentor-wrapper h2 {
        font-size: 6vw;
        font-family: Poppins-Medium;
        font-weight: 600;
        margin-bottom: 5.2vw;
        display: flex;
        flex-direction: column;
        padding: 0vw;
    }

    .mentor-line-break {
        display: block;
    }

    .mentor-content-wrapper {
        flex-direction: column;
        width: 100%;
    }

    .mentor-img-wrapper {
        justify-content: center;
        align-items: center;
        width: 61vw;
    }

    .mentor-img img {
        width: 34vw;
        padding: 4vw 5vw;
        background-color: #f7f8f9;
    }

    .mentor-description {
        width: 100%;
        margin-left: 0;
    }

    .mentor-description p {
        font-size: 3.7vw;
        line-height: 7vw;
        padding: 1vw 0;
    }

    .mentor-details-wrapper {
        padding: 1.4vw 0.5vw;
        width: 61vw;
        margin-top: -7vw;
    }

    .mentor-name {
        font-size: 3.3vw;
    }

    .mentor-linkedin-icon {
        width: 18px;
        height: 18px;
        margin-right: 1vw;
    }

    .mentor-gmail-icon {
        width: 23px;
        height: 18px;
        margin-bottom: 2px;
    }

    .mentor-company-wrapper {
        margin-top: -1vw;
    }

    .mentor-company-wrapper img {
        margin-top: 0.9vw;
        width: 64px;
        height: 24px;
        margin-left: -0.5vw;
    }

    .mentor-company-text {
        font-size: 3.3vw;
    }
}