.subscription-card-wrapper {
  border: 1px solid #d0d5dd;
  border-radius: 16px;
  width: 25%;
  text-align: center;
  position: relative;
  padding-top: 4vw;
  padding-bottom: 2vw;
  background: #f7f8f9;
}
.pricing-wrapper {
  margin-top: 3vw;
  padding-top: 2vw;
}
.subscription-wrapper {
  padding-bottom: 4vw;
  margin-bottom: 5vw;
}
.subscription-card-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 4vw;
  padding-top: 3vw;
}

.subscription-card-container > * + * {
  margin-left: 4vw;
}
.card-services-point-wrapper {
  display: flex;
  margin-bottom: 1vw;
  align-items: center;
  padding-left: 10px;
}
.subscription-card-wrapper > img {
  position: absolute;
  top: -18px;
  left: 2vw;
  width: 42%;
}
.subscription-card-wrapper > .customCssDuration {
  font-family: "Poppins-Medium";
  color: #3940a0;
  font-size: 1.2vw;
  font-weight: bold !important;
  margin-bottom: -10px;
}
.subscription-card-wrapper > .customCssFinalPrice {
  font-family: "Poppins-Medium";
  color: #3940a0;
  font-weight: 900;
  font-size: 2.5vw;
  margin-bottom: -10px;
}
.subscription-card-wrapper > p {
  font-family: "Poppins-Medium";
  color: #231f20;
  font-size: 1vw;
  padding: 0.5vw 0;
  margin-bottom: 1.2vw;
}
.subscription-plan-price-saved-wrapper > .customCssPrice {
  font-family: "Poppins-Medium";
  text-decoration: line-through;
  color: #64748b;
  font-size: 1.4vw;
  margin-top: 2vw;
  margin-bottom: -10px;
}
.customCssPrice {
  font-weight: bold !important;
}
.subscription-card-wrapper > button {
  color: #444bab;
  background: #f7f8f9;
  font-family: "Poppins-Medium";
  font-weight: 600;
  text-transform: capitalize;
  font-size: 15px;
  border: 1px solid #444bab;
  border-radius: 10px;
  padding: 10px 10px;
  width: 80%;
  cursor: pointer;
}
#free-trial-users-plans-card-hr {
  margin-top: 2px;
  padding: 0.1vw;
  width: 13%;
  margin-left: 0;
  background: #3940a0;
  position: relative;
  left: 45%;
  margin-top: 10px;
}
.card-services-point-wrapper span {
  color: #444bab;
  font-family: "Poppins-Medium";
}
.card-services-point-wrapper > p {
  font-family: "Poppins-Medium";
  font-weight: 500;
  padding-left: 10px;
  color: #272834;
  font-size: 1vw;
}
.card-services-point-wrapper img {
  width: 1.3vw;
}
.card-services-wrapper {
  padding-top: 2vw;
  padding-left: 1vw;
}
.features-text {
  font-family: "Poppins-Semibold";
  padding-bottom: 0.5vw;
  text-align: left;
  font-weight: 500;
  color: #272834;
  font-size: 1vw;
  padding-left: 10px;
}
.EMI-detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.25vw;
  font-family: "Poppins-Medium";
  color: #9a4d06;
  font-weight: 500;
  margin-top: 1.5vw;
}
.EMI-detail p {
  font-size: 1.1vw;
}
.subscription-coupon-heading-wrapper {
  display: flex;
  align-items: center;
  padding: 0.2vw 0;
  margin-top: -1.5vw;
}
.subscription-coupon-heading-wrapper > img {
  width: 7%;
  margin-right: 10px;
}
.subscription-coupon-heading-wrapper > p {
  margin-top: 5px;
  font-family: "Poppins-Regular";
  color: #4f516b;
  font-size: 1.1vw;
  font-weight: 600;
}
.subscription-coupon-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.subscription-coupon-input-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.subscription-coupon-input-wrapper button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  left: 9vw;
  bottom: 2.5vw;
  border: none;
  border-radius: 0.4vw;
  background: #444bab;
  font-family: "Poppins-Regular";
  font-weight: 600;
  color: #fff;
  padding: 0.3vw 0.7vw;
  font-size: 1vw;
  cursor: pointer;
}
.subscription-coupon-input-wrapper input {
  width: 23.6vw;
  padding: 10px 0;
  font-size: 1vw;
  text-indent: 15px;
  font-family: "Poppins-Regular";
  color: #343a40;
  border: 1px solid #e7eaee;
  outline-color: #8a8a8d;
  margin-top: 3vw;
  background: #f7f8f9;
}

.modal-wrapper-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pricing-title-and-heading-wrapper {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.pricing-title-and-heading-wrapper .customCssPricing {
  font-size: 1.4vw;
  padding: 0.2vw 0.3vw;
  border: 2px solid #444bab;
  border-radius: 1.4vw;
  width: 10vw;
  font-family: "Poppins-Medium";
  color: #444bab;
}
.pricing-title-and-heading-wrapper .customCssPricingPlan {
  font-family: "Poppins-SemiBold";
  color: #231f20;
  margin-top: 2vh;
  margin-bottom: 1vh;
  font-size: 2.5vw;
}
.success-modal-wrapper {
  padding-top: 30px;
  width: 30%;
  margin: 180px auto;
  background: #fff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.error-message-card-wrapper {
  position: relative;
  padding-top: 30px;
  width: 30%;
  height: 30%;
  margin: 180px auto;
  background: #fff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.price-increase-wrapper {
  font-size: 1.4vw;
  font-family: Poppins-Regular;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2vh;
}
.price-increase-icon {
  color: #EF4444;
  margin-right: 5px;
  transform: scale(1.3);
}
.price-increase-text {
  color: #EF4444;
  padding-left: 5px;
}
.price-increase-text br {
  display: none;
}
.close-mark {
  position: absolute;
  top: 10px;
  right: 15px;
}
.success-modal-wrapper > img {
  width: 50%;
}
.success-modal-wrapper > button {
  margin-top: 2vw;
  width: 100%;
  padding: 20px 10px;
  background-color: #fff;
  font-family: "Gilroy-Medium";
  font-size: 1.6vw;
  border: none;
  border-top: solid rgba(136, 136, 136, 0.22);
  color: #444bab;
  border-radius: 0 0 24px 24px;
  cursor: pointer;
}
.success-modal-wrapper > h3 {
  font-family: "Poppins-Regular";
  font-size: 1.4vw;
}
.success-modal-wrapper > h4 {
  font-family: "Gilroy-Bold";
  font-size: 1.8vw;
  margin: 1vw 0;
}
.success-modal-wrapper > p {
  font-family: "Gilroy-medium";
  font-size: 1.2vw;
  color: #2c2e3e;
}
.error-coupon-code-wrapper {
  font-family: "Gilroy-Medium";
  font-size: 1.2vw;
  color: #ff5050;
  margin-top: 10px;
}
.start-free-trial-section {
  text-align: center;
  padding-bottom: 4vw;
}
.start-free-trial-section > h3 {
  font-size: 2.3vw;
  color: #4f516b;
  font-family: "Poppins-SemiBold";
  margin-bottom: 1vw;
}
.start-free-trial-section > p {
  margin-bottom: 2.3vw;
  font-size: 1.5vw;
  color: #4359af;
  font-family: "Poppins-Medium";
}
.subscription-plan-price-saved-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.money-saved-text {
  color: #e48915;
  font-family: "Lato-Regular";
  font-weight: 700;
  font-size: 1.25vw;
}
.subscription-modal-wrapper-bg {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.subscription-modal-wrapper {
  position: relative;
  width: 30%;
  background: #fff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 3% 0;
  pointer-events: auto;
}
.subscription-expired-close-icon {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
}
.subscription-expired-rocket-icon {
  width: 50px;
  margin: 0 0 3%;
}
.subscription-rocket-wrapper {
  background-color: linear-gradient(93.53deg, #444bab, #416bb3 62.5%, #3ca5c2);
}
.subscription-expired-heading {
  text-align: center;
  color: #4f516b;
  font-family: "Poppins-SemiBold";
  margin: 3% 0;
}
.subscription-expired-message {
  font-size: 14px;
  text-align: center;
  color: #656565;
  margin: 0 5% 8%;
}
.subscription-expired-button {
  background: linear-gradient(93.44deg, #444bab -6.8%, #3ca5c2 186.94%);
  border-radius: 6px;
  color: #ffff;
  cursor: pointer;
  text-align: center;
  font-family: Poppins-SemiBold;
  padding: 10px 15px;
}

.subscription-loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ren-scholarship-wrapper {
  background: #cadcee;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 5.5vw;
  padding: 1.2vw 1vw 1.2vw 4vw;
}
.ren-scholarship-about-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 60vw;
}
.ren-scholarship-about {
  color: #02182b;
  font-family: "Poppins-Medium";
  font-weight: 600;
  font-size: 2.1vw;
  width: 35vw;
}
.ren-scholarship-image-wrapper {
  padding: 2vw 1vw;
  width: 20vw;
}
.ren-scholarship-image-wrapper img {
  width: 20vw;
}
.ren-scholarship-content {
  width: 40vw;
  color: #02182b;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.2vw;
  line-height: 1.6vw;
  padding: 4vw 3vw 2vw 6vw;
}
.ren-scholarship-button-wrapper {
  background-color: white;
  padding: 0.5vw 3vw;
  width: fit-content;
  border-radius: 2vw;
  margin: 2vw 1vw;
  box-shadow: 0px 4px 9px 0px #00000040;
}
.ren-scholarship-button-wrapper a {
  color: #02182b;
  font-family: Poppins-Regular;
  font-weight: 600;
}
@media (max-width: 600px) {
  .subscription-card-wrapper {
    width: 100%;
    margin-bottom: 15vw;
    padding-top: 14vw;
    margin-left: 0;
  }
  .subscription-card-wrapper > button {
    width: 90%;
  }
  .subscription-card-container {
    display: inherit;
  }
  .subscription-card-wrapper > img {
    position: absolute;
    top: -2px;
    right: 0;
    width: 30%;
  }
  .subscription-card-wrapper > .customCssDuration {
    font-size: 20px;
    font-weight: bold !important;
  }

  .subscription-card-wrapper > .customCssFinalPrice {
    font-size: 32px;
    margin-bottom: 10px;
  }
  .subscription-card-wrapper > p {
    font-size: 15px;
    margin-bottom: 5vw;
  }
  .subscription-plan-price-saved-wrapper > .customCssPrice {
    font-size: 3.5vw;
    margin-top: 4vw;
  }
  .price-increase-wrapper {
    font-size: 3.4vw;
  }
  .price-increase-text {
    text-align: left;
  }
  .price-increase-text br {
    display: block;
  }
  .card-services-point-wrapper {
    margin-bottom: 2vw;
  }
  .card-services-point-wrapper > p {
    font-size: 15px;
  }
  .card-services-point-wrapper img {
    width: 5.5vw;
  }
  .card-services-wrapper {
    padding: 40px 0;
  }
  .success-modal-wrapper {
    width: 60%;
  }
  .success-modal-wrapper > h3 {
    font-size: 2.8vw;
  }
  .success-modal-wrapper > h4 {
    font-size: 3.6vw;
  }
  .error-coupon-code-wrapper {
    font-size: 2.2vw;
  }
  .success-modal-wrapper > p {
    font-size: 2.4vw;
  }
  .success-modal-wrapper > button {
    font-size: 3.2vw;
    padding: 15px 0;
  }
  .EMI-detail {
    font-size: 3.2vw;
    text-align: center;
    padding-bottom: 4vw;
  }
  .subscription-coupon-heading-wrapper > p {
    font-size: 3.8vw;
  }
  .subscription-coupon-heading-wrapper > img {
    width: 7%;
  }
  .subscription-coupon-input-wrapper {
    margin-top: -10vw;
  }
  .subscription-coupon-input-wrapper button {
    font-size: 4.2vw;
    left: 35vw;
    bottom: 9.5vw;
    padding: 2vw 3vw;
    border-radius: 1.5vw;
    left: 34vw;
    bottom: 12.5vw;
  }
  .subscription-coupon-heading-wrapper {
    margin-bottom: 10px;
    margin-top: -6vw;
  }
  .subscription-coupon-input-wrapper input {
    font-size: 4.5vw;
    width: 90vw;
    padding: 3.5vw 0;
  }
  .start-free-trial-section {
    margin: 30px 0;
  }
  .start-free-trial-section > h3 {
    font-size: 3.6vw;
    margin-bottom: 10px;
  }
  .start-free-trial-section > p {
    font-size: 3vw;
  }
  .EMI-detail p {
    font-size: 3.5vw;
  }
  .money-saved-text {
    font-size: 16px;
  }
  .subscription-modal-wrapper {
    width: 80%;
    padding: 7% 0;
  }
  .subscription-expired-heading {
    font-size: 11px;
  }
  .subscription-expired-message {
    font-size: 11px;
  }
  .subscription-expired-button {
    font-size: 11px;
  }
  .ren-scholarship-wrapper {
    flex-direction: column;
    padding: 7vw 2vw;
    margin-bottom: 6vw;
  }
  .ren-scholarship-about-wrapper {
    flex-direction: column-reverse;
    width: 90vw;
  }
  .ren-scholarship-about {
    font-size: 5vw;
    width: 90vw;
    text-align: center;
    padding: 5vw 0;
  }
  .ren-scholarship-image-wrapper {
    width: 85vw;
    left: 0;
    padding: 0;
  }
  .ren-scholarship-image-wrapper img {
    object-fit: fill;
    width: 80vw;
  }
  .ren-scholarship-content {
    width: 90vw;
    padding: 1vw 0vw;
    font-size: 3.5vw;
    line-height: 5vw;
    text-align: center;
  }
  .ren-scholarship-button-wrapper {
    margin-top: 4vw;
    padding: 3vw 17vw;
    border-radius: 6vw;
  }
  .ren-scholarship-button-wrapper a {
    font-size: 4vw;
  }
  .pricing-title-and-heading-wrapper .customCssPricing {
    font-size: 3.5vw;
    padding: 1.2vw 1.3vw;
    border-radius: 4.5vw;
    width: 25vw;
  }
  .pricing-title-and-heading-wrapper .customCssPricingPlan {
    font-size: 6vw;
  }
  .pricing-wrapper {
    margin-top: 8vw;
    margin-bottom: 12vw;
  }
  .subscription-wrapper {
    margin-top: 8vw;
  }
  .subscription-card-wrapper > img {
    top: -5vw;
    left: 6vw;
    width: 45%;
  }
  .features-text {
    padding-bottom: 2.5vw;
    font-size: 5vw;
  }
  #free-trial-users-plans-card-hr {
    padding: 0.4vw;
    width: 15%;
    left: 43%;
    margin-top: 2vw;
  }
}
