.blogs-wrapper {
    padding: 3vw 6vw;
    margin-top: -4vw;
}
.python-blog-heading {
    font-family: Poppins-Regular;
    font-weight: 500;
    font-size: 2.5vw;
    text-align: center;
    padding-bottom: 4vw;
}
.cpp-blog-heading {
    font-family: Poppins-Medium;
    font-weight: 500;
    font-size: 2.5vw;
    text-align: center;
    padding-bottom: 4vw;
}
.dsa-blog-heading {
    font-family: Poppins-Medium;
    font-weight: 500;
    font-size: 2.5vw;
    text-align: center;
    padding-bottom: 4vw;
}
.ren-blog-heading {
    font-family: Poppins-Medium;
    font-weight: 600;
    font-size: 2.8vw;
    text-align: center;
    padding-bottom: 4vw;
}
.blogs-wrapper span {
    font-weight: 900;
    font-family: Poppins-Semibold;
}
.lld-blog-heading {
    font-family: Poppins-Semibold;
    font-weight: 500;
    font-size: 2.5vw;
    text-align: center;
    padding-bottom: 4vw;
}
.blogs-wrapper .slick-list {
    margin-left: 3vw;
}
.blogs-card {
    width: 90% !important;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 1.3vw;
    position: relative;
    cursor: pointer;
    margin-bottom: 5vw;
    height: 27vw;
    overflow: hidden;
}
.blogs-card-img-wrapper img {
    width: 100%;
    height: 100%;
}

.slick-prev {
    width: 2rem;
    height: 2rem;
}
.slick-next {
    width: 2rem;
    height: 2rem;
}
.blogs-date-time-wrapper {
    display: flex;
    padding: 1vw;
    align-items: center;
}
.blogs-date {
    font-family: Poppins-Regular;
    font-size: 1vw;
    color: #231f20;
    margin-right: 0.5vw;
}
.blogs-time {
    font-family: Poppins-Regular;
    font-size: 1vw;
    color: #231f20;
}
.ellipse-icon {
    width: 1.5%;
    margin-right: 0.5vw;
}
.ellipse-icon img {
    width: 100%;
}
.blogs-card-desc {
    color: #000;
    font-family: Poppins-Medium;
    font-size: 1.4vw;
    padding: 1vw;
}
.read-more-blogs {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 8vw;
    margin-top: -5vw;
    position: relative;
    z-index: 1;
}
.read-more-blogs p {
    color: #444bab;
    font-family: Poppins-Regular;
    font-weight: 600;
    margin-right: 0.5vw;
    font-size: 1.4vw;
}
@media (max-width: 600px) {
    .blogs-wrapper {
        margin-top: 0;
    }
    .blogs-wrapper h1 {
        font-size: 6.4vw;
        font-family: Poppins-Medium;
        font-weight: 600;
    }
    .blogs-wrapper .slick-list {
        margin-left: 8vw;
    }
    .blogs-card-img-wrapper {
        width: 100%;
    }
    .blogs-card {
        height: 70vw;
        border-radius: 3.6vw;
    }
    .blogs-card-desc {
        font-size: 3.5vw;
        padding: 2vw;
        padding-top: 0vw;
    }
    .blogs-date {
        font-size: 3vw;
        margin-right: 2vw;
    }
    .blogs-time {
        font-size: 3vw;
        margin-left: 2vw;
    }
    .ellipse-icon {
        width: 0.75%;
    }
    .blogs-date-time-wrapper {
        padding: 2vw;
    }
    .read-more-blogs {
        margin-top: -2vw;
    }
    .read-more-blogs p {
        margin-right: 1.25vw;
        font-size: 3.73vw;
    }
    .read-more-blogs img {
        width: 1rem;
    }
}
