.common-freetrial-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 4vw 0 6vw 0;
    gap: 1.2vw;
}
.common-free-trial-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.common-freetrial-wrapper .free-trial-content {
    font-family: Poppins-Semibold;
    font-weight: 500;
    font-size: 3vw;
    color: #000;
    text-align: center;
}
.common-buttons-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2vw 2vw 0 2vw;
}
.common-free-trial-btn button {
    background: linear-gradient(180deg, #3940a0 0%, #3ca3c1 100%);
    border: none;
    border-radius: 0.4vw;
    color: #ffffff;
    cursor: pointer;
    font-family: Poppins-Medium;
    font-size: 1.4vw;
    padding: 0.8vw 2vw;
    margin-left: 2vw;
}
.common-talk-to-us-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background: #e7eaee;
    cursor: pointer;
    padding: 0.8vw 2vw;
}
.common-talk-to-us-btn p {
    color: #343a40;
    font-family: Poppins-Medium;
    font-size: 1.4vw;
    margin-right: 0.5vw;
}
.common-talk-to-us-btn img {
    width: 1.7rem;
    height: 1.7rem;
}
@media (max-width: 600px) {
    .common-freetrial-wrapper {
        padding: 4vw 3vw;
        margin: 0 0vw;
    }
    .common-free-trial-content .free-trial-content {
        font-size: 3.7vw;
        margin-bottom: 5vw;
    }
    .common-buttons-wrapper {
        flex-direction: column;
        gap: 12px;
        align-items: center;
        margin-bottom: 3vw;
    }
    .common-free-trial-btn {
        width: 100%;
    }
    .common-free-trial-btn button {
        width: 100%;
        padding: 16px 32px;
        font-size: 3.4vw;
        border-radius: 10px;
        margin-left: 0;
    }
    .common-talk-to-us-btn {
        width: 100%;
        text-align: center;
        padding: 16px 32px;
    }
    .common-talk-to-us-btn p {
        font-size: 3.4vw;
        margin-right: 1.5vw;
    }
    .common-talk-to-us-btn img {
        width: 1.1rem;
        height: 1.1rem;
    }
    br {
        content: none;
    }
}
