.springboot-outcomes {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #f7f8f9;
    padding: 92px 20px;
}

.springboot-outcomes .springboot-outcomes-heading {
    font-family: Gilroy-Bold;
    text-align: center;
    font-size: 48px;
    font-weight: 400;
    line-height: 64px;
    letter-spacing: 0.04em;
    width: 100%;
}

.springboot-outcomes .springboot-outcomes-card-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 80px;
    margin-top: 40px;
}

.springboot-outcomes .springboot-switcher-card {
    display: flex;
    gap: 32px;
}

.springboot-outcomes .springboot-switcher-card .springboot-outcomes-code-box {
    border-radius: 50%;
    background: #e1e7f8;
    padding: 16px;
}

.springboot-outcomes .springboot-switcher-card .springboot-outcomes-card-heading {
    font-family: Gilroy-Bold;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #020617;
}

.springboot-outcomes .springboot-switcher-card .springboot-outcomes-card-paragraph {
    font-family: Gilroy-Regular;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #475569;
}

.springboot-outcomes .springboot-outcomes-stepper-switcher-container {
    display: flex;
    gap: 40px;
    max-width: 720px;
}

.springboot-outcomes
    .springboot-outcomes-stepper-switcher-container
    .springboot-outcomes-stepper-switcher {
    padding-top: 70px;
    padding-bottom: 42px;
}

.springboot-outcomes
    .springboot-outcomes-stepper-switcher-container
    .springboot-outcomes-stepper-switcher
    .springboot-outcomes-switcher-horizontal-line {
    width: 4px;
    height: 100%;
    background: #d0d5dd;
}

.springboot-outcomes
    .springboot-outcomes-stepper-switcher-container
    .springboot-outcomes-stepper-switcher
    .springboot-outcomes-switcher-horizontal-line
    .colored-switcher-horizontal-line {
    background: #3940a0;
}

.springboot-outcomes
    .springboot-outcomes-card-container
    .springboot-switcher-card
    .springboot-outcomes-points-container {
    position: relative;
}

.springboot-outcomes
    .springboot-outcomes-card-container
    .springboot-switcher-card
    .springboot-outcomes-switcher-points {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #d0d5dd;
    position: absolute;
    margin-top: 25px;
    right: 34px;
}
.springboot-outcomes .springboot-switcher-card.change-background {
    .springboot-outcomes-code-box,
    .springboot-outcomes-switcher-points {
        background: #3940a0;
    }
}

.springboot-outcomes .springboot-switcher-card.change-background .springboot-outcomes-card-heading {
    color: #3940a0;
}

/* 1% 22% 41% 60% 78% 100% */

@media (max-width: 600px) {
    .springboot-outcomes {
        padding: 20px 20px;
        background: white;
    }

    .springboot-outcomes .springboot-outcomes-heading {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.02em;
    }

    .springboot-outcomes .springboot-switcher-card .springboot-outcomes-code-box {
        background: #3940a0;
    }

    .springboot-outcomes .springboot-switcher-card .springboot-outcomes-card-heading {
        font-size: 20px;
        color: #3940a0;
    }

    .springboot-outcomes .springboot-switcher-card-container {
        gap: 12px;
    }

    .springboot-outcomes .springboot-switcher-card {
        flex-direction: column;
        gap: 12px;
        background: #f8fafc;
        padding: 20px 16px;
        border-radius: 20px;
    }

    .springboot-outcomes
        .springboot-outcomes-stepper-switcher-container
        .springboot-outcomes-stepper-switcher {
        display: none;
    }

    .springboot-outcomes
        .springboot-outcomes-card-container
        .springboot-switcher-card
        .springboot-outcomes-points-container {
        display: none;
    }
    .springboot-outcomes .springboot-outcomes-card-container {
        gap: 12px;
    }

    .springboot-outcomes .springboot-switcher-card .springboot-outcomes-code-box {
        padding: 8px;
    }
}
