.learn-with-section-wrapper {
    margin-bottom: 6vw;
}

.instructors-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 2vw 5.3vw;
}
.instructors-wrapper > *_* {
    margin-left: 2vw;
}

.mentor-details {
    border: 1px solid #65a5c9;
    height: 30vw;
    width: 450px;
    padding: 30px;
    padding-bottom: 15px;
    border-radius: 2%;
}

.mentors-pic-and-logo-wrapper {
    display: flex;
    align-items: center;
    position: relative;
}

.mentors-pic-and-logo-container {
    position: relative;
    top: -70px;
}

.learn-with-sec-linked-in-img {
    border: 5px solid #fff;
    background: #fff;
    opacity: 1;
    border-radius: 2px;
    position: absolute;
    left: 120px;
    bottom: -150px;
}

.learn-with-mentor-img {
    position: absolute;
}

.mentor-company-logo {
    position: absolute;
    right: 50px;
    bottom: -65px;
}

.learn-with-sec-mentors-name {
    font-size: 1.1rem;
    font-family: "Poppins-Regular";
    color: #4b4f60;
    margin-bottom: 1vw;
    margin-top: 8vw;
}

.mentors-details-para {
    font-family: "Poppins-Regular";
    font-size: 1.1vw;
    color: #4b4f60;
}
.mentors-details-word-wrapper {
    margin-top: 85px;
}
@media (max-width: 600px) {
    .instructors-wrapper {
        flex-direction: column;
        align-items: center;
    }
    .mentors-details-para {
        font-size: 14px;
    }
    .mentor-details {
        margin-bottom: 80px;
        width: 350px;
        height: 360px;
    }
    .learn-with-mentor-img {
        width: 100px;
    }
    .learn-with-sec-linked-in-img {
        left: 75px;
        bottom: -110px;
    }
    .mentor-company-logo {
        right: -10px;
        bottom: -50px;
    }
    .mentors-details-word-wrapper {
        margin-top: 15vw;
    }
}
