.forgot-password-container {
    align-items: center;
    background: #fafafb;
    display: flex;
    height: 100%;
    justify-content: center;
    margin: 0 auto;
    padding: 3% 3% 5%;
    width: 100%;
}

.forgot-password-wrapper {
    background-color: #fff;
    align-items: center;
    padding: 2vw 1vw;
    width: 35%;
}
.forgot-password-input {
    margin-bottom: 3vw;
}
.forgot-password-CTA {
    margin-bottom: 3vw;
}
.signup-text {
    cursor: pointer;
}
.forgot-pwd-error {
    color: #d14444;
}
.password-contain-popup {
    position: absolute;
    top: -2vw;
    right: -22vw;
}
.forgot-pwd-otp-set-up-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    width: 35%;
    margin: 0 auto;
    padding: 2vw 1vw;
    border: 1px solid rgba(68, 75, 171, 0.1);
}
@media (max-width: 600px) {
    .forgot-password-container {
        padding: 0% 0%;
    }
    .forgot-pwd-otp-set-up-wrapper {
        padding: 1% 5%;
        width: 100%;
        border: none;
    }
    .common-form-wrapper {
        width: 100%;
        border: none;
    }
    .common-page-title {
        font-size: 6vw;
        padding: 5vw 0;
    }
    .common-input-styles {
        padding: 3vw 0.5vw;
        font-size: 3.2vw;
        text-indent: 10px;
    }
    .signup-setup-login {
        margin-top: 12vw;
    }
    .common-blue-gradient-CTA {
        padding: 3vw 0.5vw;
        font-size: 3.2vw;
    }
}
