.spring-banner {
    font-family: "Poppins-Regular";
    font-size: 0.95vw;
    font-weight: 600;
    padding: 0.6vw;
    letter-spacing: 0.56px;
    background: #FF9C3A;
    color: #F7F8F9;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.spring-banner img {
    height: 1.5vw;
    margin-right: 1.2vw;
}
.spring-banner button {
    padding: 0.6vw 1.1vw;
    border-radius: 8px;
    background: #FFFFFF;
    border: none;
    color: #191D23;
    font-family: "Poppins-Regular";
    font-size: 0.8vw;
    font-weight: 600;
    letter-spacing: 0.4px;
    margin-left: 1.2vw;
    cursor: pointer;
}

.spring-banner a:hover {
    background-color: #FF9C3A;
}

@media (max-width: 600px) {
    .spring-banner {
        font-size: 2.25vw;
        height: 12vw;
        letter-spacing: 0.5px;
        line-height: 4.5vw;
        padding: 0.6vw 1.4vw;
    }
    .spring-banner p {
        padding: 0.6vw;
        font-size: 2.2vw;
    }
    .spring-banner img {
        width: 22vw;
        height: 6vw;
        padding-left: 1vw;
        padding-right: 1vw;
        align-items: center;
        display: flex;
    }
    .spring-banner button {
        font-size: 2.3vw;
        padding: 1.7vw 0vw;
        width: 19.5vw;
        border-radius: 7px;
        margin-left: 1vw;
        margin-right: 0.5vw;
    }
}
