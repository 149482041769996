.common-intro-wrapper {
    display: flex;
}

/* background colors section */
.lld-background-color {
    background: linear-gradient(95.72deg, #2b69a3 0.92%, rgba(1, 4, 10, 0.3) 175.77%);
}
.Cpp-background-color {
    background: linear-gradient(
        95.53deg,
        #1e2659 0%,
        rgba(9, 13, 60, 0.616667) 75%,
        rgba(1, 4, 10, 0.2) 100%
    );
}
.dsa-background-color {
    background: linear-gradient(
        95.59deg,
        #3b3257 0%,
        rgba(74, 68, 94, 0.791667) 63.56%,
        rgba(53, 52, 54, 0) 120.82%
    );
}
.react-background-color {
    background: linear-gradient(
        95.54deg,
        #093fad 0%,
        rgba(19, 60, 144, 0.723958) 74.34%,
        rgba(45, 53, 66, 0) 114.19%
    );
}
.python-background-color {
    background: linear-gradient(94.87deg, #1d5ea8 0%, #2e68ab 59.77%, #c8c8ca 112.51%);
}

/* illustrator section  */
.react-illustrator-Img {
    width: 100%;
    height: 25vw;
    margin-top: 2vw;
    height: 100%;
    padding-bottom: 5vw;
}
.dsa-illustrator-img {
    padding: 4vw 0;
    padding-right: 6vw;
    width: 30vw;
    height: 100%;
}

/*end of background colors section */
.common-intro-wrapper > img {
    background-size: contain;
    width: 30vw;
}
.common-intro-content {
    display: flex;
    flex-direction: column;
    margin-left: 8vw;
    margin-right: 9vw;
}
.common-intro-content > h1 {
    font-family: "Poppins-SemiBold";
    font-size: 2.5vw;
    font-weight: 600;
    color: #ffffff;
    margin-bottom: 2.5vw;
    margin-top: 5vw;
}
.common-intro-desc {
    font-family: "Poppins-Regular";
    font-weight: 400;
    font-size: 1.3vw;
    color: #ffffff;
    margin-bottom: 2.5vw;
}
.common-intro-cta-btn {
    background: #ffffff;
    border-radius: 6px;
    border: none;
    font-family: "Poppins-SemiBold";
    font-weight: 600;
    font-size: 1.3vw;
    padding: 0.5vw 1.5vw;
    color: #444557;
    margin-bottom: 2.5vw;
    cursor: pointer;
    white-space: nowrap;
}
.common-recommend {
    font-family: "Poppins-Medium_Italic";
    font-size: 1.1vw;
    color: #ffffff;
}

.common-nav-sections {
    display: flex;
    height: 4.7vw;
    background: #f8f7f7;
    border: 1px solid #e8e8e8;
    padding: 1.5vw 3.5vw;
}
.common-nav-sections > a {
    cursor: pointer;
    font-family: "Poppins-Regular";
    font-weight: 400;
    font-size: 1.1vw;
    color: #1b1c28;
    margin-left: 3.7vw;
}
@media (max-width: 600px) {
    .common-intro-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }
    .common-intro-wrapper > img {
        width: 100%;
        height: 100%;
        margin-top: -30px;
    }
    .common-intro-content > h1 {
        font-size: 7vw;
        text-align: center;
    }
    .common-intro-desc {
        font-size: 4vw;
        text-align: center;
        margin-top: 5vw;
    }
    .common-intro-cta-btn {
        font-size: 4vw;
        padding: 4vw 5vw;
        margin: 8vw 0;
    }
    .common-recommend {
        font-size: 4vw;
        text-align: center;
        margin-top: 2.7vw;
    }
    .common-nav-sections {
        display: none;
    }
    .dsa-illustrator-img {
        padding: 0 4vw;
        width: 100%;
        padding-top: 1vw;
        padding-bottom: 8vw;
    }
    .common-intro-content {
        align-items: center;
    }
    .react-course-for-illustrator {
        height: 100%;
        width: 100%;
    }
    .react-illustrator-Img {
        padding-bottom: 0;
    }
    .lld-illustor-img {
        width: 100%;
        height: 100%;
    }
}
