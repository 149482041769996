.cpp-learning-outcomes-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #EFFFF3;
    margin: 8vw 0;
}
.cpp-learning-outcomes-content {
    display: flex;
    flex-direction: column;
    width: 45vw;
}
.cpp-learning-outcomes-content > h2 {
    margin-bottom: 2vw;
    font-family: Poppins-Regular;
    font-weight: 600;
    font-size: 3.1vw;
    color: #272834;
    padding-top: 2vw;
}
.cpp-learning-outcomes-points-wrapper {
    display: flex;
    flex-direction: column;
    padding: 0.7vw 0;
    word-wrap: normal;
}
.cpp-learning-outcomes-points {
    display: flex;
    margin-bottom: 1.7vw;
}
.cpp-learning-outcomes-points > img {
    object-fit: contain;
    margin-right: 1.5vw;
    width: 2vw;
}
.cpp-learning-outcomes-points > h3 {
    font-family: "Poppins-Medium";
    font-weight: 500;
    font-size: 1.1vw;
    color: #2f3043;
}
@media (max-width: 600px) {
    .cpp-learning-outcomes-wrapper {
        display: flex;
        flex-direction: column;
        width: 100vw;
        padding: 1vw 5vw;
        margin-bottom: 10vw;
        background: #FFF;
    }
    .cpp-learning-outcomes-content {
        width: 90vw;
    }
    .cpp-learning-outcomes-content > h2 {
        font-size: 5.5vw;
        font-family: Poppins-Semibold;
        margin-bottom: 2vw;
        text-align: center;
    }
    .cpp-learning-outcomes-points {
        align-items: center;
        padding: 1vw 0;
    }
    .cpp-learning-outcomes-points > h3 {
        font-size: 4vw;
        font-family: Poppins-Regular;
    }
    .cpp-learning-outcomes-points > img {
       width: 5vw;
    }
  
}
