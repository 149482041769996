.signup-wrapper {
    display: flex;
}
.company-logo-success-story-wrapper {
    padding-top: 6vw;
    width: 40%;
    min-height: 110vh;
    background: var(--primary-blue-50, #e8edfb);
}
.company-logo-success-story-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.company-logo-success-story-heading {
    color: #000;
    font-family: "Poppins-SemiBold";
    font-size: 1vw;
}
.company-logos-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 0;
    margin: 2vw 4vw 0vw 4vw;
}
.company-logo-img {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 33.333333%;
    margin: 1vw 0vw;
}
.alumni-wrapper {
    border-radius: 20px;
    background: var(--neutral-50, #f7f8f9);
    margin: 2vw 5vw;
    padding: 1vw 1vw;
}
.alumni-desc {
    color: var(--neutral-800, #191d23);
    font-family: "Poppins-Regular";
    font-size: 0.8vw;
}
.alumni-name {
    color: var(--neutral-800, #191d23);
    font-family: "Poppins-SemiBold";
    padding: 0.5vw 0vw 0.3vw 0vw;
    font-size: 0.8vw;
}
.sde-company-logo-wrapper {
    display: flex;
    align-items: center;
    font-size: 0.9vw;
    font-family: "Poppins-Regular";
}
.alumni-logo {
    margin-left: 0.5vw;
}
.signup-form-container {
    width: 60%;
    /* margin-left: 40vw; */
}
.signup-form-wrapper {
    background-color: #fff;
    width: 50%;
    margin: 0 auto;
    padding: 2vw 1vw;
}
.start-free-trial-form-wrapper {
    background-color: #fff;
    width: 32%;
    margin: 0 auto;
    padding-top: 2vw;
}
.sign-up-header {
    font-family: "Poppins-Regular";
    color: #4f516b;
    font-size: 1.9vw;
    text-align: center;
    font-weight: 600;
}

.signup-setup-wrapper > input {
    font-size: 1.4vw;
    color: #393939;
    font-family: "Poppins-Regular";
    border: 1px solid #c4c4c4;
    outline-color: #c7c7d1;
    padding: 0.7vw 0;
    margin-bottom: 4vw;
    padding-left: 1vw;
}

.signup-setup-wrapper label {
    font-family: "Poppins-Regular";
    color: #737171;
}

.signup-setup-wrapper > h2 {
    font-size: 1.9vw;
    text-align: center;
    margin-bottom: 1vw;
    color: #4f516b;
    font-family: "Poppins-SemiBold";
}

.signup-setup-login {
    text-align: center;
    font-family: "Poppins-Regular";
    font-size: 1.2vw;
}

.signup-setup-login > span {
    background: linear-gradient(180deg, #3e8ebc 0%, #444bab 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor: pointer;
    margin-top: 1vw;
    font-family: "Poppins-SemiBold";
    font-size: 1.2vw;
}

.signup-forgot-password-signup-text > span {
    color: #444bab;
    font-family: "Poppins-Regular";
    font-size: 1.3vw;
}

.signup-setup-wrapper,
.signup-forgot-password-wrapper {
    display: flex;
    justify-content: center;
    width: 80%;
    flex-direction: column;
}

.signup-progress-caption {
    color: #4f516b;
    font-family: "Poppins-Regular";
    margin-bottom: 0;
    align-self: baseline;
}

.signup-setup-progress-wrapper {
    display: flex;
    justify-content: space-between;
    height: 8px;
    margin: 1vw 0;
    width: 100%;
}
.otp-sent-heading {
    font-size: 1vw;
    color: #797979;
    font-family: "Poppins-Regular";
}
.signup-setup-progress-wrapper > div {
    background: #c4c4c4;
    border-radius: 4.5px;
    width: 32.5%;
}

.signup-setup-progress-success {
    background: linear-gradient(93.44deg, #444bab -6.8%, #3ca5c2 186.94%) !important;
}

.signup-otp-password-wrapper {
    width: 50%;
    margin: 0 auto;
    padding: 2vw 1vw;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8vw;
    padding-bottom: 15vw;
}
.signup-otp-pwd-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    margin: 0 auto;
}
.signup-progress-caption {
    color: #4f516b;
    font-family: "Poppins-Regular";
    font-size: 1.1vw;
}

.signup-otp-password-timer {
    margin: 0.5vw 0;
}

.signup-otp-password-timer > span {
    color: #444bab;
    font-family: "Poppins-Regular";
    cursor: pointer;
}

.form-details-input-title,
.signup-forgot-password-wrapper > p,
.signup-form-conditional-input-wrapper > p,
.signup-setup-password-wrapper > p {
    color: #737171;
    font-family: "Poppins-Regular";
    margin-bottom: 0;
    margin-bottom: 5px;
    font-size: 16px;
}
.start-free-trial-form-wrapper > input,
.signup-form-wrapper > input,
.signup-forgot-password-wrapper > input,
.signup-form-conditional-input-wrapper > input,
.signup-setup-password-wrapper > input {
    font-size: 16px;
    color: #393939;
    font-family: "Poppins-Regular";
    border: 1px solid #c4c4c4;
    outline-color: #c7c7d1;
    border-radius: 4px;
    padding: 0.7vw 0;
    padding-left: 0.8vw;
    margin-bottom: 1vw;
    width: 100%;
}

.signup-form-profession-dropdown,
.signup-form-experience-dropdown {
    width: 100% !important;
    margin-bottom: 1.5vw;
    outline-color: #c7c7d1;
}

.phone-number-box {
    margin-bottom: 1.5vw;
}

.signup-form-profession-dropdown .MuiSelect-select,
.signup-form-experience-dropdown .MuiSelect-select {
    padding: 1vw 1vw !important;
    margin: 0;
    outline-color: #c7c7d1;
}
.phone-number-box .react-tel-input .form-control {
    width: 100% !important;
    height: 50px;
}
.signup-form-conditional-input-wrapper {
    margin-bottom: 1.5vw;
}

.signup-form-submit-CTA,
.signup-otp-confirm-CTA,
.signup-forgot-password-CTA {
    text-align: center;
    background: linear-gradient(93.53deg, #444bab 0%, #416bb3 62.5%, #3ca5c2 100%);
    color: #fff;
    font-family: "Poppins-Regular";
    font-weight: 600;
    cursor: pointer;
    padding: 0.8vw 0;
    border-radius: 4px;
    margin-top: 2vw;
    font-size: 1.2vw;
}

.signup-otp-confirm-CTA {
    width: 100%;
}
.signup-otp-confirm-btn {
    width: 100%;
    text-align: center;
    background: linear-gradient(93.53deg, #444bab 0%, #416bb3 62.5%, #3ca5c2 100%);
    color: #fff;
    font-family: "Poppins-Regular";
    font-weight: 600;
    cursor: pointer;
    padding: 0.8vw 0;
    border-radius: 4px;
    font-size: 1.2vw;
    border: none;
}
.signup-otp-input-wrapper {
    padding: 0.25vw 0.5vw;
}

.signup-otp-input-wrapper:first {
    margin-left: 0;
}

.signup-otp-input-wrapper > input {
    border-radius: 7px;
    padding: 0.7vw 1vw;
    width: 3vw !important;
    border: 1px solid #c4c4c4;
    outline-color: #e7e7ef;
}

.signup-setup-password-wrapper {
    align-self: flex-start;
    width: 100%;
}

.signup-otp-password-note {
    font-family: "Poppins-Regular";
    font-size: 1vw;
    color: #797979;
    font-style: italic;
    padding-bottom: 1vw;
}

.signup-forgot-password-wrapper > h3 {
    text-align: center;
    color: #4f516b;
    font-family: "Poppins-Regular";
    margin: 3vw 0;
}

.signup-forgot-password-login-CTA {
    text-align: center;
    background: #fff;
    border: 1.5px solid #444bab;
    border-radius: 4px;
    color: #444bab;
    font-family: "Poppins-Regular";
    cursor: pointer;
    padding: 1vw 0;
}

.signup-forgot-password-signup-text {
    color: #000000 !important;
    text-align: center;
    margin: 5vw 0;
}

.login-text {
    cursor: pointer;
}

.error-icon {
    position: absolute;
    left: 26vw;
    color: #d14444;
    visibility: visible;
    margin-top: 1vw;
}

.success-icon {
    position: absolute;
    left: 26vw;
    color: #177e40;
    visibility: visible;
    margin-top: 1vw;
}

.hide-password {
    position: absolute;
    left: 28vw;
    color: #737171;
    visibility: visible;
    margin-top: 1vw;
}

.show-password {
    position: absolute;
    left: 28vw;
    color: #737171;
    visibility: visible;
    margin-top: 1vw;
}
.signup-content-password-wrapper {
    position: relative;
}
.signup-content-password-wrapper p {
    color: #d14444;
    font-family: "Poppins-Regular";
    margin-bottom: 1vw;
    font-size: 1vw;
}
.signup-invalid-otp p {
    color: #d14444;
    font-family: "Poppins-Regular";
    margin-bottom: 1vw;
    font-size: 1vw;
}
.free-trial-form-error p {
    color: #d14444;
    font-family: "Poppins-Regular";
    margin-bottom: 1vw;
    font-size: 1vw;
}
.signup-email-error {
    color: #d14444;
    font-family: "Poppins-Regular";
    margin-bottom: 1vw;
    font-size: 1vw;
}

@media (max-width: 600px) {
    .signup-wrapper {
        background-color: #fff;
        justify-content: center;
    }
    .signup-form-container {
        width: 100%;
        padding: 0vw 5vw;
        margin-left: 0vw;
    }
    .signup-otp-password-wrapper {
        width: 100%;
        margin-left: 0vw;
        padding: 4vw 4vw;
        margin-right: 0vw;
    }

    .signup-form-wrapper {
        width: 100%;
    }
    .sign-up-header {
        font-size: 5vw;
        padding: 5vw 0;
    }
    .signup-form-wrapper {
        border: none;
    }
    .signup-progress-caption {
        font-size: 18px;
    }
    .form-details-input-title {
        font-size: 15px;
        padding-top: 3vw;
    }
    .signup-form-wrapper > input,
    .signup-forgot-password-wrapper > input,
    .signup-form-conditional-input-wrapper > input,
    .signup-setup-password-wrapper > input,
    .login-content-google-CTA {
        padding: 3vw 0.5vw;
        font-size: 3.2vw;
        text-indent: 10px;
    }
    .signup-form-wrapper > input {
        margin-bottom: 12vw;
    }
    .signup-setup-send-otp-CTA {
        padding: 4vw 0.5vw;
        font-size: 3.2vw;
    }
    .login-content-google-CTA > p {
        font-size: 3.2vw;
    }
    .login-content-OR {
        font-size: 15px;
        padding: 3vw 0;
    }
    .signup-setup-login {
        font-size: 15px;
        padding-top: 3vw;
    }
    .signup-setup-login > span,
    .signup-forgot-password-signup-text > span {
        font-size: 15px;
    }
    .otp-sent-heading {
        font-size: 16px;
        margin-top: 5vw;
        margin-bottom: 3vw;
    }
    .signup-otp-password-timer > span {
        font-size: 16px;
        margin-top: 3vw;
    }

    .signup-otp-password-note {
        font-size: 12px;
        margin-bottom: 3vw;
    }
    .signup-setup-password-wrapper > p {
        font-size: 15px;
    }
    .signup-setup-progress-wrapper {
        margin-bottom: 5vw;
    }

    .signup-form-profession-dropdown .MuiSelect-select {
        padding: 3vw 0.5vw;
    }

    .signup-form-conditional-input-wrapper > p {
        font-size: 15px;
        padding-top: 2vw;
    }
    .signup-content-password-wrapper p {
        margin-bottom: 2vw;
        font-size: 13px;
    }
    .signup-invalid-otp p {
        margin-bottom: 2vw;
        font-size: 13px;
        margin-bottom: 1vw;
        font-size: 1vw;
    }
    .free-trial-form-error p {
        margin-bottom: 2vw;
        font-size: 13px;
        margin-bottom: 1vw;
        font-size: 1vw;
    }
    .signup-email-error {
        margin-bottom: 2vw;
        font-size: 13px;
        margin-bottom: 1vw;
        font-size: 1vw;
    }
    .signup-otp-input-wrapper > input {
        width: 10vw !important;
        aspect-ratio: 1/1;
        margin: 0 8px;
    }
    .start-free-trial-form-wrapper {
        width: 100%;
        margin: 6vw 8vw;
    }
    .phone-number-box .react-tel-input .form-control {
        height: 35px;
    }
    .signup-otp-confirm-btn {
        padding: 2vw;
        font-size: 3vw;
    }
}
