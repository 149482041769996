.cpp-pricing-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1vw 0vw;
    margin: 1vw 0vw;
    margin-bottom: 4.5vw;
}
.cpp-pricing-wrapper h2 {
    font-family: Poppins-Medium;
    font-size: 2.5vw;
    text-align: center;
    margin-bottom: 0.5vw;
}
.cpp-pricing-wrapper p {
    font-family: Poppins-Regular;
    font-weight: 400;
    font-size: 1.4vw;
    text-align: center;
    margin-bottom: 1vw;
}
.cpp-pricing-plans-wrapper {
    display: flex;
    margin-top: 3rem;
}
.cpp-pricing-plan-card {
    background: #f7f8f9;
    border: 1.1px solid #d0d5dd;
    border-radius: 1.39vw;
    width: 25vw;
    height: 28vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3vw 1vw;
    margin: 1.5vw 2vw;
}
.cpp-pricing-plan-card-two {
    position: relative;
    top: -3.5vw;
    border: 1.1px solid #d0d5dd;
    border-radius: 1.39vw;
    width: 25vw;
    height: 33vw;
    display: flex;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: 6vw;
    background: linear-gradient(-135deg, #f1511b 6.95vw, #f7f8f9 0);
    margin: 0 3vw;
}
.cpp-recommend-triangle {
    position: absolute;
    top: 12%;
    right: -2%;
    transform: rotate(45deg);
    color: white;
    font-size: 1vw;
    font-weight: 600;
    font-family: "Poppins-SemiBold";
    margin-bottom: -3.47vw;
}
.cpp-initial-price-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}
.cpp-price-saved {
    color: #e48915;
    font-family: "Lato-Regular";
    font-weight: 700;
    font-size: 1.6vw;
    margin-bottom: 1vw;
    margin-left: 1vw;
}
.cpp-plan-duration {
    color: #3940a0;
    font-family: Poppins-Semibold !important;
    font-size: 1.8vw !important;
    margin-bottom: 1vw !important;
}
.cpp-horizontal-line-price {
    width: 45px;
    height: 0px;
    border-radius: 10px;
    border: 1px solid #3940a0;
    background: #3940a0;
    margin-bottom: 2vw;
}
.cpp-plan-price {
    color: #64748b;
    text-decoration: line-through;
    font-family: Poppins-Regular !important;
    font-size: 2vw !important;
}
.cpp-plan-discount-price {
    font-family: Poppins-Semibold !important;
    font-weight: 900 !important;
    font-size: 2.9vw !important;
    color: #3940a0;
    margin-bottom: 1vw;
}
.cpp-plan-desc {
    font-family: Poppins-Medium;
    font-size: 1.4vw;
    text-align: center;
    color: #231f20;
    margin-bottom: 2vw;
}
.cpp-subscribe-now-button {
    background: #3940a0;
    border-radius: 0.4vw;
    width: 20vw;
    height: 4vw;
    font-family: "Poppins-SemiBold";
    font-size: 1.4vw;
    color: #ffffff;
    border: none;
    margin-bottom: 1vw;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;
    padding: 0.5vw 2vw;
}
.cpp-pricing-plan-card-two > .cpp-subscribe-now-button {
    background: linear-gradient(92deg, #d34505 0%, #f1511b 100%);
}
.cpp-emi-text {
    color: #9a4d06;
    font-family: Poppins-Medium;
    margin-bottom: 1vw;
    font-size: 1.5vw;
}
.cpp-gift-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 1.5vw;
}
.cpp-gift-wrapper > img {
    height: 1.6vw;
    width: 1.6vw;
    margin-right: 0.4vw;
    margin-bottom: 1vw;
}
.cpp-gift-wrapper > p {
    font-family: "Poppins-Medium";
    font-weight: 500;
    font-size: 1.2vw;
    color: #4f516b;
}
.cpp-referral-code {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.cpp-referral-code > input {
    width: 23.6vw;
    height: 3.2vw;
    background: #ffffff;
    border: 1px solid #d5dbe1;
    border-radius: 0.35vw;
    padding: 0.7vw;
    font-family: "Poppins-Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 1.1vw;
    color: #616161;
}
.cpp-referral-code > span {
    position: relative;
    bottom: 2.5vw;
    left: 9vw;
    font-family: "Poppins-SemiBold";
    font-weight: 600;
    font-size: 1.1vw;
    color: #444bab;
    cursor: pointer;
}
.cpp-error-coupon-code-wrapper {
    text-align: center;
    font-family: "Gilroy-Medium";
    font-size: 1.2vw;
    color: #ff5050;
    margin-top: 2vw;
    margin-bottom: 2vw;
}
m,
.cpp-free-trial-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.2vw;
    margin-bottom: 4vw;
    margin-top: 5vw;
}
.cpp-free-trial-wrapper > p {
    font-family: "Poppins-SemiBold";
    font-weight: 600;
    font-size: 2.09vw;
    color: #4f516b;
}
.cpp-free-trial-wrapper > span {
    font-family: "Poppins-Medium";
    font-weight: 500;
    font-size: 1.39vw;
    color: #4359af;
    margin-bottom: 1vw;
}
.cpp-free-trial-wrapper > button {
    width: 20.9vw;
    height: 3.9vw;
    background: linear-gradient(91.59deg, #444bab 1.85%, #3ca5c2 99.69%);
    border-radius: 0.4vw;
    border: none;
    font-family: "Poppins-SemiBold";
    font-weight: 600;
    font-size: 1.39vw;
    color: #ffffff;
}

.apply-coupon-code-text {
    padding: 2px 4px;
    border-radius: 4px;
}

@media (max-width: 600px) {
    .cpp-pricing-wrapper {
        margin: 10vw 5vw 4vw 5vw;
    }
    .cpp-pricing-wrapper h2 {
        font-size: 6vw;
        margin-top: 10vw;
    }
    .cpp-pricing-wrapper p {
        font-size: 4.5vw !important;
        margin-bottom: 3vw;
    }
    .cpp-pricing-plans-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .cpp-pricing-plan-card {
        width: 90vw;
        height: 80vw;
        margin: 2vw 2vw;
        padding: 1vw 2vw;
        border-radius: 4.5vw;
    }
    .cpp-pricing-plan-card-two {
        top: 0;
        height: 80vw;
        width: 90vw;
        margin: 5vw 2vw;
        padding: 1vw 2vw;
        background: linear-gradient(-135deg, #f1511b 18vw, #f7f8f9 0);
        border-radius: 4.5vw;
    }
    .cpp-recommend-triangle {
        right: -2%;
        top: 10%;
        font-size: 2.8vw;
    }
    .cpp-plan-duration {
        font-size: 4.4vw;
        margin-bottom: 2vw;
        padding-top: 7vw;
    }
    .cpp-pricing-plan-card-two > .cpp-plan-duration {
        font-size: 4.4vw;
        margin-bottom: 2vw;
    }
    .cpp-horizontal-line-price {
        margin-top: 5px;
    }
    .cpp-plan-price {
        font-size: 4.4vw !important;
        margin: 0;
    }
    .cpp-plan-discount-price {
        font-size: 8vw !important;
        margin-bottom: 5vw 0;
    }
    .cpp-plan-desc {
        font-size: 4vw;
        margin: 5vw 0;
    }
    .cpp-subscribe-now-button {
        font-size: 4vw;
        padding: 2vw 7vw;
        border-radius: 1vw;
        width: 55vw;
        height: auto;
        margin-bottom: 1vw;
    }
    .cpp-emi-text {
        text-align: center;
        padding: 2vw 5vw;
        font-size: 3.1vw;
    }
    .cpp-gift-wrapper img {
        width: 1.4rem;
        height: 1.4rem;
        margin-right: 2vw;
    }
    .cpp-gift-wrapper p {
        font-size: 3vw;
        padding-top: 1vw;
    }
    .cpp-referral-code > input {
        height: 8.5vw;
        font-size: 3vw;
        width: 65vw;
        margin-top: 3vw;
        padding: 3vw 4vw;
    }
    .cpp-referral-code > span {
        font-size: 3.25vw;
        bottom: 7vw;
        left: 25vw;
        margin-top: 0;
    }
    .cpp-free-trial-wrapper > p {
        font-size: 22px;
        margin-bottom: 15px;
    }
    .cpp-free-trial-wrapper > span {
        font-size: 18px;
        margin-bottom: 12px;
    }
}
