.into-video-wrapper {
    margin: 15vw 12vw;
}
.lld-intro-video {
    height: 37vw;
    width: 72vw;
}
.play-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 13vw;
    margin-bottom: 12vw;
}
.play-button {
    background-image: url(../../../img/play-button.png);
    background-position: center 17px;
    background-repeat: no-repeat;
    background-size: 2.2vw 2.1vw;
    height: 4vw;
    width: 4vw;
    background-color: white;
    border-radius: 5vw;
}
.into-video-wrapper > h1 {
    font-family: "Poppins-Regular";
    color: #ffffff;
    font-size: 2vw;
    margin-left: 1vw;
}

.into-video-wrapper > p {
    font-family: "Poppins-Regular";
    color: #ffffff;
    font-size: 0.8vw;
    margin-left: 1vw;
}
@media (max-width: 600px) {
    .into-video-wrapper {
        margin: 0 5vw;
    }
    .lld-intro-video {
        width: 100%;
        height: 60vw;
    }
}
