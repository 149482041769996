.python-faq-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 3vw 6vw;
    margin-bottom: 2vw;
}

.python-faq-wrapper h2 {
    font-family: Poppins-Regular;
    font-weight: 500;
    font-size: 2.75vw;
    text-align: center;
    padding: 2vw;
}

.python-accordion-wrapper {
    cursor: pointer;
}

.python-accordion-background {
    background-color: #f3f4ff;
}

.python-accordion-item {
    background-color: #fff;
    padding: 1vw;
    border-bottom: 1px solid #a3a3a3;
}

.python-accordion-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.python-accordion-question h3 {
    color: #000;
    font-family: Poppins-SemiBold;
    font-size: 1.1vw;
}

.python-accordion-question img {
    width: 1rem;
    height: 1rem;
    cursor: pointer;
}

.python-accodrion-content {
    color: #272834;
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0, 1, 0, 1);
    font-family: Poppins-Regular;
    font-size: 1.1vw;
}

.python-accodrion-content.python-accodrion-show {
    padding-top: 0.5vw;
    height: auto;
    max-height: 9999px;
    transition: all 1.2s cubic-bezier(0, 1, 0, 1);
}

.renaissance-link {
    color: #444bab;
    font-family: Poppins-Regular;
    font-weight: 600;
}

@media (max-width: 600px) {
    .python-faq-wrapper {
        padding: 3vw 5vw;
        margin-bottom: 10vw;
    }

    .python-faq-wrapper h2 {
        font-size: 6.2vw;
        font-weight: 600;
        font-family: Poppins-Medium;
        padding: 0;
    }

    .python-accordion-question {
        align-items: center;
    }

    .python-accordion-question h3 {
        font-size: 3.73vw;
        line-height: 6.4vw;
        font-weight: 500;
    }

    .python-accodrion-content {
        font-size: 3.73vw;
    }

    .python-accordion-item {
        padding: 2.5vw 0;
    }

    .python-accordion-question img {
        width: 5.3vw;
        height: 5.3vw;
        margin-left: 1vw;
    }
}