.login-wrapper {
    align-items: center;
    background: #fafafb;
    display: flex;
    height: 100%;
    justify-content: center;
    margin: 0 auto;
    padding: 3% 3% 5%;
    width: 100%;
}

.login-page-wrapper {
    background: #fafafb;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-content-wrapper {
    background-color: #fff;
    align-items: center;
    padding: 2vw 1vw;
    width: 35%;
    border: 1px solid rgba(68, 75, 171, 0.1);
}
.login-content-wrapper > h2 {
    font-family: "Poppins-Regular";
    color: #4f516b;
    font-size: 1.9vw;
    text-align: center;
    font-weight: 600;
    margin-bottom: 1vw;
}

/* input[type="text"],
textarea {
    background-color: #ffffff;
    border: 1px solid #c4c4c4;
} */

.login-content-form > label {
    font-family: "Poppins-Regular";
    color: #737171;
}

.login-content-login-CTA {
    background: linear-gradient(93.44deg, #444bab -6.8%, #3ca5c2 186.94%);
    border-radius: 6px;
    color: #fff;
    padding: 1vw 0;
    font-family: "Poppins-Regular";
    text-align: center;
    cursor: pointer;
    font-size: 1.2vw;
    font-weight: 600;
}

.login-content-google-CTA {
    display: flex;
    justify-content: center;
    border-radius: 5px;
    border: 1px solid rgba(68, 75, 171, 0.8);
    cursor: pointer;
    margin-bottom: 2vw;
}

.login-content-google-CTA > p {
    margin: 0 1vw;
    color: #444bab;
    /* background-color: linear-gradient(180deg, #3e8ebc 0%, #444bab 100%); */

    font-family: "Poppins-Regular";
    font-size: 1.2vw;
    padding: 1vw 0;
    font-weight: 600;
}

.login-content-form > p {
    font-family: "Poppins-Regular";
    color: #444bab;
    text-align: end;
    margin-bottom: 2vw;
    font-size: 1vw;
    font-size: 12px;
}

.login-content-OR {
    text-align: center;
    font-size: 1.2vw;
    font-weight: 400;
    color: #000000;
    font-family: "Poppins-Regular";
    padding: 1vw 0;
    margin: 0;
}

.login-content-wrapper > p {
    color: #000;
    font-family: "Poppins-Regular";
    margin-top: 2vw;
    text-align: center;
    font-size: 1.2vw;
}

.login-content-wrapper span {
    background: linear-gradient(180deg, #3e8ebc 0%, #444bab 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor: pointer;
    margin-top: 1vw;
    font-family: "Poppins-SemiBold";
    font-size: 1.2vw;
}

.login-content-password-wrapper {
    position: relative;
}

.login-content-password-wrapper > img {
    position: absolute;
    top: 0.75vw;
    width: 2vw;
    right: 1vw;
    cursor: pointer;
}

.forgot-password-text {
    cursor: pointer;
}

@media (max-width: 600px) {
    .login-wrapper {
        padding: 0% 0%;
    }
    .login-content-wrapper {
        padding: 1% 5%;
        width: 100%;
        border: none;
    }
    .login-content-wrapper > h2 {
        font-size: 6vw;
        padding: 5vw 0;
    }
    .common-input-styles {
        padding: 3vw 0.5vw;
        font-size: 3.2vw;
        text-indent: 10px;
    }
    .signup-setup-login {
        margin-top: 12vw;
    }
    .common-blue-gradient-CTA {
        padding: 3vw 0.5vw;
        font-size: 3.2vw;
    }
    .login-content-password-wrapper > img {
        width: 7vw;
        right: 2vw;
        top: 2vw;
    }
    .login-content-wrapper > p,
    .login-content-wrapper span {
        font-size: 14px;
    }
}
