@font-face {
    font-family: "Gilroy-Bold";
    src: url("./fonts/Gilroy-Bold.ttf");
}

@font-face {
    font-family: "Gilroy-SemiBold";
    src: url("./fonts/Gilroy-SemiBold.ttf");
}

@font-face {
    font-family: "Gilroy-Medium";
    src: url("./fonts/Gilroy-Medium.ttf");
}

@font-face {
    font-family: "Gilroy-Regular";
    src: url("./fonts/Gilroy-Regular.ttf");
}


@font-face {
    font-family: "Poppins-Regular";
    src: url("./fonts/Poppins-Regular.ttf");
}

@font-face {
    font-family: "Poppins-SemiBold";
    src: url("./fonts/Poppins-SemiBold.ttf");
}

@font-face {
    font-family: "Poppins-Medium";
    src: url("./fonts/poppins.medium.ttf");
}

@font-face {
    font-family: "Poppins-Normal";
    src: url("./fonts/poppins.semibold.ttf");
}

@font-face {
    font-family: "Poppins-Medium_Italic";
    src: url("./fonts/poppins.medium-italic.ttf");
}

@font-face {
    font-family: "Poppins-Light";
    src: url("./fonts/Poppins-Light.ttf");
}

@font-face {
    font-family: "Poppins-Bold";
    src: url("./fonts/Poppins-Bold.ttf");
}
@font-face {
    font-family: "Lato-Regular";
    src: url("./fonts/Lato-Regular.ttf");
}
@font-face {
    font-family: "Lato-Black";
    src: url("./fonts/Lato-Black.ttf");
}
@font-face {
    font-family: "Lato-Bold";
    src: url("./fonts/Lato-Bold.ttf");
}
@font-face {
    font-family: "Lato-Italic";
    src: url("./fonts/Lato-Italic.ttf");
}

@font-face {
    font-family: "Lato-Medium-Italic";
    src: url("./fonts/Lato-MediumItalic.ttf");
}


