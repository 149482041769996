.bannerContainer {
    background-color: #F1F5F9;
    padding: 40px 20px;
  }
  
  .bannerWrapper {
    background-color: #181B43;
    padding: 20px 12px;
    border-radius: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 12px;
    max-width: 335px;
    margin: 0 auto;
  }
  
  .imageWrapper img {
    width: 266px;
  }
  
  .textWrapper {
    width: 300px;
    /* text-align: center; */
  }
  
  .community-heading {
    font-size: 22px;
    line-height: 32px;
    color: #ffffff;
    margin: 0;
  }
  
  .noWrap {
    white-space: nowrap;
  }
  
  .tagsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin-top: 12px;
  }
  
  .tag {
    background-color: #f8fafc;
    color: #020617;
    font-size: 12px;
    line-height: 16px;
    padding: 8px 12px;
    border-radius: 32px;
    font-weight: 500;
  }
  
  .order1 { order: 1; }
  .order2 { order: 2; }
  .order3 { order: 3; }
  
  .joinButton {
    margin-top: 40px;
    width: 300px;
    background-color: #3940a0;
    padding: 12px;
    color: #ffffff;
    border: none;
    border-radius: 12px;
    cursor: pointer;
  }
  
  .desktopOnly {
    display: none;
  }
  
  @media (min-width: 768px) {
    .bannerContainer {
      padding: 100px 20px;
    }
  
    .bannerWrapper {
      padding: 40px 12px;
      border-radius: 40px;
      max-width: 95%;
    }
  
    .imageWrapper img {
      width: 308px;
    }
  
    .textWrapper {
      width: 475px;
      text-align: left;
    }
  
    .community-heading {
      font-size: 38px;
      line-height: 56px;
    }
  
    .tag {
      font-size: 16px;
      line-height: 24px;
      padding: 12px 24px;
    }
  
    .joinButton {
      width: 160px;
    }
  
    .desktopOnly {
      display: inline-block;
    }
  
    .mobileOnly {
      display: none;
    }
  }
  
  @media (min-width: 1024px) {
    .bannerWrapper {
      max-width: 1312px;
      flex-wrap: nowrap;
      gap: 60px;
    }
  
    .textWrapper {
      width: 690px;
    }
  
    .community-heading {
      font-size: 48px;
      line-height: 64px;
    }
  }
  