.springboot-whychoose {
    padding: 60px;
}

.springboot-whychoose .springboot-whychoose-main-heading {
    font-family: Gilroy-Bold;
    font-size: 48px;
    font-weight: 400;
    line-height: 64px;
    letter-spacing: 0.04em;
    text-align: center;
    width: 100%;
}

.springboot-whychoose .springboot-whychoose-main-paragraph {
    font-family: Gilroy-Regular;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.02em;
    text-align: center;
}

.springboot-whychoose .springboot-whychoose-card-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px 20px;
    padding: 0px 64px;
    margin-top: 40px;
}

.springboot-whychoose .springboot-whychoose-card {
    background: #f7f8f9;
    padding: 24px;
    border-radius: 10px;
}

.springboot-whychoose .springboot-whychoose-card .springboot-whychoose-heading {
    font-family: Gilroy-Bold;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.02em;
    text-align: left;
    margin-top: 20px;
}

.springboot-whychoose .springboot-whychoose-card .springboot-whychoose-paragraph {
    font-family: Gilroy-Regular;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: left;
    margin-top: 8px;
}

@media (max-width: 600px) {
    .springboot-whychoose .springboot-whychoose-card-container {
        grid-template-columns: repeat(1, 1fr);
        padding: 0px 0px;
        gap: 12px 0px;
    }

    .springboot-whychoose {
        padding: 20px;
    }

    .springboot-whychoose .springboot-whychoose-card {
        padding: 16px;
    }

    .springboot-whychoose .springboot-whychoose-main-heading {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.02em;
    }

    .springboot-whychoose .springboot-whychoose-main-paragraph {
        font-size: 16px;
        line-height: 24px;
    }

    .springboot-whychoose .springboot-whychoose-card .springboot-whychoose-heading {
        font-size: 20px;
        line-height: 28px;
        margin-top: 12px;
    }

    .springboot-whychoose .springboot-whychoose-card .springboot-whychoose-paragraph {
        margin-top: 4px;
    }

    .springboot-whychoose .springboot-whychoose-card .springboot-whychoose-card-icon {
        width: 32px;
    }
}
