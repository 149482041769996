.curriculum-wrapper {
    display: flex;
    flex-direction: column;
    margin: 0vw 5vw;
    margin-top: 6vw;
    margin-bottom: 8vw;
}
.curriculum-wrapper > h1 {
    font-family: "Poppins-SemiBold";
    font-weight: 600;
    font-size: 2vw;
    color: #272834;
    margin-bottom: 3vw;
}
.topics-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.topic-card {
    width: 65vw;
    display: flex;
    flex-direction: column;
    border: 1px solid #c0c0c0;
    border-radius: 0.7vw;
    padding-bottom: 1.4vw;
    margin-bottom: 2.5vw;
}
.topic-card > span {
    color: #444bab;
    padding: 1.4vw 1.4vw;
    font-family: "Poppins-Medium";
    font-weight: 500;
    font-size: 1.1vw;
    margin-bottom: 0.7vw;
}
.topic-card-heading {
    padding: 1.2vw;
    background: #f8f8f8;
    border-radius: 0.7vw 0.7vw 0vw 0vw;
    font-family: "Poppins-SemiBold";
    font-weight: 600;
    font-size: 1.2vw;
    color: #272834;
    margin-bottom: 0.7vw;
}
.subtopics-wrapper {
    display: flex;
    padding-left: 2vw;
}
.subtopics-wrapper > img {
    height: 2vw;
    width: 2vw;
    margin-right: 1.5vw;
}
.subtopics-wrapper > p {
    font-family: "Poppins-Regular";
    font-weight: 400;
    font-size: 1.1vw;
    color: #2f3043;
    margin-bottom: 0.7vw;
}
.see-more {
    font-family: "Poppins-SemiBold";
    font-size: 1.1vw;
    color: #444bab;
    margin-bottom: 1vw;
    cursor: pointer;
}
.arrow-down {
    position: relative;
    top: 0.6vw;
    cursor: pointer;
    border-left: 0.4vw solid transparent;
    border-right: 0.4vw solid transparent;
    border-top: 0.6vw solid #444bab;
    margin-left: 1vw;
}
.arrow-up {
    position: relative;
    bottom: 1.5vw;
    cursor: pointer;
    border-left: 0.4vw solid transparent;
    border-right: 0.4vw solid transparent;
    border-bottom: 0.6vw solid #444bab;
    margin-left: 1vw;
}
.see-more-wrapper {
    display: flex;
}

@media (max-width: 600px) {
    .curriculum-wrapper > h1 {
        font-size: 20px;
    }
    .topic-card {
        margin-bottom: 20px;
        width: 100%;
    }
    .topic-card-heading {
        font-size: 16px;
        padding: 2vw;
        margin-bottom: 3vw;
    }
    .topic-card > span {
        font-size: 14px;
        margin-bottom: 3vw;
    }
    .subtopics-wrapper {
        margin-bottom: 15px;
        padding: 0px 10px;
    }
    .subtopics-wrapper > img {
        height: 20px;
        width: 20px;
        margin-right: 15px;
    }
    .subtopics-wrapper > p {
        font-size: 14px;
    }
    .see-more-wrapper {
        margin-bottom: 14px;
    }
    .see-more {
        font-size: 14px;
    }
    .arrow-down {
        top: 8px;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 8px solid #444bab;
    }
    .arrow-up {
        top: 8px;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 8px solid #444bab;
        margin-bottom: 28px;
    }
    .curriculum-wrapper {
        margin-top: 10vw;
    }
}
