.about-wrapper {
    display: flex;
    flex-direction: column;
    padding: 3vw 6vw;
    margin-bottom: 5vw;
}
.about-wrapper h2 {
    text-align: center;
    font-family: Poppins-SemiBold;
    font-size: 2.5vw;
    color: #000000;
    padding: 2vw;
}
.about-contents-wrapper {
    background: #f5f5f5;
    display: flex;
    justify-content: center;
    padding: 2vw 1vw;
    border-radius: 25px;
}
.video-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    cursor: pointer;
}
.lld-video-player {
    width: 100%;
    height: 100%;
    margin-bottom: 1vw;
}
.video-wrapper img {
    width: 100%;
}

.poster {
    position: relative;
    width: 50%;
    display: inline-flex;
}
.poster iframe {
    width: 100%;
}
.poster:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: url(../../../img/video-banner-lld-concurrency.png);
    background-size: contain;
    background-repeat: no-repeat;
    transition: 1s ease;
    cursor: pointer;
}
.poster-active:after {
    opacity: 0;
    pointer-events: none;
}

.contents-wrapper {
    display: flex;
    flex-direction: column;
    width: 40%;
    margin-left: 4vw;
}
.about-points-wrapper {
    display: flex;
    align-items: center;
    background: #ffffff;
    border-radius: 8px;
    width: 100%;
    padding: 1vw 2vw;
    padding-right: 6vw;
    margin-bottom: 1vw;
}
.about-points-wrapper img {
    width: 2rem;
    height: 2rem;
}
.about-points-wrapper p {
    padding-left: 1vw;
    font-family: Poppins-Regular;
    font-size: 1.1vw;
}
.try-free-trial {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    background-color: #ffffff;
    border: 3px solid rgba(68, 75, 171, 0.7);
    cursor: pointer;
    padding: 0.2vw;
}
.try-free-trial p {
    font-size: 1vw;
    font-family: Poppins-Regular;
    padding: 0.5vw;
}
.try-free-trial img {
    width: 1.7rem;
    height: 1.7rem;
}

@media (max-width: 600px) {
    .about-wrapper {
        padding: 4vw;
        margin-top: 10vw;
        margin-bottom: 0vw;
    }
    .about-wrapper h2 {
        font-size: 6.4vw;
        padding-bottom: 5vw;
    }
    .about-contents-wrapper {
        flex-direction: column;
        background-color: #ffffff;
    }
    .video-wrapper {
        width: 100%;
        height: 60vw;
    }
    .try-free-trial {
        width: 50%;
        margin-top: 4vw;
        padding: 1vw;
    }

    .key-highlights {
        width: 70%;
        margin-top: 4vw;
        padding: 1vw;
        position: relative;
        left: 2vw;
    }

    .key-highlights p {
        font-size: 6.4vw;
    }

    .try-free-trial p {
        font-size: 2.5vw;
    }
    .contents-wrapper {
        width: 100%;
        margin: 6vw 0vw;
        margin-top: 15vw;
    }
    .about-points-wrapper {
        border-radius: 10px;
        background: #fff;
    }
    .about-points-wrapper img {
        width: 6.4vw;
        height: 6.4vw;
        margin-right: 2vw;
    }
    .about-points-wrapper p {
        font-size: 3.5vw;
    }

    .first-point {
        font-weight: 700;
    }
}
