.common-faq-accordion-item {
    background-color: #fff;
    padding: 2vw 2vw;
    border: 1px solid #d9d9d9;
    position: relative;
}
.circular-dot {
    content: "";
    height: 0.5vw;
    width: 0.5vw;
    border-radius: 0.5vw;
    background: #4f516b;
    margin-right: 4%;
}
.common-faq-accodrion-title {
    color: #4f516b;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.common-faq-accordion-background {
    background-color: #f3f4ff;
}

.common-faq-accodrion-title h2 {
    font-family: "Poppins-Regular";
    font-size: 1.1vw;
    display: flex;
}
.common-faq-accordion-answer {
    font-size: 1.1vw;
    margin-left: 5%;
}
.circular-dot {
    margin-right: 4%;
    font-size: 1vw;
}
.open-or-close-icon {
    position: absolute;
    right: 4%;
}
.common-faq-accodrion-content {
    color: #393939;
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0, 1, 0, 1);
    font-family: "Poppins-Regular";
    font-size: 1.1vw;
}

.common-faq-accodrion-content.common-faq-accodrion-show {
    padding-top: 1.5vw;
    height: auto;
    max-height: 9999px;
    transition: all 1.2s cubic-bezier(0, 1, 0, 1);
}

@media (max-width: 600px) {
    .common-faq-accodrion-title h2,
    .common-faq-accodrion-content {
        font-size: 15px;
        padding: 0 0.5vw;
    }

    .common-faq-accordion-item {
        background-color: #fff;
    }
    .common-faq-accordion-background {
        background-color: #f3f4ff;
    }
    .common-faq-accordion-img-wrapper {
        width: 100%;
    }
    .common-faq-accordion-answer {
        font-size: 15px;
    }
    .circular-dot {
        content: "";
        height: 10px;
        width: 10px;
        border-radius: 10px;
        background: #4f516b;
        margin: 0;
    }
    .common-faq-accodrion-title h2 {
        padding: 2vw;
    }
}
