.blog-card {
    background: #ffffff;
    position: relative;
    max-width: 422px;
    padding: 12px;
    border: 1px solid #e2e8f0;
    border-radius: 20px;
  }
  
  .blog-image img {
    max-width: 100%;
    height: auto;
  }
  
  .blog-title {
    margin-top: 12px;
    font-size: 24px;
    color: #000;
  }
  
  .blog-description {
    font-size: 15px;
    margin-top: 8px;
    color: #4b5768;
  }
  
  .blog-author {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-top: 20px;
  }
  
  .author-image img {
    width: 40px;
    height: auto;
  }
  
  .author-details .author-blog {
    font-size: 14px;
    color: #000;
  }
  
  .author-meta {
    display: flex;
    gap: 24px;
    align-items: center;
  }
  
  .author-meta p,
  .author-meta ul li {
    color: #4b5768;
    font-size: 14px;
  }
  