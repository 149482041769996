.faq-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 3vw 6vw;
    margin-bottom: 2vw;
}
.faq-wrapper h2 {
    font-family: Poppins-SemiBold;
    font-size: 2.5vw;
    text-align: center;
    padding: 2vw;
}
.accordion-wrapper {
    cursor: pointer;
}
.accordion-background {
    background-color: #f3f4ff;
}
.accordion-item {
    background-color: #fff;
    padding: 1vw;
    border-bottom: 1px solid #a3a3a3;
}
.accordion-question {
    display: flex;
    justify-content: space-between;
}
.accordion-question h3 {
    color: #000;
    font-family: Poppins-SemiBold;
    font-size: 1.1vw;
}
.accordion-question img {
    width: 1rem;
    height: 1rem;
    cursor: pointer;
}

.accodrion-content {
    color: #272834;
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0, 1, 0, 1);
    font-family: Poppins-Regular;
    font-size: 1.1vw;
}

.accodrion-content.accodrion-show {
    padding-top: 0.5vw;
    height: auto;
    max-height: 9999px;
    transition: all 1.2s cubic-bezier(0, 1, 0, 1);
}
.renaissance-link {
    color: #444bab;
    font-family: Poppins-Regular;
    font-weight: 600;
}
@media (max-width: 600px) {
    .faq-wrapper {
        padding: 3vw 5vw;
        margin-bottom: 10vw;
    }
    .faq-wrapper h2 {
        font-size: 6vw;
    }
    .accordion-question h3 {
        font-size: 3.5vw;
    }
    .accodrion-content {
        font-size: 3vw;
    }
    .accordion-item {
        padding: 2vw;
    }
}
