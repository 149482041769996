.python-curriculum-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.5vw 6vw;
    margin-bottom: 5vw;
}

.python-curriculum-container h2 {
    text-align: center;
    font-size: 3.5vw;
    font-family: Poppins-Regular;
    font-weight: 500;
    margin-bottom: 2vw;
}

.python-curriculum-card-wrapper {
    display: flex;
    flex-direction: column;
}

.python-curriculum-card {
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    border-left: 0.47vw solid #387EB8;
    background: #ffffff;
    padding: 0.5vw 1vw;
    margin: 1vw 2vw;
    overflow-y: scroll;
    height: 10vw;
}

.python-curriculum-card h3 {
    color: #387EB8;
    font-size: 1.3vw;
    font-family: Poppins-Semibold;
    font-weight: 600;
}

.python-curriculum-card h4 {
    color: #191d23;
    font-size: 1.3vw;
    font-family: Poppins-Regular;
    font-weight: 400;
}

.python-curriculum-card::-webkit-scrollbar {
    width: 0.5vw;
}

.python-curriculum-card::-webkit-scrollbar-track {
    background: #ffff;
    margin-top: 10px;
    margin-bottom: 10px;
}

.python-curriculum-card::-webkit-scrollbar-thumb {
    background: #C3C8FF;
}

.python-curriculum-card::-webkit-scrollbar-thumb:hover {
    background: #C3C8FF;
}

.python-show-more-wrapper {
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.python-show-more-text {
    color: #444bab;
    font-family: Poppins-SemiBold;
    font-size: 1.4vw;
    padding: 2vw;
    display: flex;
    justify-content: center;
}

.python-show-more-text img {
    width: 2.1vw;
    height: 2.2vw;
    padding: 0.4vw 0.5vw;
    margin-top: -0.1vw;
}

.python-download-curriculum {
    display: flex;
    align-items: center;
    background-color: #3940A0;
    border-radius: 8px;
    padding: 0.8vw 1.8vw;
    margin-top: 3vw;
    cursor: pointer;
}

.python-download-curriculum img {
    width: 2.3vw;
    height: 2.3vw;
}

.python-download-curriculum p {
    font-family: Poppins-Medium;
    color: #ffffff;
    font-size: 1.7vw;
    padding: 0vw 1vw;
}

@media (max-width: 600px) {
    .python-curriculum-container {
        margin-top: 0;
        padding: 5vw;
    }

    .python-curriculum-container h2 {
        font-size: 6.4vw;
        font-family: Poppins-Medium;
        font-weight: 600;
        margin: 2vw 0;
    }

    .python-curriculum-card {
        height: auto;
        padding-left: 3vw;
        border-left: 2.4vw solid #387EB8;
        border-radius: 3.2vw;
        margin: 2.5vw 0;
    }

    .python-curriculum-card h3 {
        font-family: Poppins-Medium;
        font-size: 4.27vw;
    }

    .python-curriculum-card h4 {
        font-size: 3.73vw;
        font-weight: 400;
    }

    .python-curriculum-card::-webkit-scrollbar {
        width: 1vw;
        height: 2vh;
    }

    .python-show-more-text {
        font-size: 4.27vw;
        padding: 2vw;
    }

    .python-show-more-text img {
        width: 3.73vw;
        height: 3.47vw;
        padding: 0.1vw;
        margin-top: 1.5vw;
        margin-left: 0.75vw;
    }

    .python-download-curriculum {
        padding: 4.2vw 5.3vw;
        border-radius: 2.67vw;
    }

    .python-download-curriculum img {
        width: 6.4vw;
        height: 6.4vw;
    }

    .python-download-curriculum p {
        font-size: 4.27vw;
        padding: 0vw 2.75vw;
    }

}