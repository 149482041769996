/* YoutubeSection.css */
.youtube-section {
    background-color: #f1f5f9;
    padding: 40px 20px;
  }
  
  .youtube-section-container {
    max-width: 335px;
    margin: 0 auto;
  }
  
  .youtube-header {
    display: flex;
    justify-content: space-between;
  }
  
  .youtube-header h2 {
    color: #000000;
    font-size: 24px;
    line-height: 32px;
    max-width: 239px;
  }
  .youtube-img {
    width: 90%;
    height: 200px;
  }
  
  .youtube-btn {
    display: none;
    width: 270px;
    height: 48px;
    color: #ffffff;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    background-color: #dc2626;
    border-radius: 10px;
  }
  
  .youtube-slider-container {
    margin-top: 40px;
    position: relative;
  }
  
  .youtube-slider-item {
    border: none;
    width: 100%;
    padding-right: 10px;
  }
  
  .youtube-subscribe-btn {
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 335px;
    height: 48px;
    color: #ffffff;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    background-color: #dc2626;
    border-radius: 10px;
  }

  
  @media (min-width: 640px) {
    .youtube-section-container {
      max-width: 1312px;
    }
  
    .youtube-header h2 {
      font-size: 32px;
      line-height: 48px;
      max-width: 536px;
    }
  
    .youtube-btn {
      display: block;
    }
  
    .youtube-subscribe-btn {
      display: none;
    }
  }
  
  @media (min-width: 1024px) {
    .youtube-header h2 {
      font-size: 48px;
      line-height: 64px;
      max-width: 636px;
    }
  
    .youtube-btn {
      width: 348px;
    }
  }
  