.common-instructors-wrapper {
    display: flex;
    flex-direction: column;
    background: #f8f8f8;
    padding: 4vw;
    align-items: center;
    justify-content: space-evenly;
}
.common-instructors-wrapper > h1 {
    position: relative;
    left: -24.3vw;
    font-family: "Poppins-SemiBold";
    font-weight: 600;
    font-size: 2vw;
    color: #272834;
    margin-bottom: 3vw;
}
.common-instructors-info {
    display: flex;
    margin-bottom: 4vw;
}
.common-instructor-image {
    width: 10vw;
    height: 10vw;
}
.common-instructor-desc {
    font-family: "Poppins-Regular";
    font-weight: 400;
    font-size: 1.1vw;
    color: #272834;
    margin-top: 0.5vw;
    width: 50%;
}
.common-instructor-image-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;
}
.common-instructor-image-wrapper > img {
    margin-bottom: 1.5vw;
}
.common-instructor-image-wrapper > h2 {
    text-align: center;
    margin-bottom: 0.7vw;
    font-family: "Poppins-SemiBold";
    font-weight: 600;
    font-size: 1.1vw;
    color: #272834;
}
.common-instructor-image-wrapper > p {
    text-align: center;
    font-family: "Poppins-Regular";
    font-weight: 400;
    font-size: 0.9vw;
    text-align: center;
    color: #67686b;
    margin-bottom: 0.2vw;
}

@media (max-width: 600px) {
    .common-instructors-wrapper {
        padding-top: 10vw;
        margin-top: 15vw;
    }
    .common-instructors-wrapper > h1 {
        font-size: 20px;
        margin-bottom: 30px;
    }
    .common-instructors-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 10vw;
    }
    .common-instructor-image-wrapper {
        width: 100%;
    }
    .common-instructor-image {
        width: 35%;
        height: auto;
    }
    .common-instructor-desc {
        font-size: 14px;
        margin-top: 30px;
        width: 100%;
        text-align: justify;
    }
    .common-instructor-image-wrapper > h2 {
        font-size: 14px;
    }
    .common-instructor-image-wrapper > p {
        font-size: 14px;
    }
}
