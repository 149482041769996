.python-free-trial-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    margin: 4vw 0 7vw 0;
}
.python-free-trial-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1;
}
.python-free-trial-wrapper p {
    font-family: Poppins-Regular;
    font-weight: 500;
    font-size: 3vw;
    color: #000;
    text-align: center;
}
.python-buttons-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2vw 2vw 0 2vw;
}
.python-free-trial-btn button {
    background: linear-gradient(91.59deg, #444bab 1.85%, #3ca5c2 99.69%);
    border: none;
    border-radius: 0.4vw;
    color: #ffffff;
    cursor: pointer;
    font-family: Poppins-SemiBold;
    font-size: 2vw;
    padding: 1vw 2vw;
    margin-right: 2vw;
}
.python-talk-to-us-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background-color: #ffffff;
    border: 2px solid rgba(68, 75, 171, 0.7);
    cursor: pointer;
    padding: 0.8vw 1vw;
    gap: 0.6vw;
}

.python-talk-to-us-btn p {
    color: #343a40;
    font-family: Poppins-SemiBold;
    font-size: 2vw;
    margin: 0; 
    line-height: 1; 
}

.python-talk-to-us-btn img {
    width: 1.7vw; 
    height: 1.7vw;
    display: block; 
}
.python-free-trial-img {
    position: absolute;
    top: 5vw;
    line-height: 0%;
}
.python-free-trial-img img {
    width: 100vw;
}
@media (max-width: 600px) {
    .python-free-trial-wrapper {
        padding: 3vw 3vw 0 3vw;
        margin: 0 0vw;
    }
    .python-free-trial-wrapper p {
        font-size: 6.4vw;
        margin-bottom: 5vw;
        line-height: 9.8vw;
        font-weight: 600;
        font-family: Poppins-Medium;
    }
    .python-buttons-wrapper {
        flex-direction: column-reverse;
    }
    .python-free-trial-btn button {
        padding: 4.7vw 4.1vw;
        font-size: 4.27vw;
        border-radius: 10px;
        width: 90vw;
        margin-right: 0;
    }
    .python-talk-to-us-btn {
        padding: 4.2vw 2.3vw;
        justify-content: center;
        margin-bottom: 3vw;
        width: 100%;
        border-radius: 2.67vw;
        border: none;
        background-color: #E7EAEE;
        gap: 2vw; 
    }

    .python-talk-to-us-btn p {
        font-size: 4.27vw;
        margin: 0;
    }

    .python-talk-to-us-btn img {
        width: 4vw;
        height: 4vw;
    }
    .python-free-trial-img {
        position: relative;
        top: 4vw;
        width: 100vw;
    }
    .python-free-trial-img img {
        width: 100vw;
    }
    br {
        content: none;
    }
}
