.success-wrapper {
    padding: 0 8vw;
    background-color: #F7F8F9;
}

.success-wrapper .slick-prev {
    z-index: 1;
    left: -31px;
}
.success-wrapper .slick-next {
    right: -20px;
}
.slick-list {
    margin: 0 -10px;
}
.slick-slide>div {
    padding: 0 10px;
}

.success-header-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2vw 0;
}
.stories-blog-linkedin-image-wrapper,
.stories-blog-person-image-wrapper,
.success-box-wrapper,
.success-box-linkedin-wrapper,
.success-slider-linkedin-wrapper,
.success-slider-person-wrapper {
    width: 100%;
    object-fit: cover;
}

.success-slider-statement-quotation-wrapper {
    display: flex !important;
    padding: 5px;
    padding: 2vw 3vw;
    border-radius: 15px;
    justify-content: center;
    align-items: center;
}

.success-header-wrapper > h1 {
    font-family: "Poppins-SemiBold";
    color: #231f20;
    font-size: 2.5vw;
    margin-top: 1vw;
}

.success-header-wrapper > p {
    color: rgba(39, 40, 52, 0.91);
    font-family: "Poppins-Regular";
    font-size: 1.1vw;
}

.success-header-wrapper > img {
    cursor: pointer;
    position: absolute;
    right: 5vw;
    bottom: 3vw;
    z-index: 2;
}

.success-slider-slick-wrapper .slick-slider {
    margin-bottom: 4vw;
}

.success-slider-details-wrapper > img {
    width: fit-content;
    height: fit-content;
}

.success-slider-dots {
    position: absolute;
    width: 100%;
    margin-top: 2vw;
    padding: 0;
    text-align: center;
}

.success-box-description-wrapper > p {
    color: #393939;
    font-family: "Poppins-Regular";
    font-size: 1.1vw;
}

.success-box-description-wrapper span,
.success-slider-statement-quotation-wrapper span {
    color: #3a3f8e;
    cursor: pointer;
    font-size: 1.1vw;
}

.success-slider-dots li {
    list-style: none;
    cursor: pointer;
    display: inline-block;
    margin: 0 3px;
    padding: 0;
}

.success-slider-dots li button {
    border: none;
    background: #c4c4c4;
    color: transparent;
    cursor: pointer;
    display: block;
    height: 10px;
    width: 10px;
    border-radius: 7.5px;
    margin: 0 5px;
}

.success-slider-dots li.slick-active button {
    background-color: #3e8ebc;
}

.success-slider-wrapper {
    display: flex !important;
    background-color: rgba(249, 250, 252, 0.79);
    padding: 2vw 0;
}

.success-slider-wrapper > section {
    padding-left: 2vw;
    padding-right: 2vw;
    display: flex;
    flex-direction: column;
}

.success-slider-person {
    width: 14vw;
    height: 16vw;
    border-radius: 5px;
}

.success-slider-linkedin {
    cursor: pointer;
    width: 2.4vw;
    display: inherit;
    position: relative;
    top: 16vw;
}

.read-more-btn {
    font-family: "Poppins-Regular";
    color: #3a3f8e;
    cursor: pointer;
}

.success-slider-contents {
    margin-top: 0.9vw;
}

.success-slider-contents > p {
    width: 97%;
    font-size: 1.5vw;
    font-family: "Poppins-Regular";
    text-align: justify;
    line-height: 3vw;
}

.success-slider-author-details-wrapper {
    display: flex;
    flex-direction: column;
    bottom: 2vw;
    margin-top: 1vw;
}

.success-slider-author-details-wrapper > section {
    display: flex;
}

.success-slider-author-details-wrapper > section > p {
    margin: 0;
    margin-left: 0.5vw;
}

.success-slider-author-details-wrapper > p {
    font-size: 1.8vw;
    color: #000000;
    font-family: "Poppins-Medium";
}

.stories-blog-wrapper {
    background: #fff;
    display: flex;
    flex-direction: column;
    text-align: justify;
    padding: 0vw 2vw;
    padding-top: 3vw;
    margin-bottom: 2vw;
    align-items: center;
    background-color: rgba(249, 250, 252, 0.79);
}

.stories-blog-wrapper > img {
    align-self: flex-start;
    width: 2.5vw;
    margin-bottom: 1vw;
}

.stories-blog-wrapper > p {
    width: 95%;
    color: #000000;
    font-family: "Poppins-Regular";
    font-size: 1.1vw;
    margin-bottom: 2vw;
}

.stories-success-boxes-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2vw;
}

.success-box-wrapper {
    display: flex;
    padding: 0.6vw 1.25vw;
    flex: 1;
    margin: 0 10px;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid #e7eaee;
    border-radius: 15px;
    background-color: #FFFFFF;
}

.success-box-wrapper:last-child {
    margin-right: 0;
}

.success-box-description-wrapper > img {
    width: 2.5vw;
    height: auto;
    margin-bottom: 10px;
}

.success-box-description-wrapper > p,
.success-box-author-wrapper {
    padding-left: 1.5vw;
    text-align: justify;
}
.success-description hr {
    border: 1px solid #e7eaee;
}
.success-description .desc {
    font-size: 1.2vw;
    font-size: 1.2vw;
    font-weight: 400;
    color: #191d23;
    font-family: "Poppins-Regular";
}
.full-story {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.1vw;
    margin-top: 0.78vw;
    padding: 0.5vw;
    font-family: "Poppins-Medium";
    font-weight: 500;
}

.full-story .arrow-wrapper {
    width: 1.2vw;
}

.full-story .arrow-wrapper img {
    width: 100%;
}

.full-story p {
    font-weight: 500;
    font-family: "Poppins-Medium";
    font-size: 1.2vw;
}

.success-box-author-wrapper {
    position: relative;
    right: 2vw;
    display: flex;
    align-items: center;
}
.success-slider-logo-img {
    width: 8vw;
    position: relative;
    right: 0.56vw;
}
.success-box-person {
    width: 7vw;
    border-radius: 50%;
    aspect-ratio: 1/1;
    margin-top: 20px;
}

.success-box-author-details-wrapper {
    display: flex;
    align-items: baseline;
    width: fit-content;
    margin-right: 2vw;
}

.success-box-linkedin {
    position: relative;
    right: -2vw;
    height: 2vw;
    margin-left: -3vw;
    cursor: pointer;
    width: 2vw;
}

.success-box-company-wrapper {
    margin-top: 2vw;
}

.stories-blog-person-company-img-wrapper > img {
    width: 50%;
}
.company-img-wrapper {
    width: 98px;
    height: 28px;
}
.company-img-wrapper img {
    max-width: 100%;
    max-height: 100%;
    margin-top: -1px;
}

.success-box-company-wrapper > p {
    font-family: "Poppins-Medium";
    margin-bottom: 0;
    font-size: 1.2vw;
}

.stories-blog-person-wrapper {
    display: flex;
    width: 30%;
    align-items: center;
    margin-bottom: 2vw;
}

.stories-blog-person-image {
    border-radius: 50%;
    width: 8vw;
    height: auto;
    aspect-ratio: 1/1;
}

.stories-blog-person-details-wrapper {
    display: flex;
    align-items: baseline;
    width: 40%;
}

.stories-blog-linkedin-image {
    height: 5vh;
    position: relative;
    width: 2.5vw;
    right: 2vw;
    cursor: pointer;
}

.stories-blog-wrapper > div {
    margin-top: 2vw;
    margin-bottom: 1vw;
    color: #444bab;
    font-family: "Poppins-SemiBold";
    font-size: 1.5vw;
    cursor: pointer;
}

.stories-blog-person-company-wrapper {
    display: flex;
    flex-direction: column;
    margin-left: 1vw;
}

.stories-blog-person-company-wrapper > h3 {
    color: #000;
    font-family: "Poppins-Medium";
    font-size: 1.1vw;
    margin-bottom: 0;
}


.success-box-person-wrapper {
    position: relative;
    right: -0.85vw;
}

.circular-progress-wrapper {
    width: 10%;
    margin: 0 auto;
}

.success-slider-profile-wrapper {
    position: relative;
}

.placements-container {
    margin-top: 7.8vw;
    margin-bottom: 4.1vw;
}

.placements-container .heading {
    text-align: center;
}

.placements-container .companies-img-wrapper {
    padding: 2vw 5vw 3vw;
}

.companies-img-wrapper img {
    width: 100%;
}

.placements-container .many-more {
    font-size: 1.6vw;
    color: #191d23;
    text-align: center;
    font-family: "Poppins-Medium";
}

.start-free-trial-section h2 {
    font-size: 3.5vw;
    color: #231f20;
}

.start-free-trial-section p {
    font-size: 1.6vw;
}

.free-btn {
    display: flex;
    justify-content: center;
}

.start-free-trial-section .free-trial-btn {
    width: 20vw;
    font-size: 1.25vw;
    border-style: none;
    border-radius: 10px;
    background-color: #444bab;
    color: white;
    padding: 12px 14px;
    font-weight: 500;
}

@media (max-width: 600px) {
    .success-header-wrapper > h1 {
        font-size: 6.4vw;
    }
    .success-header-wrapper > p {
        font-size: 14px;
        text-align: center;
    }
    .success-header-wrapper {
        padding: 10px 0;
    }

    .success-wrapper .slick-prev {
        width: 10.6vw;
        height: 10.6vw;
        margin-top: 5vw;
        left: 26vw;
        top: 105%;
        z-index: 0;
    }

    .success-wrapper .slick-next {
        width: 10.6vw;
        height: 10.6vw;
        margin-top: 5vw;
        right: 32vw;
        top: 105%;
    }

    .full-story p {
        font-size: 16px;
    }
    .success-slider-contents > p {
        font-size: 4.2vw;
        line-height: 6.4vw;
    }
    .success-slider-contents span{
        font-size: 4.2vw;
    }
    .quotation-mark {
        width: 9%;
        margin-bottom: 2vw;
        margin-top: 20px;
    }
    .success-slider-profile-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 2vw;
    }
    .success-slider-statement-quotation-wrapper {
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 20px;
    }
    .success-slider-person {
        width: 15vw;
        height: 16vw;
        border-radius: 50%;
        position: relative;
        right: 16vw;
    }

    .success-slider-author-details-wrapper > p {
        font-size: 20px;
    }
    .stories-success-boxes-wrapper {
        flex-direction: column;
    }
    .success-box-wrapper {
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 30px;
        padding: 10px 20px 20px 20px;
    }
    .success-box-person {
        width: 19vw;
    }
    .success-box-company-wrapper > p,
    .stories-blog-person-company-wrapper > h3,
    .success-slider-author-details-wrapper > p {
        font-size: 16px;
    }
    .success-box-linkedin {
        position: relative;
        right: 6px;
        width: 5vw;
        height: 5vw;
    }
    .success-box-description-wrapper > img,
    .stories-blog-wrapper > img {
        width: 6.5vw;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .company-img-wrapper {
        width: 75px;
    }
    .stories-blog-wrapper > p {
        font-size: 15px;
        margin-bottom: 20px;
    }
    .stories-blog-person-wrapper {
        width: 90%;
    }
    .stories-blog-person-image {
        width: 25vw;
    }
    .stories-blog-linkedin-image {
        width: 8.5vw;
        right: 5vw;
    }
    .success-slider-linkedin {
        top: 16vw;
        width: 4vw;
        z-index: 1;
        right: 5vw;
    }
    .stories-blog-person-company-wrapper > img {
        height: 8vw;
        width: 25vw;
    }
    .stories-blog-wrapper > div {
        font-size: 18px;
        padding: 20px 0;
    }
    .success-slider-author-details-wrapper {
        position: relative;
        top: 4vw;
        right: 10vw;
    }

    .success-slider-author-details-wrapper p {
        font-size: 3.7vw;
    }

    .success-slider-logo-img {
        width: 21vw;
    }
    .success-slider-slick-wrapper .slick-slider {
        margin-bottom: 20vw;
    }

    .placements-container {
        margin-top: 26vw;
        margin-bottom: 12vw;
    }
    .placements-container .heading {
        margin-bottom: 6.4vw;
        width: 100%;
        font-size: 6.1vw;
    }

    .placements-container .companies-img-wrapper {
        padding: 2vw 0vw 3vw;
    }
    .placements-container .many-more {
        margin-top: 1.2vw;
        font-size: 4.2vw;
    }
    .stories-success-boxes-wrapper {
        margin-bottom: 0vw;
    }
    .start-free-trial-section h2 {
        font-size: 5.8vw;
    }

    .start-free-trial-section p {
        font-size: 3.7vw;
    }

    .start-free-trial-section .free-trial-btn {
        width: 85%;
        padding: 5vw;
        margin-top: 3.5vw;
        font-size: 3.7vw;
    }

    .full-story {
        margin-top: 2vw;
        gap: 20px;
    }

    .full-story .arrow-wrapper {
        width: 4.2vw;
    }
    .success-description .desc {
        font-size: 16px;
        line-height: 7.4vw;
        font-family: "Poppins-Regular";
    }
    .success-box-person-wrapper {
        position: relative;
        right: -0.7vw;
    }
}

@media screen and (min-width: 1920px) {
    .success-wrapper {
        padding: 0 11.5vw;
    }
    .success-header-wrapper > h1 {
        font-size: 2vw;
    }
    .success-header-wrapper > p {
        font-size: 0.92vw;
    }
    .success-slider-person-wrapper {
        position: relative;
        top: -1vw;
    }
    .success-slider-linkedin-wrapper {
        z-index: 2;
        position: relative;
        top: 14vw;
    }
    .success-slider-linkedin {
        top: 0;
        width: 2vw;
    }
    .success-slider-person-wrapper {
        top: 0;
    }
    .success-slider-person {
        width: 13vw;
        height: 14vw;
    }

    .success-wrapper .slick-prev {
        left: -57px;
        width: 3.5rem;
        height: 3.5rem;
    }
    .success-wrapper .slick-next {
        right: -31px;
        width: 3.5rem;
        height: 3.5rem;
    }
    .success-slider-contents > p {
        font-size: 1.2vw;
        line-height: 2vw;
    }
    .placements-container .heading {
        font-size: 2vw;
    }
    .placements-container .companies-img-wrapper {
        width: 85%;
        margin-left: 7vw;
    }

    .start-free-trial-section h2 {
        font-size: 2vw;
    }
    .success-box-company-wrapper > p {
        font-size: 0.93vw;
    }
    .success-description .desc {
        font-size: 0.93vw;
    }
    .full-story p {
        font-size: 0.93vw;
    }
    .success-box-linkedin {
        width: 1.5vw;
        height: 1.5vw;
    }
    .company-img-wrapper {
        width: 150px;
        height: 35px;
    }
    .placements-container .many-more {
        font-size: 1.2vw;
    }
    .start-free-trial-section p {
        font-size: 1.2vw;
    }
    .start-free-trial-section .free-trial-btn {
        width: 14vw;
        font-size: 0.95vw;
    }
}

@media screen and (min-width: 2560px) {
    .success-wrapper {
        padding: 0 17.5vw;
    }
    .success-header-wrapper > h1 {
        font-size: 1.5vw;
    }
    .success-header-wrapper > p {
        font-size: 0.72vw;
    }
    .success-slider-person-wrapper {
        position: relative;
        top: -1vw;
    }
    .success-slider-linkedin-wrapper {
        z-index: 2;
        position: relative;
        top: 8.5vw;
    }
    .success-slider-linkedin {
        top: -3vw;
        width: 1.2vw;
    }

    .success-slider-person {
        width: 5.5vw;
        height: 6.5vw;
    }
    .success-box-description-wrapper span,
    .success-slider-statement-quotation-wrapper span {
        font-size: 0.9vw;
    }
    .success-wrapper .slick-prev {
        left: -60px;
        width: 3.5rem;
        height: 3.5rem;
    }
    .success-wrapper .slick-next {
        right: -26px;
        width: 3.5rem;
        height: 3.5rem;
    }
    .success-slider-contents > p {
        font-size: 0.93vw;
        line-height: 1.5vw;
    }
    .success-slider-author-details-wrapper > p {
        font-size: 0.93vw;
    }
    .success-slider-logo-img {
        width: 5vw;
        right: 0.4vw;
    }
    .placements-container {
        margin-bottom: 1.2vw;
    }
    .placements-container {
        margin-top: 3.8vw;
        margin-bottom: 1.2vw;
    }
    .placements-container .heading {
        font-size: 1.5vw;
    }

    .placements-container .companies-img-wrapper {
        width: 80%;
        margin-left: 8vw;
        padding: 2vw 5vw 1vw;
    }
    .success-box-person {
        width: 4vw;
    }

    .success-box-company-wrapper > p {
        font-size: 0.73vw;
    }
    .success-description .desc {
        font-size: 0.73vw;
    }
    .full-story p {
        font-size: 0.73vw;
    }
    .success-box-linkedin {
        width: 1.25vw;
        height: 1.25vw;
        right: -2vw;
    }
    .company-img-wrapper{
        width: 150px;
        height: 45px;
    }
    .placements-container .many-more {
        font-size: 0.95vw;
        margin-left: 3vw;
    }
    .start-free-trial-section h2 {
        font-size: 1.5vw;
    }
    .start-free-trial-section p {
        font-size: 0.95vw;
    }
    .start-free-trial-section .free-trial-btn {
        width: 12vw;
        font-size: 0.93vw;
    }
}
