* ::-webkit-scrollbar {
    width: 0px;
    display: none;
}

* ::-webkit-scrollbar-track {
    background: #fff;
    display: none;
}

* ::-webkit-scrollbar-thumb {
    background: #fff;
    display: none;
}

* ::-webkit-scrollbar-thumb:hover {
    background: #fff;
    display: none;
}

.header-sticky {
    position: sticky;
    top: 0px;
    z-index: 4;
}

.custom-h1 {
    font-size: 2em !important; /* Default for <h1>, usually around 32px */
    font-weight: bold !important; /* <h1> is bold */
    line-height: 1.2 !important; /* Good readability for headers */
    margin: 0.67em 0 !important; /* Default browser margin */
}

.custom-h2 {
    font-size: 1.5em !important; /* Default for <h2>, usually around 24px */
    font-weight: lighter !important; /* <h2> is bold */
    line-height: 1.3 !important; /* Slightly more relaxed than <h1> */
    margin: 0.75em 0 !important; /* Default browser margin */
}

.custom-h3 {
    font-size: 1.17em !important; /* Default for <h3>, usually around 18.72px */
    font-weight: bold !important; /* <h3> is bold */
    line-height: 1.4 !important; /* Relaxed line height for readability */
    margin: 0.83em 0 !important; /* Default browser margin */
}

.custom-h4 {
    font-size: 1em !important; /* Default for <h4>, usually around 16px */
    font-weight: bold !important; /* <h4> is bold */
    line-height: 1.5 !important; /* Generous line height */
    margin: 1.12em 0 !important; /* Default browser margin */
}



.error-message-form-input-field {
    font-size: 13px !important;
    color: red !important;
    font-family: "Poppins-Regular";
    font-weight: 500 !important;
}
a {
    text-decoration: none;
}

.Success-Stories-App {
    background-color: #f7f8f9;
}

.shining-button-to-attract-click {
    /* outline: 1px solid #fbbe24c3 !important;
    box-shadow: #fef3c7 0 0 4px 4px; */
    outline: 1px solid #5a74d7 !important;
    box-shadow: #465aca52 0 0 4px 4px;
}

.transition-ease {
    transition: all 600ms ease-out;
}
