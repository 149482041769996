.about-main-wrapper {
  margin: 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.about-heading {
  font-size: 2.4vw;
  padding: 3vw 0;
  font-family: Poppins-Medium;
}
.about-video-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5vw;
  width: 60vw;
  height: 30vw;
  border-radius: 1.9vw;
  cursor: pointer;

  /* equivalent to frameborder=0 */
  border: none;  
}
.about-thumbnail {
  width: 60vw;
  /* height: 30vw; */
  border-radius: 2vw;
  margin-bottom: 1.5vw;
}

@media (max-width: 600px) {
  .about-main-wrapper {
    margin: 4vw 0;
    padding: 2vw;
  }
  .about-heading {
    font-size: 5vw;
    padding-bottom: 6vw;
  }
  .about-video-wrapper {
    width: 92vw;
    height: 49vw;
    border-radius: 4vw;
    margin-bottom: 3vw;
  }
  .about-thumbnail {
    width: 92vw;
    height: 49vw;
    border-radius: 4vw;
    margin-bottom: 3vw;
  }
}
