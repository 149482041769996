.python-pricing-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1vw 0vw;
    margin: 1vw 0vw;
    margin-bottom: 4.5vw;
}

.python-pricing-wrapper h2 {
    font-family: Poppins-Regular;
    font-weight: 500;
    font-size: 2.5vw;
    text-align: center;
    margin-bottom: 0.5vw;
}

.python-pricing-wrapper p {
    font-family: Poppins-Regular;
    font-weight: 400;
    font-size: 2vw;
    text-align: center;
    margin-bottom: 0vw;
}

.python-pricing-plans-wrapper {
    display: flex;
    margin-top: 4vw;
}

.python-pricing-plan-card {
    border: 1.1px solid #D0D5DD;
    border-radius: 1.39vw;
    width: 21vw;
    height: 24vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3vw 1vw;
    box-shadow: 0px 4.4px 22px 0px #0000001A;
    margin: 0 2vw;
}

.python-pricing-plan-card-two {
    position: relative;
    top: -1.5vw;
    border: 1.1px solid #D0D5DD;
    border-radius: 1.39vw;
    width: 23vw;
    height: 28vw;
    display: flex;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 6vw;
    background: linear-gradient(-135deg, #F1511B 6.95vw, #ffffff 0);
    margin: 0 3vw;
    box-shadow: 0px 4.4px 22px 0px #0000001A;
}

.python-recommend-triangle {
    position: absolute;
    top: 12%;
    right: -2%;
    transform: rotate(45deg);
    color: white;
    font-size: 1vw;
    font-weight: 600;
    font-family: "Poppins-SemiBold";
    margin-bottom: -3.47vw;
}

.python-initial-price-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.python-price-saved {
    color: #e48915;
    font-family: "Lato-Regular";
    font-weight: 700;
    font-size: 1.6vw;
    margin-bottom: 1vw;
    margin-left: 1vw;
}

.python-plan-duration {
    font-family: "Lato-Black" !important;
    font-weight: 900 !important;
    font-size: 1.8vw !important;
    color: #272834;
    margin-bottom: 1vw !important;
}

.python-pricing-plan-card-two>.python-plan-duration {
    font-family: "Lato-Black";
    font-weight: 900;
    font-size: 1.8vw;
    color: #F1511B;
    margin-bottom: 1vw;
}

.python-horizontal-line-price {
    width: 40px;
    height: 0px;
    border-radius: 10px;
    border: 1px solid #3babc3;
    background: #3babc3;
    margin-bottom: 2vw;
}

.python-pricing-plan-card-two>.python-horizontal-line-price {
    color: #F1511B;
    border: 1px solid #F1511B;
}

.python-plan-price {
    color: #888888;
    text-decoration: line-through !important;
    font-family: "Lato-Regular" !important;
    font-size: 2vw !important;
    margin-bottom: 1vw !important;
}

.python-plan-discount-price {
    font-family: "Lato-Black" !important;
    font-weight: 900 !important;
    font-size: 2.9vw !important;
    color: #272834;
    margin-bottom: 1vw !important;
}

.python-pricing-plan-card-two>.python-plan-discount-price {
    color: #F1511B;
}

.python-plan-desc {
    font-family: "Lato-Regular";
    font-weight: 400;
    font-size: 1.4vw;
    text-align: center;
    color: #231F20;
    margin-bottom: 2vw;
}

.python-subscribe-now-button {
    background: linear-gradient(93.44deg, #444bab -6.8%, #3ca5c2 186.94%);
    border-radius: 0.4vw;
    width: 17vw;
    height: 5vw;
    font-family: "Poppins-SemiBold";
    font-weight: 600;
    font-size: 1.4vw;
    color: #ffffff;
    border: none;
    margin-bottom: 1vw;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;
    padding: 0.5vw 2vw;
}

.python-pricing-plan-card-two>.python-subscribe-now-button {
    background: linear-gradient(93.44deg, #D03F01 -6.8%, #FF9D43 186.94%);
}

.python-emi-text {
    color: #9a4d06 !important;
    font-family: Poppins-Medium !important;
    margin-bottom: 1vw !important;
    font-size: 1.8vw !important;
}

.python-gift-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 1.5vw;
}

.python-gift-wrapper>img {
    height: 1.6vw;
    width: 1.6vw;
    margin-right: 0.4vw;
}

.python-gift-wrapper>p {
    font-family: "Poppins-Medium";
    font-weight: 500;
    font-size: 1.8vw;
    color: #4f516b;
}

.python-referral-code {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.python-referral-code>input {
    width: 23.6vw;
    background: #f7f8f9;
    border: 1px solid #d5dbe1;
    border-radius: 0.35vw;
    padding: 0.7vw;
    font-family: "Poppins-Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 1.23vw;
    color: #616161;
}

.python-referral-code>span {
    align-items: center;
    border: none;
    border-radius: .4vw;
    bottom: 2.9vw;
    color: #444bab;
    cursor: pointer;
    display: flex;
    font-family: Poppins-Medium;
    font-size: 1.23vw;
    font-weight: 600;
    justify-content: center;
    left: 9vw;
    padding: .3vw .7vw;
    position: relative;
}

.python-error-coupon-code-wrapper {
    text-align: center;
    font-family: "Gilroy-Medium";
    font-size: 1.2vw;
    color: #ff5050;
    margin-top: 2vw;
    margin-bottom: 2vw;
}

m,
.python-free-trial-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.2vw;
    margin-bottom: 4vw;
    margin-top: 5vw;
}

.python-free-trial-wrapper>p {
    font-family: "Poppins-SemiBold";
    font-weight: 600;
    font-size: 2.09vw;
    color: #4f516b;
}

.python-free-trial-wrapper>span {
    font-family: "Poppins-Medium";
    font-weight: 500;
    font-size: 1.39vw;
    color: #4359af;
    margin-bottom: 1vw;
}

.python-free-trial-wrapper>button {
    width: 20.9vw;
    height: 3.9vw;
    background: linear-gradient(91.59deg, #444bab 1.85%, #3ca5c2 99.69%);
    border-radius: 0.4vw;
    border: none;
    font-family: "Poppins-SemiBold";
    font-weight: 600;
    font-size: 1.39vw;
    color: #ffffff;
}

@media (max-width: 600px) {
    .python-pricing-wrapper {
        margin: 5vw 4vw;
    }

    .python-pricing-wrapper h2 {
        font-size: 6.4vw;
        font-weight: 600;
        font-family: Poppins-Medium;
        margin-top: 5vw;
    }

    .python-pricing-wrapper p {
        font-size: 4.27vw;
        margin-bottom: 3vw;
    }

    .python-pricing-plans-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 4vw;
    }

    .python-pricing-plan-card {
        height: 96vw;
        width: 90vw;
        margin: 5vw 0;
        padding: 1vw 2vw;
        border-radius: 4.5vw;
        box-shadow: none;
        background-color: #f7f8f9;
    }

    .python-pricing-plan-card-two {
        height: 92.8vw;
        width: 90vw;
        margin: 5vw 2vw;
        padding: 1vw 2vw;
        box-shadow: none;
        background: linear-gradient(-135deg, #F1511B 18vw, #f7f8f9 0);
        border-radius: 4.5vw;
        top: 0;
    }

    .python-recommend-triangle {
        right: 0;
        top: 8%;
        font-size: 2.5vw;
    }

    .python-plan-duration {
        font-size: 6.4vw !important;
        font-weight: 600 !important;
        color: #3940A0;
        margin-bottom: 2vw !important;
    }

    .python-pricing-plan-card-two>.python-plan-duration {
        font-size: 6.4vw;
        font-weight: 600;
        margin-bottom: 2vw;
    }

    .python-horizontal-line-price {
        margin-top: 5px;
        margin-bottom: 15px;
        border: 1px solid #3940A0;
        ;
    }

    .python-plan-price {
        font-size: 5.1vw !important;
        color: #64748B;
        font-weight: 600 !important;
        margin: 20px 0px !important;
    }

    .python-plan-discount-price {
        font-size: 8.8vw !important;
        color: #3940A0;
        margin-bottom: 5vw 0 !important;
    }

    .python-plan-desc {
        font-size: 4.27vw;
        font-weight: 600;
        margin: 5vw 0;
    }

    .python-subscribe-now-button {
        font-size: 4.27vw;
        padding: 4.2vw 7vw;
        border-radius: 2.67vw;
        width: 83vw;
        height: auto;
        margin-bottom: 1vw;
    }

    .python-emi-text {
        text-align: center !important;
        padding: 2vw 5vw !important;
        font-size: 3.73vw !important;
    }

    .python-gift-wrapper img {
        width: 1.4rem;
        height: 1.4rem;
        margin-right: 2vw;
    }

    .python-gift-wrapper p {
        font-size: 4.27vw;
        padding-top: 1vw;
    }

    .python-referral-code>input {
        height: 100%;
        font-size: 4.5vw;
        width: 100%;
        margin-top: 3vw;
        padding: 3.5vw 0;
        text-indent: 15px;
    }

    .python-referral-code>span {
        font-size: 4.27vw;
        color: #fff;
        background-color: #444bab;
        bottom: 13.5vw;
        left: 35vw;
        margin-top: 1vw;
        border-radius: 1.5vw;
        padding: 2vw 3vw;
    }

    .python-free-trial-wrapper>p {
        font-size: 22px;
        margin-bottom: 15px;
    }

    .python-free-trial-wrapper>span {
        font-size: 18px;
        margin-bottom: 12px;
    }
}