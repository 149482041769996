.common-course-for-wrapper {
    background-size: cover;
    display: flex;
    margin: 8vw;
    margin-top: 0;
    margin-bottom: 6vw;
    align-items: center;
}
.common-course-for-content {
    display: flex;
    flex-direction: column;
    width: 55%;
}
.common-course-for-illustrator-wrapper {
    margin-left: 6vw;
    width: 33%;
}
.common-course-for-content > h2 {
    margin-bottom: 1.7vw;
    font-family: "Poppins-SemiBold";
    font-weight: 600;
    font-size: 2vw;
    color: #272834;
}
.common-course-for-content > p {
    margin-bottom: 0.7vw;
    font-family: "Poppins-Regular";
    font-weight: 400;
    font-size: 1.1vw;
    color: #2f3043;
    padding-bottom: 1.5vw;
}
.common-course-for-content-points {
    display: flex;
    align-items: center;
    padding-bottom: 0.7vw;
}
.common-course-for-content-points > p {
    font-family: "Poppins-Regular";
    font-weight: 400;
    font-size: 1.1vw;
    color: #2f3043;
}
.tick-mark-img-wrapper {
    object-fit: contain;
    margin-right: 1vw;
}
.common-course-for-content-points > img {
    width: 100%;
    height: 100%;
}
.common-course-for-content-image {
    position: relative;
    bottom: 2vw;
    height: auto;
}

/* course for image illustrator */
.dsa-course-for-illustrator {
    width: 55%;
    height: 25vw;
}
.react-course-for-illustrator {
    height: 30vw;
}
.common-course-for-content > span {
    margin-top: 1.39vw;
    margin-bottom: 1.39vw;
    font-family: "Poppins-Medium_Italic";
    font-weight: 500;
    font-size: 1.1vw;
    color: #2f3043;
}

@media (max-width: 600px) {
    .common-course-for-wrapper {
        display: flex;
        flex-direction: column;
        margin: 10vw 8vw;
        align-items: center;
    }
    .common-course-for-content {
        width: 100%;
    }
    .common-course-for-illustrator-wrapper {
        margin-left: 0;
        width: 80%;
    }
    .common-course-for-content > h2 {
        font-size: 20px;
    }
    .common-course-for-content > p {
        text-align: justify;
        font-size: 14px;
        margin-bottom: 20px;
    }
    .common-course-for-content-points {
        height: 20px;
        margin-bottom: 10px;
    }
    .common-course-for-content-points > p {
        font-size: 15px;
    }
    .common-course-for-content > span {
        font-size: 15px;
        margin-bottom: 20px;
        margin-top: 30px;
    }
    .lld-course-for-illustrator {
        width: 100%;
    }
    .dsa-course-for-illustrator {
        height: 70vw;
        margin-left: 20%;
    }
    .react-course-for-illustrator {
        height: 100%;
        width: 100%;
    }
}
