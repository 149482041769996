.selection-screen-container {
    background: linear-gradient(179.83deg, #d7edfd 0.15%, rgba(252, 254, 255, 0) 99.85%);
}
.selection-screen-header-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.selection-screen-logo {
    width: 4vw;
    margin-bottom: 0.8vw;
}

.selection-screen-heading {
    font-family: "Lato-Bold";
    font-size: 2.3vw;
    font-weight: bolder;
    margin-bottom: 20px;
}

.selection-screen-subheading {
    font-family: "Lato-Regular";
    font-size: 1.2vw;
    margin-bottom: 2vw;
}

.selection-screen-courses {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-left: 7%;
}

.selection-screen-course-card-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 25%;
    margin: 3%;
    background: linear-gradient(179.83deg, #edf4fa 0.15%, rgba(252, 254, 255, 0) 99.85%);
    border: 1px solid #e3e3e3;
    border-radius: 12px;
    padding: 0vw 1.5vw;
    padding-top: 1vw;
    position: relative;
}

.course-image-tag {
    position: absolute;
    right: 0vw;
    top: 1.5vw;
    width: 50%;
    height: auto;
}
.new-course {
    position: absolute;
    right: 0vw;
    top: 0vw;
    width: 15%;
    height: auto;
    border-top-right-radius: 12px;
}
.selection-screen-course-content {
    margin-top: 3vw;
}
.selection-screen-course-title {
    font-family: "Lato-Bold";
    font-size: 1.5vw;
    padding: 2vw 0vw;
}

.selection-screen-course-description {
    font-family: "Lato-Regular";
    font-size: 1.1vw;
    color: #616161;
}

.selection-screen-course-button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.5vw;
    margin-top: 3vw;
}

.selection-screen-course-button {
    background: linear-gradient(91.41deg, #444bab 0%, #3aa2c1 99.71%);
    border-radius: 10px;
    color: #ffffff;
    font-family: "Lato-Bold";
    font-weight: bold;
    font-size: 1.3vw;
    text-transform: none;
    padding: 0.8vw 0;
    width: 100%;
    border: none;
    cursor: pointer;
}

.selection-screen-course-button:disabled {
    cursor: progress;
    opacity: 0.7;
}

@media (max-width: 600px) {
    .selection-screen-logo {
        margin-bottom: 15px;
        width: 10%;
    }
    .selection-screen-heading {
        text-align: center;
        font-size: 25px;
        margin-bottom: 15px;
    }
    .selection-screen-subheading {
        font-size: 14px;
    }
    .selection-screen-courses {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 15px;
        margin: 15px 0px;
    }
    .selection-screen-course-card-wrapper {
        width: 80%;
        margin-bottom: 20px;
        padding: 10px 20px;
        flex-direction: column;
    }
    .course-image-tag {
        width: 45%;
    }
    .selection-screen-course-content {
        margin: 50px 0px;
    }
    .selection-screen-course-title {
        font-size: 20px;
    }
    .selection-screen-course-description {
        font-size: 14px;
    }
    .selection-screen-course-button-wrapper {
        width: 100%;
    }
    .selection-screen-course-button {
        padding: 10px 0;
        margin-top: 25px;
        font-size: 15px;
    }
}
