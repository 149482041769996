.cpp-audience-wrapper {
    display: flex;
    flex-direction: column;
    background: #EFFFF3;
    margin-bottom: 3vw;
    padding: 2vw 2.25vw;
}
.cpp-audience-heading-wrapper {
    text-align: center;
    font-family: Poppins-Regular;
    color: #000000;
}
.cpp-audience-heading-wrapper > h2 {
    font-size: 3.1vw;
    font-weight: 600;
    padding-bottom: 1vw;
}
.cpp-audience-card-wrapper {
    display: flex;
    justify-content: center;
}
.cpp-audience-card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: auto;
    padding: 2vw 1vw;
}
.cpp-audience-img {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 1vw;
}
.cpp-audience-img img {
    height: 20vw;
}
.cpp-audience-subheading .subHeadingCss {
    font-family: Poppins-Medium;
    font-size: 1.2vw;
    text-align: center;
    padding: 0.5vw 1vw;
}
.cpp-audience-text {
    font-family: Poppins-Regular;
    font-size: 1.1vw;
    text-align: center;
    padding: 0.5vw 0;
}

@media (max-width: 600px) {
    .cpp-audience-wrapper {
        margin-bottom: 0.5vw;
        padding: 1vw 5vw;
    }
    .cpp-audience-heading-wrapper > h2 {
        font-size: 5.25vw;
    }
    .cpp-audience-card-wrapper {
        padding: 1.5vw 0;
        margin-bottom: 0.5vw;
        flex-direction: column;     
    }
    .cpp-audience-card {
        padding: 2vw 0;
        margin-bottom: 2vw;
    }
    .cpp-audience-img img {
        width: 50vw;
        height: auto;
    }
    .cpp-audience-subheading .subHeadingCss {
        padding-top: 1vw;
        font-size: 4.1vw;
    }
    .cpp-audience-text{
        font-size: 3.5vw;
    }
}