.canellation-policy {
    padding: 0 5vw;
    padding-bottom: 5vw;
}

.canellation-policy h2 {
    font-size: 1.8vw;
    color: #272834;
    font-family: "Poppins-SemiBold";
    padding: 2.5vw 0;
}
.canellation-policy h2 {
    font-family: "Poppins-Regular";
    font-size: 1.6vw;
    margin-bottom: -1vw;
    color: #272834;
}
.canellation-policy h5 {
    font-family: "Poppins-Regular";
    color: #272834;
    font-size: 1.2vw;
    margin-bottom: 2px;
}
.canellation-policy p {
    font-family: "Poppins-Regular";
    color: #505050;
    font-size: 1.1vw;
    text-align: justify;
    padding-bottom: 1vw;
}
.cancellation-policy-content-wrapper {
    padding: 4vw 0;
    padding-bottom: 1.5vw;
}
.refund-request-content-wrapper {
    padding-bottom: 2vw;
}
@media (max-width: 600px) {
    .canellation-policy h2 {
        font-size: 3.8vw;
        padding: 40px 0;
    }
    .canellation-policy h2 {
        font-size: 3vw;
    }
    .canellation-policy h5 {
        font-size: 3vw;
    }
    .canellation-policy p {
        font-size: 2.8vw;
    }
    .canellation-policy {
        padding-left: 4vw;
    }
}
