.lld-pricing-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 5vw 0vw;
    margin-top: 7vw;
    margin-bottom: 1vw;
}
.lld-pricing-wrapper h2 {
    font-family: Poppins-SemiBold;
    font-size: 2.5vw;
    text-align: center;
}
.lld-pricing-wrapper p.finalPrice {
    font-family: "Lato-Regular";
    color: #3940a0;
    font-weight: 900;
    font-size: 3vw;
    margin-bottom: -2px;
}
.plans-section-wrapper {
    width: 100%;
}
.plans-wrapper {
    background: #f6f6f6;
    width: 100%;
}
.plans-wrapper table {
    background: #f6f6f6;
    width: 100%;
}
.plans-details-wrapper {
    display: flex;
    flex-direction: column;
}
.pricing-table-heading-wrapper {
    display: flex;
    padding: 2vw 1vw;
}
.pricing-table-heading-column-1 {
    width: 35%;
    text-align: left;
    padding-top: 8vw;
    padding-left: 2vw;
    font-family: Poppins-SemiBold;
    font-size: 2vw;
}
.pricing-table-heading-column {
    width: 22%;
}

.duration {
    font-family: Poppins-SemiBold;
    color: #3940a0 !important;
}

.initialPrice {
    font-weight: bold !important;
    font-size: 1.5vw !important;
    margin-bottom: -5px !important;
    text-decoration-line: line-through !important;
    color: #64748b !important;
    margin-top: 20px !important;
}
.duration{
    font-weight: bold !important;
    font-size: 20px !important;
    margin-bottom: -7px !important;
}

.invest-heading {
    font-size: 23px !important;
    font-weight: 600 !important;
    color: #58595b !important; 
}

.plan-type {
    color: #4f516b;
    font-family: Poppins-Bold;
    font-size: 1.5vw;
}
.coupon-code-applied-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.5vw 0vw;
    margin-bottom: 1vw;
}
.coupon-code-applied {
    color: #475bac;
    padding: 0.3vw 0.6vw;
    border-radius: 12px;
    background: #ffffff;
    font-size: 0.9vw;
    font-family: Poppins-Regular;
}
.original-price-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}
.original-price {
    color: #888;
    font-family: Lato-Regular;
    font-weight: 400;
    font-size: 1.5vw;
    text-decoration: line-through;
}
.mentored-saved-amount {
    background-color: #fc8;
    color: #9c5e0b;
    padding: 0.3vw 0.5vw;
    border-radius: 10px;
    background: #a1d1ff;
    font-size: 1vw;
    font-family: Lato-Regular;
    margin-left: 1vw;
}
.saved-amount {
    color: #475bac;
    padding: 0.3vw;
    border-radius: 10px;
    background: #a1d1ff;
    font-size: 1vw;
    font-family: Lato-Regular;
    margin-left: 1vw;
}
.mentored-final-price {
    background: linear-gradient(106deg, #ff5f12 0%, #ffa01e 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: Lato-Black;
    font-size: 2.3vw;
    padding-bottom: 0.3vw;
}
.final-price {
    background: linear-gradient(123deg, #4756a9 0%, #4584c1 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: Lato-Black;
    font-size: 2.3vw;
    padding-bottom: 0.3vw;
}
.per-month-price {
    color: #727272;
    font-family: Lato-Regular;
    font-weight: 400;
    font-size: 1vw;
}

.started-btn button {
    background: linear-gradient(95.61deg, #444bab 0%, #3babc3 100%);
    color: #ffffff;
    font-family: Lato-Bold;
    font-size: 1vw;
    border: 1px solid #e0e0e0;
    padding: 0.8vw 1vw;
    border-radius: 5px;
    margin-top: 2.1vw;
    cursor: pointer;
}
.self-paced-btn button {
    background: linear-gradient(95.61deg, #444bab 0%, #3babc3 100%);
    color: #ffffff;
    font-family: Lato-Bold;
    font-size: 1vw;
    border: 1px solid #e0e0e0;
    padding: 0.8vw 1vw;
    border-radius: 5px;
    margin-top: 1vw;
    cursor: pointer;
}

.mentored-btn button {
    background: linear-gradient(90deg, #ff590b 0%, #ffb172 51.02%, #ff9e16 100%);
    color: #633413;
    font-family: Lato-Bold;
    font-size: 1vw;
    border: 1px solid #e0e0e0;
    padding: 0.8vw 1vw;
    border-radius: 5px;
    margin-top: 1vw;
    cursor: pointer;
}
.pricing-icon {
    width: 1.4rem;
    height: 1.4rem;
}
.pricing-table-data-wrapper {
    display: flex;
    align-items: center;
    padding: 1.25vw;
}
.pricing-table-data-wrapper-hr {
    border: 2px solid #ccc9c9;
    margin: 0vw 1vw;
}
.pricing-table-data-column-1 {
    width: 35%;
    text-align: left;
    padding-left: 2vw;
    font-family: Poppins-Regular;
    font-size: 1.1vw;
}
.pricing-table-data-column {
    display: flex;
    justify-content: center;
    width: 22%;
}
.pricing-text-classes {
    text-align: center;
    background-color: #ccdde2;
    width: 40%;
    color: #000000;
    font-size: 1vw;
    font-family: Poppins-SemiBold;
    border-radius: 25px;
    padding: 0.5vw 0.8vw;
}
.pricing-text {
    text-align: center;
    background-color: #ccdde2;
    color: #000000;
    font-size: 1vw;
    font-family: Poppins-SemiBold;
    border-radius: 20px;
    padding: 0.5vw 0.5vw;
    width: 22%;
}
.pricing-blue-text {
    color: #444bab;
    font-family: Poppins-SemiBold;
}
.coupon-code-wrapper {
    margin-top: 3vw;
}
.lld-emi-text {
    color: #9a4d06;
    font-family: Poppins-Medium;
    margin-bottom: 1vw;
}

.lld-referral-code {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.lld-referral-code > input {
    width: 23.6vw;
    height: 3.2vw;
    background: #ffffff;
    border: 1px solid #d5dbe1;
    border-radius: 0.35vw;
    padding: 0.7vw;
    font-family: "Poppins-Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 1.1vw;
    color: #616161;
}
.lld-referral-code > span {
    position: relative;
    bottom: 2.5vw;
    left: 9vw;
    font-family: "Poppins-SemiBold";
    font-weight: 600;
    font-size: 1.1vw;
    color: #444bab;
    cursor: pointer;
}
.lld-gift-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 1.5vw;
}
.lld-gift-wrapper > img {
    height: 1.6vw;
    width: 1.6vw;
    margin-right: 0.4vw;
}

.lld-gift-wrapper > p {
    font-family: "Poppins-Medium";
    font-weight: 500;
    font-size: 1.1vw;
    color: #4f516b;
}
.gold-bg {
    border-top: 46px solid linear-gradient(90deg, #ff6216 0%, #ffa457 52.95%, #ffa121 100%);
}
.blue-bg {
    border-top: 46px solid #444bab;
}
.mob-plans-card-details-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 27px;
    border: 1px solid #444bab;
    background: #fff;
    margin: 6vw 9vw;
}
.mob-mentored-plans-card-details-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 27px;
    border: 1px solid #444bab;
    border-top: 46px solid #ff6216;
    background: #fff;
    margin: 2vw 6vw;
    position: relative;
}

.gold-mob-duration-wrapper {
    border-radius: 27px 27px 0px 0px;
    width: 100%;
    padding: 3vw;
    background: linear-gradient(90deg, #ff6216 0%, #ffa457 52.95%, #ffa121 100%);
}
.blue-mob-duration-wrapper {
    border-radius: 27px 27px 0px 0px;
    width: 100%;
    padding: 3vw;
    background: #444bab;
}

.mob-duration-text-white {
    color: #ffffff;
    font-family: Poppins-SemiBold;
    font-size: 3vw;
    text-align: center;
}
.mob-duration-text-gold {
    color: #231f20;
    font-family: Poppins-SemiBold;
    font-size: 3vw;
    text-align: center;
}
.mob-plan-type {
    color: #4f516b;
    font-family: Poppins-Bold;
    font-size: 4vw;
    padding-top: 4vw;
}

.apply-coupon-code-text {
    padding: 2px 4px;
    border-radius: 4px;
}

@media (max-width: 600px) {
    .lld-pricing-wrapper h2 {
        font-size: 6.4vw;
        margin-top: 20vw;
    }
    .initialPrice {
        font-size: 3.5vw !important;
        margin-top: 20px !important;
    }
    .lld-pricing-wrapper p.finalPrice {
        font-size: 32px;
    }
    .lld-pricing-wrapper .invest-heading {
        padding: 0 0 0 20px;
        font-size: 4.2vw;
    }
    .lld-pricing-wrapper h2 {
        font-size: 6.4vw;
        margin-bottom: 6vw;
    }
    .lld-pricing-wrapper .plans-section-wrapper {
        padding: 0vw 4vw;
    }

    .card-services-point-wrapper > p {
        font-size: 14px;
    }
    .original-price {
        font-size: 3.5vw;
    }
    .mentored-final-price {
        font-size: 4.5vw;
    }
    .final-price {
        font-size: 30px;
    }
    .per-month-price {
        font-size: 2.5vw;
    }
    .started-btn button {
        padding: 2vw 7vw;
        font-size: 2.5vw;
        font-family: Poppins-SemiBold;
    }
    .self-paced-btn button {
        padding: 2vw 7vw;
        font-size: 2.5vw;
        font-family: Poppins-SemiBold;
        margin-top: 3vw;
    }
    .mentored-btn button {
        padding: 2vw 7vw;
        font-size: 2.5vw;
        font-family: Poppins-SemiBold;
        margin-top: 3vw;
    }
    .mob-pricing-feature-wrapper {
        display: flex;
        flex-direction: column;
        padding: 3vw;
        padding-left: 6vw;
    }
    .mob-pricing-feature-card-wrapper {
        display: flex;
        align-items: center;
        width: 100%;
        margin: 2vw 0vw;
    }

    .pricing-icon {
        width: 1rem;
        height: 1rem;
        margin-right: 2vw;
    }
    .mob-pricing-feature-card-wrapper p {
        font-family: Poppins-Regular;
        font-size: 2.5vw;
    }
    .pricing-blue-text {
        font-family: Poppins-SemiBold;
        font-size: 2.5vw;
    }
    .lld-emi-text {
        text-align: center;
        padding: 2vw 4vw;
        font-size: 3.5vw;
    }
    .lld-gift-wrapper img {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 1vw;
    }
    .lld-gift-wrapper p {
        font-size: 4.2vw;
        padding-top: 1vw;
    }
    .lld-referral-code > input {
        margin-top: 2vw;
        height: 6vw;
        font-size: 3.5vw;
        width: 85vw;
        padding: 5vw;
        border-radius: 5px;
    }

    .lld-referral-code > span {
        font-size: 3.5vw;
        bottom: 8.3vw;
        left: 35vw;
    }
    .subscription-card-wrapper > p {
        font-weight: 600;
    }
    .saved-amount {
        font-size: 2.5vw;
        padding: 1vw;
        font-family: Lato-Bold;
        margin-left: 1vw;
    }
    .mentored-saved-amount {
        background: #fc8;
        font-size: 2.5vw;
        padding: 0.8vw 1vw;
        font-family: Lato-Bold;
        margin-left: 2vw;
    }
}
