.courses-wrapper {
    padding: 3vw 6vw;
}
.python-course-heading {
    font-family: Poppins-Regular;
    font-weight: 500;
    font-size: 2.5vw;
    text-align: center;
    padding-bottom: 4vw;
}
.cpp-course-heading {
    font-family: Poppins-Medium;
    font-weight: 500;
    font-size: 2.5vw;
    text-align: center;
    padding-bottom: 4vw;
}
.dsa-course-heading {
    font-family: Poppins-Medium;
    font-weight: 500;
    font-size: 2.5vw;
    text-align: center;
    padding-bottom: 4vw;
}
.lld-course-heading {
    font-family: Poppins-Semibold;
    font-weight: 500;
    font-size: 2.5vw;
    text-align: center;
    padding-bottom: 4vw;
}
.ren-course-heading {
    font-family: Poppins-Semibold;
    font-weight: 500;
    font-size: 2.5vw;
    text-align: center;
    padding-bottom: 4vw;
}
.courses-wrapper .slick-list {
    margin-left: 3vw;
}
.course-card {
    width: 90% !important;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 1.3vw;
    position: relative;
    cursor: pointer;
    margin-bottom: 5vw;
    height: 28vw;
}

.course-card-img-wrapper img {
    width: 100%;
    height: 100%;
    border-top-left-radius: 1.3vw;
    border-top-right-radius: 1.3vw;
}
.course-card-desc {
    color: #353535;
    font-family: Poppins-Regular;
    font-size: 1.1vw;
    padding: 1vw;
}
.course-card-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Poppins-SemiBold";

    background-color: #ffffff;
    border: none;
    position: absolute;
    bottom: 5%;
    padding-left: 1.5vw;
    color: #444bab;
    text-align: center;
}
.know-more-txt {
    font-size: 1.25vw;
}
.arrow-right {
    width: 12%;
    margin-left: 0.5vw;
}

@media (max-width: 600px) {
    .courses-wrapper {
        padding: 7vw 5vw;
    }
    .courses-wrapper h1 {
        font-size: 6.4vw;
        font-weight: 600;
        font-family: Poppins-Medium;
    }
    .courses-wrapper .slick-list {
        margin-left: 8vw;
    }
    .course-card {
        height: 75vw;
        border-radius: 3.6vw;
    }
    .course-card-desc {
        font-size: 3.1vw;
        padding: 3.1vw;
    }
    .course-card-btn {
        padding-left: 3.1vw;
    }
    .know-more-txt {
        font-size: 3.4vw;
        margin-top: 5px;
    }
    .course-card-img-wrapper img {
        border-top-left-radius: 3.3vw;
        border-top-right-radius: 3.3vw;
    }
    .slick-prev {
        left: -10px;
    }
    .slick-next {
        right: -10px;
    }
}
