.get-section-title-and-heading-wrapper {
    text-align: center;
}

.get-section-title-and-heading-wrapper h2 {
    font-family: "Poppins-SemiBold";
    color: #444557;
    margin: 2vh 0;
    font-size: 2.5vw;
}

.get-section-title-and-heading-wrapper h3 {
    font-family: "Poppins-SemiBold";
    color: #444bab;
    font-size: 1.3vw;
}

.get-section-title-and-heading-wrapper p {
    font-family: "Poppins-Regular";
    color: #444557;
    margin-bottom: 2vw;
    font-size: 1.2vw;
}

.common-page-title {
    font-family: "Poppins-SemiBold";
    color: #4f516b;
    font-size: 1.9vw;
    text-align: center;
    margin-bottom: 2vw;
}
.common-input-title {
    color: #737171;
    font-family: "Poppins-Regular";
    margin-bottom: 0;
    margin-bottom: 5px;
    font-size: 1.1vw;
    cursor: pointer;
}
.common-input-styles {
    width: 100%;
    font-size: 1.2vw;
    color: #393939;
    font-family: "Poppins-Regular";
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    padding: 0.7vw 0.5vw;
    margin-bottom: 1vw;
    outline-color: #c7c7d1;
}
.common-blue-gradient-CTA {
    background: linear-gradient(93.53deg, #444bab 0%, #416bb3 62.5%, #3ca5c2 100%);
    border: none;
    border-radius: 6px;
    color: #fff;
    padding: 1vw 0;
    font-family: "Poppins-Regular";
    text-align: center;
    cursor: pointer;
    font-size: 1.2vw;
    font-weight: 600;
    cursor: pointer;
}
.common-form-wrapper {
    background-color: #fff;
    width: 35%;
    margin: 0 auto;
    padding: 2vw 1vw;
    border: 1px solid rgba(68, 75, 171, 0.1);
}
.success-text {
    color: #177e40;
    font-family: "Poppins-Regular";
    margin-bottom: 1vw;
    font-size: 1vw;
    text-align: center;
}
.error-text {
    color: #d14444;
    font-family: "Poppins-Regular";
    margin-bottom: 1vw;
    font-size: 1vw;
    margin-top: 1vw;
    text-align: center;
}
.common-pwd-error-icon {
    position: absolute;
    left: 26vw;
    color: #d14444;
    visibility: visible;
    margin-top: 0.75vw;
}

.common-pwd-success-icon {
    position: absolute;
    left: 26vw;
    color: #177e40;
    visibility: visible;
    margin-top: 0.75vw;
}

.common-pwd-hide-password {
    position: absolute;
    left: 28vw;
    color: #737171;
    visibility: visible;
    margin-top: 0.75vw;
    font-family: "Poppins-Regular";
    margin-bottom: 1vw;
    font-size: 1vw;
}

.common-pwd-show-password {
    position: absolute;
    left: 28vw;
    color: #737171;
    visibility: visible;
    margin-top: 0.75vw;
    font-family: "Poppins-Regular";
    margin-bottom: 1vw;
    font-size: 1vw;
}
@media (max-width: 600px) {
    .success-text,
    .common-pwd-hide-password,
    .error-text {
        margin-bottom: 2vw;
        font-size: 13px;
    }
    .common-pwd-hide-password {
        left: 92%;
        margin-top: 2vw;
    }
    .common-pwd-show-password {
        left: 92%;
        margin-top: 2vw;
    }

    .common-pwd-error-icon {
        left: 85%;
        margin-top: 2vw;
    }
    .common-pwd-success-icon {
        left: 85%;
        margin-top: 2vw;
    }
    .get-section-title-and-heading-wrapper h3 {
        font-size: 3.5vw;
    }
    .get-section-title-and-heading-wrapper h2 {
        font-size: 5vw;
        padding: 0 10px;
    }
    .get-section-title-and-heading-wrapper p {
        font-size: 14px;
        padding: 20px 40px;
    }
    .custom-accordion-img-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .custom-accordion-img-wrapper img {
        max-width: 60%;
    }
    .common-page-title {
        font-size: 5vw;
        padding: 5vw 0;
    }

    .common-blue-gradient-CTA {
        padding: 3vw 0.5vw;
        font-size: 3.2vw;
        margin-top: 10vw;
        width: 100%;
    }

    .common-input-title {
        font-size: 15px;
        padding-top: 2vw;
    }
}
