.renaissanceContainer {
    position: relative;
    display: inline-block;
    text-align: center;
    cursor: pointer;
}
.renaissanceText {
    text-align: center;
    padding: 1vw 10px 1vw 10px;
    cursor: pointer;
}
.renaissanceProgramTitle {
    font-family: "Gilroy-SemiBold";
    font-style: normal;
    font-weight: 400;
    font-size: 1.111vw;
    line-height: 1.667vw;
    color: #231f20;
}
.interviewPrepLink {
    font-family: "Gilroy-Medium";
    font-style: normal;
    font-weight: 400;
    font-size: 0.764vw;
    line-height: 0.833vw;
    color: #5a74d7;
    text-decoration: none;
}
.hoverBox {
    box-sizing: content-box;
    display: none;
    position: absolute;
    top: 4.5vw;
    left: 0;
    background-color: white;
    border-radius: 0vw 0vw 1.389vw 1.389vw;
    border: 1px solid #e5e5e5;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 1.389vw;
    width: 17.25vw;
    z-index: 1000;
    text-align: left;
}

.renaissanceContainer:hover .hoverBox {
    display: block;
}

/* .hoverBox .customCss {
    font-family: Gilroy-Bold;
    font-size: 1.4vw;
    font-weight: 400;
    line-height: 2.222vw;
    letter-spacing: 0.02em;
} */

.hoverBoxTitle {
    font-family: Gilroy-Bold !important;
    font-size: 1.4vw !important;
    font-weight: 400 !important;
    line-height: 2.222vw !important;
    letter-spacing: 0.02em !important;
}


.hoverBox p {
    font-family: Gilroy-Regular;
    font-size: 1vw;
    font-weight: 400;
    line-height: 1.5vw;
    letter-spacing: 0.02em;
}

.interviewImg {
    margin: 0.833vw auto;
    width: 12.333vw;
}
.buttonDivOne {
    display: flex;
    justify-content: center;
    gap: 0.556vw;
}

.buttonDivTwo {
    display: flex;
    justify-content: center;
    gap: 0.556vw;
    margin: 0.556vw 0vw 0.833vw 0vw;
}

.hoverBoxButtons button {
    font-family: Gilroy-Medium;
    background: #f1f5f9;
    border: 1px solid #64748b;
    border-radius: 2.083vw;
    padding: 0.278vw 0.417vw;
    font-size: 0.694vw;
    line-height: 1.111vw;
    cursor: pointer;
    color: #64748b;
    width: fit-content;
}
.exploreProgramBtn {
    margin: 0 auto;
    display: block;
    width: 16.25vw;
    background: #3940a0;
    color: white;
    border: none;
    padding: 0.556vw 3.33vw;
    font-size: 0.972vw;
    line-height: 1.667vw;
    border-radius: 0.417vw;
    cursor: pointer;
    transition: all 0.3s ease;
}

.exploreProgramBtn:hover {
    background-color: #303693;
    transform: scale(1.05);
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
}

@media only screen and (max-width: 600px) {
    .renaissanceProgramTitle {
        font-family: Gilroy-SemiBold;
        font-size: 4.267vw;
        font-weight: 400;
        line-height: 6.4vw;
        letter-spacing: 0.02em;
        text-align: left;
    }
    .renaissanceText {
        margin-top: 4.333vw;
    }
    .interviewPrepLink {
        font-family: Gilroy-Medium;
        font-size: 2.667vw;
        font-weight: 400;
        line-height: 3.2vw;
        letter-spacing: 0.02em;
        text-align: left;
        margin: 0;
    }
    .hoverBox {
        display: none;
    }
    .renaissanceContainer:hover .hoverBox {
        display: none;
    }
}
