.mentor-detail {
    display: flex;
    flex-direction: column;
    gap: 0px;
}
.underline {
    position: absolute;
    right: 15%;
    top: 90%;
    width: 35%;
}
.mentor-header {
    display: flex;
    align-items: start;
    gap: 20px
}
.mentor-container {
    text-align: center;
    padding: 100px 20px;
    background-color: #181B43;
}

.mentor-desc {
    margin-top: 2px;
    font-size: 20px !important;
    letter-spacing: 0.025rem;
}

.mentor-desc strong {
    font-family: "Gilroy-bold";
    font-weight: 400;
}

.mentor-title {
    font-size: 48px;
    font-weight: bold;
    color: white;
}

.mentors-wrapper {
    font-family: "Gilroy-Regular";
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
    margin-top: 60px;
}

.mentor-section-heading {
    position: relative;
}

.mentor-content {
    background: #F2F4FC;
    border-radius: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 540px;
    text-align: left;
    transition: transform 0.3s ease-in-out;
}


.mentor-image {
    width: 140px;
    height: 156px;
    border-radius: 10%;
    object-fit: cover;
    display: block;
    margin-bottom: 12px;
}

.mentor-naming {
    font-size: 28px;
    line-height: 40px;
    font-family: "Gilroy-semibold";
    color: #020617;
    margin-bottom: 5px;
    letter-spacing: 0.08rem;
}

.mentor-experience,
.mentor-company {
    display: flex;
    align-items: center;
    gap: 8px;
}

.mentor-role {
    font-family: "Gilroy-semibold";
    font-size: 16px;
    color: #020617;
    white-space: nowrap;
}

.linkedin-logo {
    width: 25px;
    cursor: pointer;
}
.mentor-experience {
    margin-top: -5px;
}

@media (max-width: 600px) {
    .mentors-wrapper {
        flex-direction: column;
        align-items: center;
        gap: 8px;
        margin-top: 32px;
    }
    .mentor-content {
        padding: 20px 12px;
    }
    .mentor-title {
        font-size: 24px;
    }
    .underline {
        position: absolute;
        top: 90%;
        right: 5%;
        width: 46%;
    }
    .company-logo {
        width: 25%;
    }
    .ppa_logo {
        width: 60% ;
    }
    .mentor-content {
        width: 100%;
    }
    .mentor-role {
        font-size: 12px;
    }
    .mentor-naming {
        margin-bottom: 0px;
        line-height: 24px;
    }
    .mentor-experience {
        margin-top: 0px;
    }
    .mentor-container {
        padding: 40px 20px;
    }
    .mentor-naming {
        font-size: 20px;
        letter-spacing: 0rem;
    }
    .mentor-image {
        width: 104px;
        height: 116px;
    }
    .mentor-desc {
        font-size: 16px !important;
    }
    .mentor-header{
        gap: 8px;
    }
    .mentor-detail {
        gap: 4px;
    }
}

@media (max-width: 527px) {
    .underline {
        position: absolute;
        top: 96%;
        width: 70%;
        right: 15%;
    }
}