.add-story-close {
    border: none;
    background: none;
    position: relative;
    top: 4.23vh;
    left: 95%;
    cursor: pointer;
}

.add-story-close:active {
    transform: scale(0.98);
}

.add-story-container {
    margin: 2.59vw auto;
    padding: 3.2vw 3.2vw;
    width: 80%;
    background: #fbfbfb;
    display: flex;
    flex-direction: column;
}

.add-story-header {
    width: 16vw;
    height: 5.5vh;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 1.68vw;
    line-height: 150%;
    color: #343a40;
}

.add-story-input-container {
    margin: 1.25rem 0;
    display: flex;
    flex-direction: column;
}

.input-label {
    width: 8.65vw;
    height: 5.07vh;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 1.55vw;
    line-height: 150%;
    color: #343a40;
}

.input-label-image {
    width: 11.79vw;
    height: 5.5vh;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 1.5vw;
    line-height: 150%;
    color: #435baf;
    cursor: pointer;
    margin-bottom: -0.625rem;
}

input[type="file"] {
    display: none;
}

.file-limit {
    width: 8.5vw;
    height: 2.9vh;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 0.91vw;
    line-height: 1.37vw;
    text-align: center;
    color: #343a40;
}

.input-box {
    width: 75.52vw;
    height: 7.1vh;
    background: #ffffff;
    border: 1px solid rgba(68, 75, 171, 0.1);
    box-sizing: border-box;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 1.42vw;
    line-height: 150%;
    color: #343a40;
    padding-left: 1.29vw;
}

.input-textarea {
    height: 24vh;
    padding-right: 1.25rem;
}

.add-story-char-counter {
    margin-top: 1vh;
    margin-right: 1vw;
    width: 5.37vw;
    height: 3,4vh;
    align-self: flex-end;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1.16vw;
    line-height: 2.13vw;
    color: #AAAAAA;
}

.add-story-reset {
    position: relative;
    cursor: pointer;
    top: 1.41vh;
    left: 63%;
    margin-bottom: 3.5%;
    box-sizing: border-box;
    width: 15.09vw;
    height: 6.3vh;
    background-color: white;
    border: 1px solid #4260b0;
    border-radius: 6px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 1.55vw;
    line-height: 149.8%;
    color: #416EB4;
}

.add-story-submit {
    position: relative;
    top: 1.41vh;
    cursor: pointer;
    left: 65%;
    margin-bottom: 3.5%;
    box-sizing: border-box;
    width: 13.2vw;
    height: 6.3vh;
    background: linear-gradient(93.44deg, #444bab -6.8%, #3ca5c2 186.94%);
    border-radius: 6px;
    border: none;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 1.55vw;
    line-height: 149.8%;
    color: #ffffff;
}

.input-label-image-uploaded {
    width: 12.9vw;
    height: 5vh;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 1.55vw;
    line-height: 150%;
    color: #343a40;
    cursor: pointer;
}

.attached-icon {
    width: 13.88vw;
    height: 16vh;
    background: #ffffff;
    border: 1px solid rgba(68, 75, 171, 0.1);
    box-sizing: border-box;
    cursor: pointer;
}

@media screen and (max-width: 1280px) {
    .add-story-container {
        width: 62.22vw;
    }
    .input-box {
        width: 55.67vw;
        height: 6vh;
    }
    .input-textarea {
        height: 20vh;
    }
    .add-story-reset {
        width: 12vw;
        font-size: 1.14vw;
    }
    .add-story-submit {
        width: 11.79vw;
        font-size: 1.34vw;
    }
}

@media screen and (max-width: 1024px) {
    .add-story-container {
        width: 49vw;
    }
    .input-box {
        width: 42.5vw;
        height: 5vh;
    }
    .input-textarea {
        height: 15vh;
    }
    .add-story-reset {
        width: 10vw;
        font-size: 1.14vw;
    }
    .add-story-submit {
        width: 8.5vw;
        font-size: 1.14vw;
    }
}

@media screen and (max-width: 767px) {
    .add-story-container {
        width: 36vw;
    }
    .input-box {
        width: 29.47vw;
        height: 2vh;
    }
    .input-textarea {
        height: 10vh;
    }
    .add-story-submit {
        width: 5.8vw;
        font-size: 0.93vw;
    }
}