.course-includes-wrapper1,
.course-includes-wrapper2 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 4vw;
    margin-bottom: 6vw;
}

.course-includes-title {
    margin-left: 89px;
    margin-bottom: 20px;
    font-family: "Poppins-SemiBold";
    color: #4f516b;
    font-size: 2.1vw;
}

.course-included-img-section {
    width: 51%;
    display: flex;
    justify-content: center;
}

.course-included-container {
    width: 45%;
}
.course-included-dropdown-img {
    width: 85%;
}
@media (max-width: 600px) {
    .course-includes-wrapper-mobile {
        margin: 0 20px;
    }
}
