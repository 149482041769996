.ren-whylearn-wrapper {
    display: flex;
    padding: 2vw 6vw;
    margin-bottom: 2vw;
    background-color: #E8EDFB;
    align-items: center;
    justify-content: space-between;
}
.whylearn-content {
    display: flex;
    flex-direction: column;
    width: 33vw;
    padding: 1vw 2vw;
}
.whylearn-content h2 {
    font-family: Poppins-Semibold;
    color: #4D4D4D;
    font-size: 2.4vw;
    margin-bottom: 1vw;
}
.whylearn-content h3 {
    font-family: Poppins-Regular;
    color: #191D23;
    font-size: 1.5vw;
    margin-bottom: 2vw;
    font-weight: 400;
}
.whylearn-illustrator {
    height: 27vw;
    width: 22vw;
}
.whylearn-points-wrapper {
    display: flex;
    flex-wrap: wrap;
    column-gap: 3vw;
    padding: 1vw 2vw;
    width: 50vw;
}
.whylearn-points {
    display: flex;
    width: 45%;
    padding: 1vw;
}
.whylearn-icons {
    padding-top: 0.2vw;
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 1vw;
}
.whylearn-title {
    font-family: Poppins-Semibold;
    font-size: 1.4vw;
    padding-bottom: 0.3vw;
    color: #4D4D4D;
}
.whylearn-description {
    color: #2A2A2A;
    font-family: Poppins-Regular;
    font-size: 1.1vw;
}

@media (max-width: 600px) {
    .ren-whylearn-wrapper {
        flex-direction: column;
        padding: 4vw 2vw;
        justify-content: center;
        background-color: #fff;
    }
    .whylearn-illustrator {
        display: none;
    }
    br {
        display: none;
    }
    .whylearn-content {
        width: 100%;
        justify-content: center;
        align-items: center;
        padding-top: 4vw;
    }
    .whylearn-content h2 {
        font-size: 5.5vw;
    }
    .whylearn-content h3 {
        font-size: 4vw;
        padding-bottom: 2vw;
    }
    .whylearn-points-wrapper {
        width: 100%;
        justify-content: space-between;
        row-gap: 3vw;
    }
    .whylearn-points {
        width: 47%;
    }
    .whylearn-title {
        font-size: 3.5vw;
        padding-bottom: 1.5vw;
    }
    .whylearn-description {
        font-size: 3vw;
    }
    .whylearn-icons {
        height: 1.5rem;
        width: 1.5rem;
    }
}
