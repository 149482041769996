.springboot-impact {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
    background: #020617;
    color: white;
    padding: 60px 64px;
    font-family: Gilroy-Bold;
    font-size: 40px;
    font-weight: 400;
    line-height: 52px;
    letter-spacing: 0.04em;
    text-align: center;
}

.springboot-impact .springboot-impact-rating-container {
    display: flex;
    flex-direction: column;
    gap: 1px;
    font-family: Gilroy-Bold;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.02em;
    text-align: center;
}

.springboot-impact .springboot-impact-horizontal-line {
    height: 80px;
    border: 1px solid #cbd5e1;
}

.springboot-impact .springboot-impact-paragraph {
    font-family: Gilroy-Bold;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.02em;
    text-align: center;
    color: #94a3b8;
}

.springboot-impact .springboot-impact-sub-div {
    display: flex;
    gap: 40px;
}

@media (max-width: 600px) {
    .springboot-impact .springboot-impact-horizontal-line {
        display: none;
    }

    .springboot-impact {
        flex-direction: column;
        font-family: Gilroy-Bold;
        font-size: 24px;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0.02em;
        text-align: left;
        padding: 20px;
        gap: 16px;
    }

    .springboot-impact .springboot-impact-paragraph {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: center;
    }
}
