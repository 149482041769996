.error-message {
    color:#EF4444;
    font-size: 0.8rem;
}

.springboot-intro-desktop {
    padding: 60px 64px;
    background-color: #F2F4FC;
}

.springboot-intro-desktop .springboot-intro-recommend {
    padding: 12px 32px;
    background: #F2F4FC;
    color: #020617;
    font-family: Gilroy-Bold;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: left;
    width: max-content;
    border-radius: 6px;
    text-wrap: nowrap;
    margin-bottom: 8px;
}

.springboot-intro-desktop .springboot-intro-banner {
    display: flex;
    gap: 70px;
}

.springboot-intro-desktop .springboot-intro-heading {
    font-family: Gilroy-Bold;
    font-size: 48px;
    font-weight: 400;
    line-height: 64px;
    letter-spacing: 0.04em;
    text-align: left;
    color: #3940a0;
}

.springboot-intro-desktop .springboot-intro-description {
    color: #0f172a;
    font-family: Gilroy-Medium;
    font-size: 24px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.02em;
    text-align: left;
    margin-bottom: 32px;
    margin-top: 1.5vw;
}

.springboot-intro-desktop .springboot-intro-carousel-parent {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.springboot-intro-desktop .springboot-intro-carousel {
    width: 500px;
}

.springboot-intro-desktop .springboot-intro-carousel-box {
    width: 100px !important;
    height: 100px;
    background: red;
}

.springboot-intro-desktop .springboot-intro-carousel-card {
    background: #f1f5f9;
    border-radius: 10px;
    padding: 24px;
}

.springboot-intro-desktop .springboot-intro-list-element {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    gap: 8px;
}

.springboot-intro-desktop .springboot-intro-list-element p {
    font-family: Gilroy-Regular;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #020617;
}

.springboot-intro-desktop .springboot-intro-card {
    display: flex;
    flex-direction: column;
    background: #f1f5f9;
    padding: 24px;
    border-radius: 10px;
    gap: 20px;
    height: 100%;
}

.springboot-intro-desktop .springboot-intro-card .springboot-intro-image-section {
    border-radius: 12px;
    background: #ffffff;
    height: 275px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.springboot-intro-desktop
    .springboot-intro-card
    .springboot-intro-information-box
    .springboot-intro-card-heading {
    font-family: Gilroy-Bold;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.02em;
    text-align: left;
}

.springboot-intro-desktop
    .springboot-intro-card
    .springboot-intro-information-box
    .springboot-intro-card-paragraph {
    font-family: Gilroy-Regular;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: left;
    margin-top: 4px;
}

.springboot-intro-desktop
    .springboot-intro-card
    .springboot-intro-information-box
    .springboot-intro-tags-container {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin-top: 12px;
}

.springboot-intro-desktop
    .springboot-intro-card
    .springboot-intro-information-box
    .springboot-intro-tag {
    background: #ffffff;
    padding: 8px 20px;
    font-family: Gilroy-Regular;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-align: left;
    border-radius: 5px;
    white-space: nowrap;
}

.springboot-intro-desktop .springboot-intro-carousel-parent .slick-list {
    margin-left: 2vw;
    margin-right: 1vw;
}

.springboot-intro-desktop .springboot-intro-button-container {
    width: fit-content;
}

.springboot-intro-desktop .springboot-intro-free-trial-button {
    background: #3940a0;
    font-family: Gilroy-Bold;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: left;
    text-transform: capitalize;
    padding: 16px 32px;
    border-radius: 10px;
    /* margin-top: 12px; */
    width: 100%;
}

.springboot-intro-desktop
    .springboot-intro-button-container
    .springboot-intro-after-button-description {
    font-family: Gilroy-Regular;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: left;
    margin-top: 12px;
}

.springboot-intro-desktop
    .springboot-intro-button-container
    .springboot-intro-after-button-description
    span {
    font-family: Gilroy-Bold;
}

.springboot-intro-desktop .springboot-intro-carousel-parent.mobile {
    display: none;
}

.springboot-intro-desktop .springboot-intro-carousel .slick-slide > div {
    height: 100%;
}

.springboot-intro-desktop .springboot-intro-carousel .slick-slide {
    height: 590px;
}

@media (max-width: 600px) {
    .springboot-intro-desktop {
        padding: 20px;
    }
    .springboot-intro-desktop .springboot-intro-banner {
        display: flex;
        gap: 0px;
    }

    .springboot-intro-desktop .springboot-intro-recommend {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.01em;
        text-align: left;
        background: transparent;
        padding: 0px;
        margin-bottom: 4px;
    }

    .springboot-intro-desktop .springboot-intro-banner .springboot-intro-heading {
        font-size: 24px;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0.02em;
        /* margin-top: 8px; */
    }

    .springboot-intro-desktop .springboot-intro-banner .springboot-intro-description {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        margin-top: 4px;
        margin-bottom: 20px;
    }

    .springboot-intro-desktop .springboot-intro-banner .springboot-intro-list-element p {
        font-family: Gilroy-Regular;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.02em;
        text-align: left;
    }

    .springboot-intro-desktop .springboot-intro-banner {
        flex-direction: column;
    }

    .springboot-intro-desktop .springboot-intro-carousel-parent.mobile {
        margin-bottom: 10px;
    }
    .springboot-intro-desktop .springboot-intro-carousel {
        width: 350px;
        margin-bottom: 50px;
    }

    .springboot-intro-desktop .springboot-intro-carousel-parent.mobile {
        display: flex;
    }

    .springboot-intro-desktop .springboot-intro-carousel-parent.desktop {
        display: none;
    }

    .springboot-intro-desktop .springboot-intro-card {
        padding: 16px;
    }

    .springboot-intro-desktop .springboot-intro-card .springboot-intro-image-section {
        height: 250px;
    }

    .springboot-intro-desktop
        .springboot-intro-card
        .springboot-intro-information-box
        .springboot-intro-card-heading {
        font-size: 20px;
        line-height: 28px;
    }

    .springboot-intro-desktop
        .springboot-intro-card
        .springboot-intro-information-box
        .springboot-intro-card-paragraph {
        font-size: 14px;
        line-height: 20px;
    }

    .springboot-intro-desktop
        .springboot-intro-card
        .springboot-intro-information-box
        .springboot-intro-tag {
        font-size: 12px;
        line-height: 16px;
    }

    .springboot-intro-desktop .springboot-intro-free-trial-button {
        width: 100%;
    }

    .springboot-intro-desktop .springboot-intro-carousel .slick-next {
        top: 105%;
        right: calc(40vw - 5px);
        width: 12px;
    }

    .springboot-intro-desktop .springboot-intro-carousel .slick-prev {
        top: 105%;
        left: calc(40vw - 5px);
        width: 12px;
    }
}

.close-button{
    position: absolute;
    top: 5%;
    right: 5%;
    cursor: pointer;
    font-size: 2.5vw;
}

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 20;
}

.popup-content {
    position: relative;
    background: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
}

.popup-content h2 {
    margin-bottom: 10px;
}

.popup-content button {
    padding: 12px 32px;
    border: none;
    background: #007bff;
    color: white;
    cursor: pointer;
    border-radius: 12px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.02em;
    background-color: #3940A0;
    margin-top: 1rem;
}


.springboot-intro-desktop {
    width: 100%;
    display: flex;
    justify-content: center;
}

.springboot-intro-banner {
    display: flex;
    width: 100%;
    gap: 20px;
    align-items: center;
}

.springboot-intro-content {
    flex: 1.3; 
    padding: 20px;
}

.consultation-container {
    flex: 0.7; 
    height: auto;
    border-radius: 20px;
    padding: 20px 24px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: white;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

/* Mobile View */
@media (max-width: 768px) {
    .springboot-intro-banner {
        flex-direction: column;
    }

    .consultation-container {
        width: 100% !important;
    }

    .springboot-intro-content {
        padding: 0px !important;
    }
}

@media (max-width: 600px) {
    .tags-container {
        display: none !important; 
    }
    .springboot-intro-content {
        padding: 0px !important;
    }
    .consultation-container {
        padding: 20px 12px !important;
    }
    .consultation-title {
        font-size: 24px !important;
    }
    .trial-info {
        font-size: 14px !important;
    }
}


.tags-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.tag {
    height: 48px;
    gap: 8px;
    border-radius: 32px;
    padding: 12px 24px;
    border-width: 1px;
    background-color: #F1F5F9;
    border: 1px solid #CBD5E1;
    color: #333;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
}

.consultation-container {
    width: 50%;
    border-radius: 20px;
    padding: 20px 24px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: white;
}

.consultation-title {
    font-family: 'Gilroy-Bold', sans-serif;
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 4%;
}

.consultation-title span {
    color: #EF4444;
}

.form-label {
    font-family: 'Gilroy-SemiBold', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.32px; 
}

.consultation-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
}

.form-label {
    display: flex;
    flex-direction: column;
}

.form-input {
    font-family: 'Gilroy-Regular', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.32px;
    height: 40px;
    gap: 8px;
    border-radius: 6px;
    padding: 8px 20px;
    border-width: 1px;
    background-color: #F8FAFC;
    border: 1px solid #E1E7F8;
}

.info-text {
    font-family: 'Gilroy-Medium', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.28px; 
    color: #64748B;
}

.form-input::placeholder {
    color: #64748B; 
}

.button-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-items: center;
    align-items: center;
}
.primary-button {
    font-family: "Gilroy-Bold", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 2%;
    width: 100%;
    height: 48px;
    gap: 8px;
    border-radius: 10px;
    padding: 12px 8px;
    border: none;
    background-color: #FCD34D;
    cursor: pointer;
}

.secondary-button {
    font-family: "Gilroy-Bold", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 2%;
    width: 100%;
    height: 48px;
    gap: 8px;
    border-radius: 10px;
    padding: 12px 8px;
    border: 1px solid #3940A0;
    color: #3940A0;
    background-color: white;
    cursor: pointer;
}

.trial-info {
    font-family: "Gilroy-Regular", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.32px; 
}

.trial-info span {
    font-family: "Gilroy-Bold", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.32px; 
}

.react-tel-input .form-control {
    width: 100%;
    height: 40px;
    background-color: #F8FAFC;
}



