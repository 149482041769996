.holi-banner-wrapper {
    font-family: 'Gilroy-Bold';
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    text-align: center;
    color: #000000;
    background: radial-gradient(50% 50% at 50% 50%, #FFF589 0%, #FDD512 100%);
    position: relative;
    border-bottom: 1px solid #3940A0 ;
}

.holi-banner-content {
    font-family: 'Gilroy-Bold';
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 1px;
    gap: 40px;
    padding: 5px 0px;
}

.holi-banner-wrapper span {
    color: #EF4444;
    font-weight: bold;
    margin: 0px 5px;
}

.holi-banner-wrapper button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Gilroy-Bold';
    width: 125px;
    height: 40px;
    gap: 8px;
    padding: 10px 24px;
    border-radius: 10px;
    background: #000000;
    color: #FFFFFF;
    border: none;
    cursor: pointer;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 2%;
    white-space: nowrap;
}

.image3{
    display: none;
}

@media (max-width: 600px) {
    .holi-banner-wrapper  {
        gap: 5px;
        padding: 4px 0px;
        padding-right: 10px !important;
    }
    .image1 {
        display: none;
    }
    .image2 {
        display: none;
    }
    .image3{
        display: block;
        position: absolute;
        top: 0%;
    }
    .holi-offer-text {
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 1px;
        margin-left: 50px;
        margin-top: 12px;
        margin-bottom: 12px;
        margin-right: 12px;
    }
    .holi-banner-wrapper button {
        width: 164px;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 1px;
        padding: 15px;
    }
    .holi-banner-content {
        font-family: "Gilroy-SemiBold";
        gap: 5px;
    }
    .copy-icon {
        height: 14px;
        width: auto;
    }
}
