.cpp-curriculum-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;    
    align-items: center;
    margin: 6vw 5vw 5vw 5vw;
}
.cpp-curriculum-wrapper h2 {
    font-family: "Poppins-Regular";
    font-weight: 600;
    font-size: 3.1vw;
    text-align: center;
    color: #231F20;
    margin-bottom: 3vw;
}
.cpp-topics-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.cpp-topic-card {
    width: 65vw;
    display: flex;
    flex-direction: column;
    border-radius: 0.7vw;
    padding: 0.8vw 1.5vw 0.4vw 1.5vw;
    margin-bottom: 1.5vw;
    border: 1px solid #B8C0CC;
    background: #F7F8F9;
    cursor: pointer;
}
.cpp-topic-card-heading-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.5vw 1.5vw;
    margin-bottom: 0.5vw;
}
.cpp-topic-card-heading-wrapper span {
    color: #231F20;
    font-family: "Poppins-Medium";
    font-weight: 500;
    font-size: 1.1vw;
    padding: 0.5vw 1.5vw;
    height: fit-content;
    border-radius: 2vw;
    background:#E7EAEE;
}
.cpp-topic-card-heading {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.cpp-topic-card-heading h3 {
    padding: 0.6vw 0.5vw;
    font-family: "Poppins-SemiBold";
    font-weight: 500;
    font-size: 1.2vw;
    color: #231F20;
    margin-bottom: 0.1vw;
}
.cpp-topic-card-heading img {
    height: 1.3vw;
}
.cpp-subtopics-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.5vw 1.5vw;
}
.cpp-line-break {
    border-top: 1px solid #E7EAEE;
}
.cpp-subtopics-wrapper > img {
    height: 2vw;
    width: 2vw;
    margin-right: 1.5vw;
}
.cpp-subtopics-wrapper h4 {
    padding: 0.2vw 0.5vw;
    font-family: "Poppins-Regular";
    font-weight: 400;
    font-size: 1.1vw;
    color: #231F20;
}
.see-more {
    font-family: "Poppins-SemiBold";
    font-size: 1.1vw;
    color: #444bab;
    margin-bottom: 1vw;
    cursor: pointer;
}
.arrow-down {
    position: relative;
    top: 0.6vw;
    cursor: pointer;
    border-left: 0.4vw solid transparent;
    border-right: 0.4vw solid transparent;
    border-top: 0.6vw solid #444bab;
    margin-left: 1vw;
}
.arrow-up {
    position: relative;
    bottom: 1.5vw;
    cursor: pointer;
    border-left: 0.4vw solid transparent;
    border-right: 0.4vw solid transparent;
    border-bottom: 0.6vw solid #444bab;
    margin-left: 1vw;
}
.cpp-download-curriculum {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 3px solid rgba(68, 75, 171, 0.7);
    padding: 0.7vw 1.8vw;
    margin-top: 1vw;
    cursor: pointer;
    width: fit-content;
}
.cpp-download-curriculum img {
    width: 1.7vw;
    height: 1.7vw;
}
.cpp-download-curriculum p {
    font-family: Poppins-SemiBold;
    color: #343A40;
    font-size: 1.2vw;
    padding: 0vw 1vw;
}
@media (max-width: 600px) {
    .cpp-curriculum-wrapper {
        margin: 2vw 0;
        padding: 0 5vw;
    }
    .cpp-curriculum-wrapper h2 {
        font-size: 4.75vw
    }
    .cpp-topics-wrapper
    .cpp-topic-card {
        margin-bottom: 20px;
        width: 90vw;
    }
    .cpp-topic-card-heading {
        font-size: 16px;
        padding: 2vw;
        margin: 1vw;
    }
    .cpp-topic-card-heading-wrapper {
        padding: 0.5vw 1vw;
        margin-bottom: 0;
    }
    .cpp-topic-card-heading-wrapper p {
        font-size: 3.4vw;
    }
    .cpp-topic-card-heading img {
        height: 3.5vw;
    }
    .cpp-topic-card-heading-wrapper span {
        font-size: 3vw;
        margin: 1vw;
    }
    .cpp-subtopics-wrapper {
        padding: 0.75vw 5vw;
    }
    .cpp-subtopics-wrapper > img {
        height: 20px;
        width: 20px;
        margin-right: 15px;
    }
    .cpp-subtopics-wrapper h4 {
        font-size: 3.2vw;
    }
    .see-more {
        font-size: 14px;
    }
    .arrow-down {
        top: 8px;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 8px solid #444bab;
    }
    .arrow-up {
        top: 8px;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 8px solid #444bab;
        margin-bottom: 28px;
    }
    .curriculum-wrapper {
        margin-top: 10vw;
    }
    .cpp-download-curriculum {
        padding: 1.5vw 2vw;
        border-radius: 4px;
        margin-bottom: 7vw;
        border: 2px solid rgba(68, 75, 171, 0.7);
        align-items: center;
    }
    .cpp-download-curriculum img {
        width: 1.1rem;
        height: 1.1rem;
    }
    .cpp-download-curriculum p {
        font-size: 2.75vw;
        padding: 0vw 2.75vw;
    }
}
