@media (max-width: 600px) {
    .success-stories-card-wrapper {
        margin-top: 20px;
    }
    .success-stories-slider-card {
        background: #fff;
        border-radius: 3.6vw;
        display: flex;
        flex-direction: column;
    }

    .success-stories-slider-card .slick-list {
        margin-left: 8vw;
        margin-right: 10vw;
    }

    .success-stories-slider-card .quotes-img-wrapper {
        margin-top: 3.2vw;
        display: flex;
        justify-content: center;
    }

    .success-stories-slider-card .quotes {
        margin-top: 5.3vw;
        height: 1rem;
        margin-bottom: 2vw;
        width: 1.5rem;
    }
    .quotes img {
        width: 100%;
    }
    .success-stories-slider-card-img-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .success-stories-slider-card-img-wrapper img {
        height: 8rem;
        margin: 2vw 0;
        width: 8rem;
    }
    .success-stories-slider-card-desc {
        height: 72vw;
        font-size: 4vw;
        padding: 3.1vw;
        text-align: center;
        font-family: Poppins-Regular;
        text-overflow: ellipsis;
    }

    .bottom-wrapper {
        padding-bottom: 3vw;
    }

    .success-stories-slider-card .success-stories-slider-name-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .success-stories-slider-name {
        font-family: Poppins-SemiBold;
        font-size: 4.2vw;
        margin-right: 0.5vw;
    }

    .success-stories-slider-name-wrapper img {
        height: 1.5rem;
        margin: 2vw 0;
        width: 1.5rem;
    }

    .success-stories-slider-card .success-stories-slider-company-logo-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 3vw;
        gap: 8px;
    }

    .sde-slider-text {
        font-size: 3.5vw;
        font-weight: 600;
    }

    .success-stories-card-wrapper .slick-arrow {
        width: 10.6vw;
        height: 10.6vw;
        margin-left: 37vw;
        margin-right: 35vw;
        top: 110%;
    }

    .slick-prev {
        left: -20px;
    }
    .slick-next {
        right: -20px;
    }
}
