.banner-container {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.banner-content-container {
    display: flex;
    align-items: center;
    gap: 40px;
    padding: 20px 16px;
}

.claim-button {
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
    font-family: "Gilroy-Bold";
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    padding: 10px 24px;
    border-radius: 10px;
    white-space: nowrap;
    text-align: center;
    border: none;
    cursor: pointer;
    position: relative;
    z-index: 1;
    min-width: 8.5rem;
}

.banner-content {
    font-family: "Gilroy-Bold";
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
}

.mobile-left-image {
    display: none;
}

.left-image,
.right-image {
    display: block;
}

@media (max-width: 900px) {
    .right-image,
    .left-image {
        display: none;
    }
    .banner-content-container {
        gap: 15px;
        margin-left: 100px;
        padding: 10px 16px;
    }
    .banner-content {
        font-size: 16px;
    }
    .claim-button {
        font-size: 14px;
        padding: 10px;
    }
}

@media (max-width: 600px) {
    .left-image,
    .right-image {
        display: none;
    }

    .mobile-left-image {
        display: block !important;
        position: absolute;
    }

    .claim-button {
        font-size: 12px;
        line-height: 14px;
        padding: 10px;
        min-width: 5.9rem;
    }

    .banner-content {
        font-family: "Gilroy-SemiBold";
        font-size: 13px;
        line-height: 18px;
    }

    .banner-content-container {
        gap: 16px;
        padding: 12px 16px;
    }
}
