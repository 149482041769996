.ren-faq-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 3vw 6vw;
    margin: 2vw 0;
}
.ren-faq-wrapper h2 {
    font-family: Poppins-Medium;
    font-weight: 600;
    font-size: 2.75vw;
    text-align: center;
    padding: 2vw;
}
.ren-accordion-wrapper {
    cursor: pointer;
}
.ren-accordion-background {
    background-color: #f3f4ff;
}
.ren-accordion-item {
    background-color: #fff;
    padding: 1.4vw 1vw 0.8vw 1vw;
    border-bottom: 1px solid #D0D5DD;

}
.ren-accordion-question {
    display: flex;
    justify-content: space-between;
}
.ren-accordion-question h3 {
    color: #000000;
    font-family: Poppins-Regular;
    font-weight: 600;
    font-size: 1.3vw;
}
.ren-accordion-question img {
    width: 1rem;
    height: 1rem;
    margin-top: 1vw;
    cursor: pointer;
}
.ren-accodrion-content {
    color: #272834;
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0, 1, 0, 1);
    font-family: Poppins-Regular;
    font-weight: 400;
    font-size: 1.1vw;
    padding-top: 1vw;
}
.ren-accodrion-content.ren-accodrion-show {
    padding-top: 0.5vw;
    height: auto;
    max-height: 9999px;
    transition: all 1.2s cubic-bezier(0, 1, 0, 1);
}
.renaissance-link {
    color: #444bab;
    font-family: Poppins-Regular;
    font-weight: 600;
}
@media (max-width: 600px) {
    .ren-faq-wrapper {
        padding: 3vw 5vw;
        margin-bottom: 10vw;
    }
    .ren-faq-wrapper h2 {
        font-size: 5.5vw;
    }
    .ren-accordion-question h3 {
        font-size: 3.5vw;
    }
    .ren-accodrion-content {
        font-size: 3.5vw;
    }
    .ren-accordion-item {
        padding: 3vw 2vw 2vw 2vw;
    }
    .ren-accodrion-content.ren-accodrion-show {
        padding-top: 2.5vw;
    }
}
