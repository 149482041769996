.audience-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 5vw;
}
.audience-wrapper h2 {
    text-align: center;
    font-size: 2.5vw;
    font-family: Poppins-SemiBold;
    color: #000000;
}
.audience-card-wrapper {
    display: flex;
    flex-wrap: wrap;
    padding: 2vw;
}
.audience-heading {
    text-align: center !important;
    font-size: 7.5vw !important;
    font-family: Poppins-SemiBold !important;
    color: #000000 !important;
    margin-bottom: 2vw !important;
}
.audience-card {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    padding: 2vw;
}
.audience-img {
    width: 25%;
}
.audience-img img {
    width: 100%;
}
.audience-card-data {
    display: flex;
    flex-direction: column;
    padding-left: 1vw;
    width: 50%;
}
.audience-card-data p {
    font-family: Poppins-SemiBold;
    font-size: 1.3vw;
    padding-bottom: 0.5vw;
}
.audience-card-data span {
    font-family: Poppins-Regular;
    font-size: 1.1vw;
}

@media (max-width: 600px) {
    .audience-wrapper {
        margin-bottom: 15vw;
    }
    .audience-wrapper h2 {
        font-size: 5.4vw;
    }
    .audience-card-wrapper {
        padding: 0vw;
    }
    .audience-card {
        width: 100%;
        padding: 2vw 0vw 2vw 0vw;
    }

    .audience-img {
        width: 20%;
    }
    .audience-card-data p {
        font-size: 4vw;
    }

    .audience-card-data span {
        font-size: 3vw;
    }
    .audience-card-data {
        width: 60%;
        margin-left: 4vw;
    }
}
