.instructors-wrapper {
  margin-top: 4vw;
}
.intro-illustor-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
}

.intro-illustor-wrapper img {
  width: 100%;
}
.ashutosh-img-intro,
.srikanth-img-intro {
  float: right;
}
.intro-card-vivek-border {
  position: absolute;
  bottom: -30px;
  left: 41px;
  border-radius: 17px 0px;
}

.intro-card-bharat-border {
  position: absolute;
  bottom: -45px;
  right: 43px;
  border-radius: 17px 17px 0px 17px;
}

.intro-section-email-wrapper {
  margin-top: 2vw;
}
.intro-talk-to-us-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2vw;
  background: #50cc5d;
  cursor: pointer;
  width: 11vw;
  padding: 0.8vw 1vw;
  position: fixed;
  right: 2vw;
  bottom: 2vw;
  z-index: 1;
}
.intro-talk-to-us-btn p {
  color: #ffffff;
  font-family: Poppins-Regular;
  font-size: 1.1vw;
  margin-right: 0.5vw;
}
.intro-talk-to-us-btn img {
  width: 1.5rem;
  height: 1.5rem;
}
.free-trail {
  font-family: "Poppins-Medium";
  color: #272834;
  font-size: 0.9vw;
  margin-top: 0.7vw;
}

.intro-words {
  width: 48%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.illustrator {
  width: 50vw;
}

.intro-para-illustrator-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 3vw 2vw 5vw 7vw;
  background: url(./../../../img/intro-bg.webp);
  background-repeat: no-repeat;
  background-size: cover;
}

.intro-list img {
  padding-right: 10px;
  height: 1.2vw;
  width: 1.7vw;
}

.introListMockInterview img {
  padding-right: 12px;
}

.introListPerformance img {
  padding-right: 15px;
}

.intro-illustrator-mobile {
    display: none;
}

.intro-list {
  display: flex;
  font-family: "Poppins-Regular";
  color: rgba(0, 0, 0, 0.8);
  padding-bottom: 0.6vw;
  font-size: 1vw;
}

.intro-email-submit-button {
  width: 150px;
  height: 35px;
  background: linear-gradient(
    93.44deg,
    #444bab -6.8%,
    #3e8ebc 96.13%,
    #3ca5c2 186.94%
  );
  border-radius: 4px;
  font-family: "Poppins-normal";
  color: #ffffff;
  border: none;
  position: absolute;
  left: 375px;
  top: 7px;
  cursor: pointer;
}
.intro-email-loader {
  position: absolute;
  right: 30px;
  top: 10px;
  cursor: auto;
}
.intro-section-email-form {
  position: relative;
}
.intro-email-input {
  width: 535px;
  height: 50px;
  background: #faf8f8;
  border: 1px solid #ebecff;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 11px 11px;
  font-family: "Poppins-Regular";
  font-size: 1vw;
  outline-color: #e7e7ef;
}

.renaissance-heading {
  font-family: "Poppins-Medium";
  font-weight: 600;
  font-size: 2.3vw;
  color: #01004c;
}

.renaissance-caption {
  display: inline;
  color: #231f20;
  font-family: "Poppins-Medium";
  font-weight: 600;
  font-size: 2.3vw;
}

.renaissance-caption-subheading {
  font-family: "Poppins-Medium";
  color: #000000;
  padding: 1.5vw 0;
  font-size: 1.4vw;
  font-weight: 400;
}

.requirementsContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.time-and-requirement-wrapper {
  display: flex;
  padding: 1vw 0;
}
.time-and-requirement-wrapper > * + * {
  margin-left: 50px;
}

.requirement-heading {
  font-family: "Poppins-Light";
  color: #444bab;
  font-size: 1vw;
  margin-bottom: 0;
}

.requirement-para {
  color: #191a15;
  font-size: 1vw;
  font-family: "Poppins-Regular";
  padding-top: 0.2vw;
  font-weight: 400;
}

.introButtonsWapper {
  display: flex;
  justify-content: space-between;
}

.illustratorImg {
  width: 35vw;
  height: auto;
}

.introduction-text {
  position: relative;
}
.intro-list-para {
  font-size: 0.9vw;
}
.intro-input-field-wrapper {
  padding: 1.5vw 0;
  cursor: pointer;
  display: inline-block;
}
@media (max-width: 600px) {
  br {
    display: none;
  }
  .intro-words {
    width: 100%;
    padding: 0;
  }
  .illustrator {
    margin-top: 30px;
    width: 100%;
  }
  .intro-para-illustrator-wrapper {
    text-align: center;
    max-width: 600px;
    padding: 4vw 4vw;
    display: block;
    background: url("../../../img/intro-bg-mob.webp");
  }
  .intro-list-wrapper {
    max-width: 600px;
    margin: 0 auto;
    width: 100%;
  }
  .intro-list {
    align-items: center;
    font-size: 10px;
    margin: 0 2vw;
    text-align: left;
  }
  .intro-list img {
    height: 1.5rem;
    width: 2rem;
  }
  .renaissance-heading {
    font-size: 24px;
    margin-bottom: 4vw;
    margin-top: 3vw;
    text-align: left;
  }
  .renaissance-caption-subheading {
    font-size: 18px;
    margin-bottom: 3vw;
    text-align: left;
    font-family: Poppins-Regular;
  }
  .renaissance-caption {
    font-size: 24px;
    margin-bottom: 0;
  }
  .time-and-requirement-wrapper {
    justify-content: center;
    padding: 2vw 0;
  }
  .requirement-heading,
  .requirement-para {
    font-size: 15px;
    padding-bottom: 4vw;
  }
  .free-trail {
    margin-top: 2.5vw;
    font-size: 3.5vw;
  }
  .action-button-wrapper {
    display: flex;
    flex-wrap: wrap;
  }
  .chat-with-expert {
    width: 30%;
    font-size: 12px;
    padding: 10px 15px;
  }
  .intro-illustor-wrapper {
    align-items: center;
    display: flex;
  }
  .intro-illustor-wrapper img {
    display: none;
  }
  .intro-illustrator-mobile {
    display: block;
  }
  .intro-illustrator-mobile img {
    width: 100%;
    padding-bottom: 3vw;
  }
  .yagna-img-intro {
    float: left;
  }
  .intro-talk-to-us-btn {
    display: none;
  }
  .intro-list-para {
    font-size: 16px;
    margin-bottom: 10px;
  }
}
