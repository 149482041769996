.dsa-community-wrapper {
    display: flex;
    background-color: #f7f8f9;
    margin: 4vw 0;
    padding: 2vw 4vw;
    align-items: center;
    justify-content: space-evenly;
}

.dsa-community-content {
    padding: 4vw 0.5vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.dsa-community-content h2 {
    font-family: Poppins-Semibold;
    font-weight: 500;
    color: #231F20;
    font-size: 2.3vw;
    padding-bottom: 1vw;
}

.dsa-community-content p {
    color: #231F20;
    font-family: Poppins-Regular;
    font-weight: 400;
    font-size: 1.25vw;
    padding-bottom: 2vw;
    line-height: 2vw;
}

.dsa-join-community-btn {
    display: flex;
    width: fit-content;
    justify-content: center;
    align-items: center;
    align-self: center;
    border: none;
    border-radius: 0.4vw;
    font-family: "Poppins-Regular";
    font-weight: 600;
    color: #fff;
    padding: 1vw 3vw;
    font-size: 1.5vw;
    cursor: pointer;
    background-color: #3A51A6;
}

.dsa-community-image {
    width: 25vw;
}

.dsa-community-image img {
    width: 100%;
}

@media (max-width: 600px) {
    .dsa-community-wrapper {
        flex-direction: column;
        padding: 10vw 2vw;
        margin: 10vw 0;
    }

    .dsa-community-image {
        width: 57%;
    }

    .dsa-community-image img {
        width: 100%;
    }

    .dsa-community-content {
        padding: 2vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .dsa-community-content h2 {
        font-size: 5.6vw;
        text-align: center;
        padding-bottom: 3vw;
        font-family: Poppins-Medium;
        color: #191d23;
    }

    .dsa-community-content p {
        font-size: 3.7vw;
        padding-bottom: 5vw;
        color: #191d23;
        text-align: center;
        font-family: Poppins-Regular;
        font-weight: 400;
        line-height: 5vw;
    }

    .dsa-join-community-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        font-family: "Poppins-Regular";
        font-weight: 600;
        color: #fff;
        cursor: pointer;
        background-color: #3a51a6;
        font-size: 3.7vw;
        width: 90%;
        padding: 2.5vw 2vw;
        border-radius: 2vw;
    }
}