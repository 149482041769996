.dsa-audience-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 8vw 0;
}

.dsa-audience-wrapper h2 {
    margin-bottom: 2vw;
    font-family: Poppins-Semibold;
    font-weight: 600;
    font-size: 2.8vw;
    color: #272834;
    padding-top: 2vw;
}

.dsa-audience-points-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.7vw 0;
    word-wrap: normal;
}

.dsa-audience-points {
    display: flex;
    border: 1px solid #D0D5DD;
    background-color: #F7F8F9;
    border-radius: 0.9vw;
    padding: 1.7vw 3.5vw;
    margin-bottom: 1.7vw;
    width: fit-content;
}

.dsa-audience-points>img {
    object-fit: contain;
    margin-right: 1.5vw;
    width: 2vw;
}

.dsa-audience-points>p {
    font-family: "Poppins-Medium";
    font-weight: 500;
    font-size: 1.7vw;
    color: #2f3043;
}

.dsa-audience-cta-btn-wrapper {
    margin: 1vw 0vw;
}

.dsa-audience-cta-btn-wrapper button {
    background: linear-gradient(180deg, #3940A0 0%, #3CA3C1 100%);
    border: none;
    border-radius: 0.4vw;
    color: #ffffff;
    cursor: pointer;
    font-family: Poppins-Medium;
    font-weight: 700;
    font-size: 1.1vw;
    padding: 1.1vw 7vw;
    width: auto;
}

@media (max-width: 600px) {
    .dsa-audience-wrapper {
        display: flex;
        flex-direction: column;
        width: 100vw;
        padding: 1vw 5vw;
        margin-top: 1vw;
        margin-bottom: 7vw;
        background: #FFF;
    }

    .dsa-audience-wrapper h2 {
        font-size: 5.6vw;
        font-family: Poppins-Medium;
        margin-bottom: 6vw;
        text-align: center;
    }

    .dsa-audience-points {
        align-items: center;
        padding: 2.8vw 4vw;
        border-radius: 2.8vw;
        margin-bottom: 3.5vw;
    }

    .dsa-audience-points>p {
        font-size: 3.7vw;
        font-family: Poppins-Regular;
        font-weight: 700;
    }

    .dsa-audience-points>img {
        width: 5.6vw;
    }

    .dsa-audience-cta-btn-wrapper {
        display: flex;
        justify-content: center;
        margin: 3vw 0;
    }

    .dsa-audience-cta-btn-wrapper button {
        border: none;
        border-radius: 2.3vw;
        font-size: 3.7vw;
        padding: 4.5vw 5vw;
        width: 88vw;
    }

}