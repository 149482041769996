.common-pricing-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3vw 0vw;
    margin: 8vw 0vw;
    margin-bottom: 1vw;
    background: linear-gradient(180deg, #439db6 0%, #2f466f 69.27%, #292c59 100%);
    margin-top: 4vw;
    padding-bottom: 8vw;
}
.common-pricing-wrapper > h1 {
    font-family: "Poppins-SemiBold";
    font-weight: 600;
    font-size: 2.5vw;
    color: #ffffff;
    margin-bottom: 4.3vw;
    text-align: center;
}

.pricing-plans-wrapper {
    display: flex;
}
.pricing-plan-card {
    background: #ffffff;
    border-radius: 1.39vw;
    width: 23vw;
    height: 25vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 3vw;
}
.pricing-plan-card-two {
    position: relative;
    top: -1.5vw;
    background: #ffffff;
    border-radius: 1.39vw;
    width: 23vw;
    height: 27vw;
    display: flex;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 6vw;
    background: linear-gradient(-135deg, #2e438d 6.95vw, #ffffff 0);
    margin-right: 3vw;
    margin-left: 3vw;
}
.recommend-triangle {
    position: absolute;
    top: 12%;
    right: -2%;
    /* bottom: 3.2vw;
    left: 7.4vw; */
    transform: rotate(45deg);
    color: white;
    font-size: 1vw;
    font-weight: 600;
    font-family: "Poppins-SemiBold";
    margin-bottom: -3.47vw;
}
.initial-price-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}
.price-saved {
    color: #e48915;
    font-family: "Lato-Regular";
    font-weight: 700;
    font-size: 1.6vw;
    margin-bottom: 1vw;
    margin-left: 1vw;
}
.plan-duration {
    font-family: "Lato-Black";
    font-weight: 900;
    font-size: 1.8vw;
    color: #272834;
    margin-bottom: 1vw;
}
.horizontal-line-price {
    width: 50px;
    height: 0px;
    border-radius: 10px;
    border: 2px solid #3babc3;
    background: #3babc3;
    margin-bottom: 2vw;
}
.plan-price {
    color: #888888;
    text-decoration: line-through;
    font-family: "Lato-Regular";
    font-size: 2vw;
    margin-bottom: 1vw;
}
.plan-discount-price {
    font-family: "Lato-Black";
    font-weight: 900;
    font-size: 3vw;
    color: #272834;
    margin-bottom: 1vw;
}
.plan-desc {
    font-family: "Lato-Regular";
    font-weight: 400;
    font-size: 1.4vw;
    text-align: center;
    color: #343a40;
    margin-bottom: 1vw;
}
.subscribe-now-button {
    background: linear-gradient(93.44deg, #444bab -6.8%, #3ca5c2 186.94%);
    border-radius: 0.4vw;
    /* width: 20.3vw;
    height: 4vw; */
    font-family: "Poppins-SemiBold";
    font-weight: 600;
    font-size: 1.4vw;
    color: #ffffff;
    border: none;
    margin-bottom: 1vw;
    cursor: pointer;
    white-space: nowrap;
    padding: 0.5vw 5vw;
}
.emi-text {
    font-family: "Poppins-Medium";
    font-weight: 500;
    font-size: 1.1vw;
    color: #ffffff;
    margin: 1vw 0vw;
}

.gift-wrapper {
    display: flex;
}
.gift-wrapper > img {
    height: 1.6vw;
    width: 1.6vw;
    margin-right: 0.4vw;
}
.gift-wrapper > p {
    font-family: "Poppins-Medium";
    font-weight: 500;
    font-size: 1.3vw;
    color: #ffffff;
    margin-bottom: 1.39vw;
}
.referral-code {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.referral-code > input {
    width: 23.6vw;
    height: 3.2vw;
    background: #ffffff;
    border: 1px solid #d5dbe1;
    border-radius: 0.35vw;
    padding: 0.7vw;
    font-family: "Poppins-Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 1.1vw;
    color: #616161;
}
.referral-code > span {
    position: relative;
    bottom: 2.5vw;
    left: 9vw;
    font-family: "Poppins-SemiBold";
    font-weight: 600;
    font-size: 1.1vw;
    color: #444bab;
    cursor: pointer;
}
.error-coupon-code-wrapper {
    text-align: center;
    font-family: "Gilroy-Medium";
    font-size: 1.2vw;
    color: #ff5050;
    margin-top: 2vw;
    margin-bottom: 2vw;
}

.free-trial-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.2vw;
    margin-bottom: 4vw;
    margin-top: 5vw;
}
.free-trial-wrapper > p {
    font-family: "Poppins-SemiBold";
    font-weight: 600;
    font-size: 2.09vw;
    color: #4f516b;
}
.free-trial-wrapper > span {
    font-family: "Poppins-Medium";
    font-weight: 500;
    font-size: 1.39vw;
    color: #4359af;
    margin-bottom: 1vw;
}
.free-trial-wrapper > button {
    width: 20.9vw;
    height: 3.9vw;
    background: linear-gradient(91.59deg, #444bab 1.85%, #3ca5c2 99.69%);
    border-radius: 0.4vw;
    border: none;
    font-family: "Poppins-SemiBold";
    font-weight: 600;
    font-size: 1.39vw;
    color: #ffffff;
}
@media (max-width: 600px) {
    .common-pricing-wrapper {
        margin-top: 10vw;
    }
    .common-pricing-wrapper > h1 {
        font-size: 20px;
        text-align: center;
        margin: 15px;
    }
    .pricing-plans-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .pricing-plan-card {
        width: 85%;
        height: 350px;
        margin-bottom: 40px;
    }
    .pricing-plan-card-two {
        width: 75vw;
        height: 400px;
        margin-bottom: 40px;
        background: linear-gradient(-135deg, #2e438d 18vw, #ffffff 0);
    }
    .recommend-triangle {
        /* bottom: 30px;
        left: 130px; */
        right: 0;
        top: 8%;
        font-size: 10px;
    }

    .plan-duration {
        font-size: 27px;
        margin-bottom: 10px;
    }
    .horizontal-line-price {
        margin-top: 5px;
        margin-bottom: 15px;
    }
    .plan-price {
        font-size: 25px;
        margin: 20px 0px;
    }
    .plan-discount-price {
        font-size: 43px;
        margin-bottom: 15px;
    }
    .plan-desc {
        font-size: 20px;
        margin: 15px 0px;
    }
    .subscribe-now-button {
        font-size: 20px;
        border-radius: 5px;
        padding: 1vw 10%;
    }
    .emi-text {
        font-size: 14px;
        text-align: center;
        padding: 2vw;
    }
    .gift-wrapper {
        margin-top: 2vw;
    }
    .gift-wrapper > img {
        width: 20px;
        height: 20px;
    }
    .gift-wrapper > p {
        font-size: 14px;
        text-align: center;
    }

    .referral-code > input {
        width: 95%;
        height: 40px;
        font-size: 14px;
        padding: 5px 10px;
        border-radius: 5px;
        margin-top: 5vw;
    }
    .referral-code > span {
        bottom: 33px;
        /* left: 160px; */
        left: 35%;
        font-size: 14px;
    }
    .free-trial-wrapper > p {
        font-size: 22px;
        margin-bottom: 15px;
    }
    .free-trial-wrapper > span {
        font-size: 18px;
        margin-bottom: 12px;
    }
}
