@media (max-width: 600px) {
    .lld-free-trial-mobile-wrapper {
        margin-bottom: 12vw;
    }
    .lld-free-trial-mobile-wrapper {
        padding: 3vw 6vw;
    }
    .lld-free-trial-mobile-wrapper p {
        text-align: center;
        font-family: Poppins-SemiBold;
        font-size: 5.8vw;
    }
    .buttons-wrapper-mobile {
        margin-top: 5vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 3vw;
    }
    .free-trial-btn {
        width: 100%;
    }
    .free-trial-btn button {
        width: 100%;
        padding: 2.6vw;
        font-size: 4.2vw;
        border-radius: 10px;
    }
    .talk-to-us-btn {
        width: 100%;
        background-color: #e7eaee;
        padding: 1.8vw;
        justify-content: center;
        border: none;
    }
    .talk-to-us-btn p {
        font-size: 5.2vw;
        margin-right: 1.5vw;
    }
    .talk-to-us-btn img {
        width: 1.2rem;
        height: 1.2rem;
    }
    .lld-empty-inbox-img {
        display: none;
    }
}
