.footer-container {
    background: #3940A0;
}
.footer-header {
    display: flex;
    align-items: center;
    padding: 3vw 0vw;
    margin-left: 7vw;
    padding-bottom: 1vw;
}
.footer-header img {
    width: 3rem;
    height: 3rem;
    margin-right: 1vw;
}
.footer-header p {
    font-family: Poppins-Regular;
    font-size: 1.5vw;
    color: #ffffff;
    font-weight: bold; 
}
.footer-contents {
    display: flex;
    justify-content: space-between;
    margin: 1.5vw 3vw;
    margin-right: 0vw;
    padding: 0vw 10vw 0vw 4vw;
}

.footer-section-company-courses {
    display: flex;
    width: 45vw;
}
.footer-section-company {
    display: flex;
    flex-direction: column;
    padding-left: 5vw;
    width: 20vw;
}
.footer-section-company h2 {
    font-family: Poppins-Regular;
    font-weight: 400;
    color: #ffffff;
    font-size: 1.2vw;
    padding: 1vw 0vw;
}

.footer-section-company p {
    font-family: Poppins-Regular;
    color: #ffffff;
    font-size: 1vw;
    padding: 1vw 0vw;
}

.footer-section-courses {
    display: flex;
    flex-direction: column;
}
.footer-section-courses .customCss {
    font-family: Poppins-Semibold;
    /* font-weight: 500; */
    color: #ffffff !important;
    font-size: 1.2vw;
    padding: 1vw 0vw;
}
.footer-section-courses p {
    font-family: Poppins-Regular;
    color: #ffffff;
    font-size: 1vw;
    padding: 1vw 0vw;
}

.footer-section-courses.new {
    margin-left: 4vw;
}

.footer-section {
    display: flex;
    justify-content: space-between;
    padding-top: 4vw;
}
.footer-section-policies {
    padding-left: 2vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.footer-section.courses {
    margin-left: -2vw;
}
.footer-section h2 {
    font-family: Poppins-Regular;
    font-weight: 400;
    color: #ffffff;
    font-size: 1.2vw;
    padding: 1vw 0vw;
}
.footer-section p {
    font-family: Poppins-Regular;
    color: #ffffff;
    font-size: 1vw;
    padding: 1vw 0vw;
}
.line-break {
    margin-top: 4vw;
}
.footer-icons-wrapper {
    display: flex;
    justify-content: center;
}
.footer-icons-wrapper img {
    width: 3vw;
    height: 3vw;
    margin-right: 1vw;
}

.footer-line-break {
    padding: 1vw 0;
}
.footer-line-break p {
    color: #ffffff;
    font-family: Poppins-Regular;
    font-size: 1vw;
    text-align: center;
}

@media (max-width: 600px) {
    .footer-contents {
        flex-direction: column;
        justify-content: space-around;
        padding-right: 2vw;
    }
    .footer-header {
        display: none;
    }
    .footer-section-company-courses {
        display: flex;
        flex-direction: column;
        width: 78vw;
    }
    .footer-section {
        padding: 0;
        padding-top: 5vw;
        width: auto;
    }
    .footer-section p {
        font-size: 3.4vw;
        padding: 2.5vw 0vw;
    }
    .footer-section-courses.new {
        order: 2;
        margin-left: 0vw;
        margin-top: 0vw;
    }
    .footer-section-company {
        padding: 0;
        margin-left: 0;
        width: auto;
    }
    a > p {
        cursor: pointer;
    }
    .line-break {
        margin-top: 0vw;
    }
    .footer-icons-wrapper img {
        width: 2.5rem;
        height: 2.5rem;
        margin: 0 2vw;
    }
    .footer-section-company h2 {
        font-size: 4vw;
        font-weight: 600;
    }
    .footer-section-company p {
        font-size: 3.4vw;
        padding: 2.5vw 0vw;
    }
    .footer-section h2 {
        font-size: 4vw;
        font-weight: 600;
    }
    .footer-section-courses {
        margin-top: 6vw;
    }
    .footer-section-courses .customCss {
        font-size: 4vw;
        font-weight: 600;
    }
    .footer-section-courses p {
        font-size: 3.4vw;
        padding: 2.5vw 0vw;
    }

    .footer-section br {
        content: none;
    }
    .footer-section-courses br {
        content: none;
    }
    .footer-section-policies {
        padding: 0 3vw;
    }
    .footer-icons-wrapper {
        padding: 3vw 5vw;
        justify-content: center;
    }
    .footer-header {
        margin-left: 10vw;
        padding: 6vw 0vw;
    }
    .footer-header img {
        width: 2rem;
        height: 2rem;
    }
    .footer-line-break {
        padding: 3vw 0;
    }
    .footer-line-break p {
        font-size: 3vw;
        text-align: center;
    }
}
